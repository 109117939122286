<template>
  <div class="contact-info-area <!--pt-70--> pb-90">

    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6">
          <div class="contact-info-item mt-30">
            <h3 class="title">Türk Eczacıları Birliği 2. Bölge Ankara Eczacı Odası</h3>
            <span><i class="fas fa-map-marker-alt"></i>Adres:</span>
            <ul>
              <li>Olgunlar Sokak No:32</li>
              <li>Kızılay /ANKARA</li>
            </ul>
            <span><i class="fas fa-phone"></i>Tel:</span>
            <ul>
              <li><a href="03124250708">(312) 425-0708</a></li>
            </ul>
            <span><i class="fas fa-phone"></i>Faks:</span>
            <ul>
              <li><a href="03124254296">(312) 425-4296</a></li>
            </ul>
            <span><i class="fas fa-envelope"></i>E-mail:</span>
            <ul>
              <li><a href="aeo@aeo.org.tr">aeo@aeo.org.tr</a></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="contact-info-item mt-30">
            <h3 class="title">SUT ve Protokol Danışmanı Eczacılar</h3>
            <span><i class="fas fa-phone"></i>Tel:</span>
            <ul>
              <li><a href="03124250770">(312) 425-0770</a></li>
            </ul>
            <span><i class="fas fa-envelope"></i>E-mail:</span>
            <ul>
              <li><a href="danisman.ecz@aeo.org.tr">danisman.ecz@aeo.org.tr</a></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="contact-info-item mt-30">
            <h3 class="title">Sıralı Dağıtım Birimi</h3>
            <span><i class="fas fa-phone"></i>Tel:</span>
            <ul>
              <li><a href="03124258015">(312) 425-8015</a></li>
            </ul>
            <span><i class="fas fa-phone"></i>Faks:</span>
            <ul>
              <li><a href="03124258088">(312) 425-8088</a></li>
            </ul>
            <span><i class="fas fa-envelope"></i>E-mail:</span>
            <ul>
              <li><a href="siralidagitim@aeo.org.tr">siralidagitim@aeo.org.tr</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="map-area">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3060.1822243083525!2d32.85611007638306!3d39.91493808602675!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d34fa9450985b1%3A0x9b478501a451d509!2sAnkara%20Chamber%20of%20Pharmacists!5e0!3m2!1sen!2str!4v1715062032082!5m2!1sen!2str"
                    width="600"
                    height="450"
                    style="border:0;"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade">
            </iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    window.scrollTo(0, 0)
  }
};

</script>

<style>
.contact-info-area {
  margin-top: 0px;
}

@media (max-width: 992px) {
  .contact-info-area {
    margin-top: 0px;
  }

}

</style>
