<template>
  <div>
    <div >
      <Navbar class="navbar_container"/>

      <drawer @toggleSidebar="toggleSidebar" :sidebar="sidebar" /> 
    </div>
    <!-- <div class="sticky-top">
      <Header @toggleSidebar="toggleSidebar" />
    </div> -->

    <!-- <about-author /> -->
    <div class="container">
      <h4 class="title">CV Yükle</h4>
      <h5 class="title border-bottom w-50 mt-3">Kişisel Bilgiler</h5>
      <div class="row">
        <div class="col-md-6 mt-4">
          <label for="inputGroupFile04" class="form-label fw-bold"
            >Fotoğraf yükle</label
          >
          <div class="input-group">
            <input
              type="file"
              class="form-control"
              id="inputGroupFile04"
              aria-describedby="inputGroupFileAddon04"
              aria-label="Upload"
            />
          </div>
        </div>
      </div>
      <br />
      <form class="row g-3">
        <div class="col-md-6">
          <label for="inputName" class="form-label fw-bold">Ad</label>
          <input
            type="text"
            class="form-control"
            id="inputName"
            placeholder="Adınız"
          />
        </div>
        <div class="col-md-6">
          <label for="inputSurname" class="form-label fw-bold">Soyad</label>
          <input
            type="text"
            class="form-control"
            id="inputSurname"
            placeholder="Soyadınız"
          />
        </div>
        <div class="col-md-6">
          <label for="inputEmail4" class="form-label fw-bold">E-Posta</label>
          <input
            type="email"
            class="form-control"
            id="inputEmail4"
            placeholder="E-Posta Adresiniz"
          />
        </div>
        <div class="col-md-6">
          <label for="phone1" class="form-label fw-bold">Cep Telefonu</label>
          <input
            type="tel"
            class="form-control"
            id="phone1"
            placeholder="05*******"
          />
        </div>
        <div class="col-md-4">
          <label for="inputAddress" class="form-label fw-bold"
            >İkamet Adresi</label
          >
          <input
            type="text"
            class="form-control"
            id="inputAddress"
            placeholder="İkamet Adresiniz"
          />
        </div>
        <div class="col-md-4">
          <label for="inputCity" class="form-label fw-bold">İlçe</label>
          <input
            type="text"
            class="form-control"
            id="inputCity"
            placeholder="İlçe"
          />
        </div>
        <div class="col-md-4">
          <label for="inputCity" class="form-label fw-bold">İl</label>
          <input
            type="text"
            class="form-control"
            id="inputCity"
            placeholder="İl"
          />
        </div>
        <div class="col-md-6">
          <label for="inputAddress2" class="form-label fw-bold"
            >Doğum Yeri</label
          >
          <input
            type="text"
            class="form-control"
            id="inputAddress2"
            placeholder="Doğum Yeriniz"
          />
        </div>
        <div class="col-md-6">
          <label for="birthday" class="form-label fw-bold">Doğum Tarihi</label>
          <input
            type="date"
            id="birthday"
            name="birthday"
            class="form-control"
          />
        </div>
        <h5 class="title border-bottom w-50 mt-5">Öğrenim Bilgileri</h5>
        <div class="row">
          <div class="col-md-4">
            <label for="inputState" class="form-label fw-bold">Okulunuz</label>
            <select id="inputState" class="form-select" v-model="selected">
              <option v-for="(uni,index) in universities" :key="index" :value="uni.title">
                {{ uni.title }}
              </option>
            </select>
          </div>
          <div class="col-md-4">
            <label for="inputNote" class="form-label fw-bold"
              >Not Ortalamanız</label
            >
            <input
              type="text"
              class="form-control"
              id="inputNote"
              placeholder="Diplomada/Transkripte Yazan hali (0.00)"
            />
          </div>
          <div class="col-md-4">
            <label for="inputGraduate" class="form-label fw-bold"
              >Mezuniyet Tarihiniz</label
            >
            <input
              type="text"
              class="form-control"
              id="inputGraduate"
              placeholder="Yıl"
            />
          </div>

          <div class="col-md-12 mt-3">
            <label for="inputGroupFile05" class="form-label fw-bold"
              >Diploma yükle</label
            >
            <div class="input-group">
              <input
                type="file"
                class="form-control"
                id="inputGroupFile05"
                aria-describedby="inputGroupFileAddon05"
                aria-label="Upload"
              />
            </div>
            <p class="title w-25 mt-5 fw-bold">Yabancı Diller</p>
            <ul class="list-group col-md-6">
              <li
                class="list-group-item d-flex align-items-center justify-content-between"
              >
                <label for="almanca" class="form-label col-md-4">Almanca</label>
                <input type="range" class="form-range col-md-4" id="almanca" />
              </li>

              <li
                class="list-group-item d-flex align-items-center justify-content-between bg-light"
              >
                <label for="arapca" class="form-label col-md-4">Arapça</label>
                <input type="range" class="form-range col-md-4" id="arapca" />
              </li>
              <li
                class="list-group-item d-flex align-items-center justify-content-between"
              >
                <label for="farsca" class="form-label col-md-4">Farsça</label>
                <input type="range" class="form-range col-md-4" id="farsca" />
              </li>
              <li
                class="list-group-item d-flex align-items-center justify-content-between bg-light"
              >
                <label for="fransizca" class="form-label col-md-4">Fransızca</label>
                <input type="range" class="form-range col-md-4" id="fransizca" />
              </li>
              <li
                class="list-group-item d-flex align-items-center justify-content-between"
              >
                <label for="ingilizce" class="form-label col-md-4">İngilizce</label>
                <input type="range" class="form-range col-md-4" id="ingilizce" />
              </li>
            </ul>
            <div class="row g-3">
              <div class="col-md-6">
                <p class="title mt-5 fw-bold">
                  Tecrübeler ve Alınan Eğitimler | Sertifikalar
                </p>
                <div class="form-floating">
                  <textarea
                    class="form-control"
                    placeholder="Leave a comment here"
                    id="egitim"
                    style="max-height: 200px; height: 150px"
                  ></textarea>
                  <label for="egitim"
                    >Örnek: YIL - ALINAN SERTİFİKA | YIL- BULUNULAN GÖREV</label
                  >
                </div>
              </div>
              <div class="col-md-6">
                <p class="title mt-5 fw-bold">Kendinizden Kısaca Bahsediniz</p>
                <div class="form-floating">
                  <textarea
                    class="form-control"
                    placeholder="Leave a comment here"
                    id="otobio"
                    style="max-height: 200px; height: 150px"
                  ></textarea>
                  <label for="otobio">Maksimum 5000 karakter giriniz.</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h5 class="title border-bottom w-50 mt-5">Başvuru Bilgileri</h5>
        <div class="positions">
          <p class="fw-bold">
            Aşağıdaki kategorilerden başvuru yapmak istediğinizi seçiniz. *
          </p>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
            <label class="form-check-label fw-bold" for="flexCheckDefault">
              MESUL MÜDÜR
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckChecked"
            />
            <label class="form-check-label fw-bold" for="flexCheckChecked">
              STAJYER ECZACI
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckChecked"
            />
            <label class="form-check-label fw-bold" for="flexCheckChecked">
              YARDIMCI ECZACI
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckChecked"
            />
            <label class="form-check-label fw-bold" for="flexCheckChecked">
              İKİNCİ ECZACI
            </label>
          </div>
        </div>
        <div class="location">
          <p class="fw-bold">Hangi ilçe/ilçelerde görev almak istiyorsunuz?</p>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                İlçe seçiniz
              </button>
            </h2>
            <div
              id="collapseOne"
              class="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <div class="form-check" v-for="loc in locations" :key="loc">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :value="loc.title"
                    :id="loc._id"
                  />
                  <label class="form-check-label fw-bold" :for="loc._id">
                    {{ loc.title }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h5 class="title border-bottom w-25 mt-5">Referanslar</h5>
        <div class="referance row g-3">
          <div class="col-md-6">
            <div class="form-floating">
              <textarea
                class="form-control"
                placeholder="Leave a comment here"
                id="egitim"
                style="max-height: 200px; height: 150px"
              ></textarea>
              <label for="egitim"
                >KİŞİ ADI VE ÜNVANI - İLETİŞİM BİLGİLERİ ŞEKLİNDE
                YAZINIZ.</label
              >
            </div>
          </div>
        </div>
        <div class="">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="checkhed"
            />
            <label class="form-check-label fw-bold" for="checkhed">
              6698 Sayılı Kişisel Verilerin Korunması Kanunu’nu okudum ve Ankara
              Eczacı Odası'nın verilerimi depolayarak ilgili kişilerle
              paylaşmasını kabul ediyorum.
            </label>
            <div>
              <a
                class="link-primary fw-bold"
                href="https://mvc.aeo.org.tr//UserFiles/files/aeo-cv-proje-kvkk-metni.pdf"
                >Kişisel verilerin korunması aydınlatma metni için
                tıklayınız.</a
              >
            </div>
          </div>
        </div>
        <h5 class="title border-bottom w-25 mt-5">Notlar & Öneriler</h5>
        <div class="notes row g-3">
          <div class="col-md-6">
            <div class="form-floating">
              <textarea
                class="form-control"
                placeholder="Leave a comment here"
                id="notes"
                style="max-height: 200px; height: 150px"
              ></textarea>
              <label for="notes"></label>
            </div>
          </div>
        </div>
        <div class="row g-3">
          <div class="col-md-6 d-flex">
            <button class="btn btn-primary w-100">Kaydet</button>
          </div>
        </div>
        <p>
          Özgeçmiş havuzundan çıkmak isteyen kişilerin
          <a class="link-primary fw-bold" href="info@aeo.org.tr "
            >info@aeo.org.tr
          </a>
          adresine çıkış ile ilgili mail göndermeleri gerekmektedir.
        </p>
      </form>
      <hr />
      <p>© 2022 - ImOnTech</p>
    </div>

    <footer-one />
    <div class="go-top-area">
      <div class="go-top-wrap">
        <div class="go-top-btn-wrap">
          <a href="#" class="go-top go-top-btn d-block">
            <i class="fa fa-angle-double-up"></i>
            <i class="fa fa-angle-double-up"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Drawer from "../Mobile/Drawer.vue";
import FooterOne from "../Utility/Footer/FooterOne.vue";

// import Header from "../Utility/Header/index";
import Universities from "../Data/Universities.json";
import Locations from "../Data/Locations.json";
import Navbar from "@/components/Utility/Header/index.vue";
// import AboutAuthor from "./AboutAuthor.vue";
export default {
  components: {
    Navbar,

    FooterOne,
    Drawer,

  },
  data: () => ({
    sidebar: false,
    selected: null,
    universities: Universities.data,
    locations: Locations.data,
  }),
  created() {
    document.addEventListener("scroll", this.topToBottom);
  },
  mounted () {
  window.scrollTo(0, 0)
},
  methods: {
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
    topToBottom() {
      const result = document.querySelector(".go-top");
      if (
        document.body.scrollTop > window.innerHeight ||
        document.documentElement.scrollTop > window.innerHeight
      ) {
        result.classList.add("active");
      } else {
        result.classList.remove("active");
      }
    },
  },
};
</script>

<style scoped>
.container {
  margin-top: 30px;
  width:100%;
}
@media (max-width: 992px) {
  .container {
    margin-top: 150px;
  }
}
</style>
