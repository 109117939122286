<template>
  <div>
    <Navbar class="navbar_container"/>
  <div class="container1" style="margin-top: 150px;margin-bottom: 100px">
    <div class="row" style="display: flex; justify-content: center; align-items: center; text-align: center;">
      <h1> Play Store Uygulamalarımız</h1>
    </div>

    <div class="row ortalayici">
      <a class="buton9" href="https://play.google.com/store/apps/details?id=aeo.com.aeorehber&hl=tr&pli=1">Aeo Rehber</a>
    </div>
    <div class="row">
      <a class="buton9" href="https://play.google.com/store/apps/details?id=com.aeo.nobet&hl=tr">AEO Nöbet</a>
    </div>
    <div class="row" style="margin-top:50px;display: flex; justify-content: center; align-items: center; text-align: center;">
      <h1> App Store Uygulamalarımız</h1>
    </div>
    <div class="row">
      <a class="buton9" href="https://apps.apple.com/us/app/aeo-rehber/id1188315741">AEO Rehber</a>
    </div>
    <div class="row">
      <a class="buton9" href="https://apps.apple.com/us/app/aeo-n%C3%B6bet/id1419817490">AEO Nöber</a>
    </div>
  </div>
    <footer-one/>
  </div>
</template>

<script>
import Navbar from "@/components/Utility/Header/index";
import FooterOne from "@/components/Utility/Footer/FooterOne.vue";

export default {
  components: {
    FooterOne,

    /*Banner,*/
    Navbar,
  }
}
</script>

<style>
.container1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.buton9 {
  font-family: Calibri;
  font-weight: bold;

  width: 800px;
  height: 100px;
  background-color: #dc0404;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-size: 25px;
  margin-top: 50px;
}
</style>
