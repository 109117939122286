import axios from 'axios';


class GeneralService {


    getSocialEventsPaginated(payload) {
        return axios.post(`${process.env.VUE_APP_AEO_FRONTEND_API}Event/get-all-paginated`,
            {
                PageNumber: payload.currentPage, PageLength: payload.pageSize
            }
        )
    }

    getSocialEventById(payload) {
        return axios.get(`${process.env.VUE_APP_AEO_FRONTEND_API}Event/get-by-id?id=` + payload);
    }

}

export default new GeneralService();