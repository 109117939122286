<template>
  <div>

    <navbar></navbar>
<div  style="height: 30vh">

    <div class="row" style="margin-top: 14vh; margin-left: 16vw;z-index: 999">
      <button style="border-color: white" class=" buton1" @click="clickRouteMethod('/allposts/Personel/46')"> Eleman Arıyorum
      </button>
      <button style="border-color: white"  class=" buton" @click="clickRouteMethod('/allposts/Satıyorum/44')">  Eşya Satıyorum
      </button>
      <button style="border-color: white"  class=" buton "  @click="clickRouteMethod('/allposts/Devir%20Eczane/45')"> Devren Eczane
      </button>
      <button style="border-color: white"  class=" buton" @click="clickRouteMethod('/allposts/Yardımcı\u00A0Eczacı/127')"> Yardımcı Eczacı
      </button>
      <button style="border-color: white"  class=" buton" @click="clickRouteMethod('/allposts/İkinci Eczacı/128')"> İkinci Eczacı
      </button>
      <button style="border-color: white"  class=" buton" @click="clickRouteMethod('/allposts/Mesul Müdür/129')"> Mesul Müdür
      </button>
      <button style="border-color: white"  class=" buton2" @click="clickRouteMethod('/allposts/Stajyer/133')"> Stajyer Eczacı
      </button>


  </div>
      <!--<button class="buton" @click="clickRouteMethod('/allposts/Personel/44')" > Personel </button>-->

      <!--          <button class="buton" @click="clickRouteMethod('/allposts/Satıyorum/45')" > Satıyorum </button>-->


      <!--          <button class="buton" @click="clickRouteMethod('/allposts/Devir%20Eczane/46')" > Devir Eczane </button>-->

</div>


    <footer-one class="footer"/>
  </div>
</template>

<script>
import FooterOne from "../Utility/Footer/FooterOne.vue";

// import Header from "../Utility/Header/index";
import {mapActions, mapGetters} from "vuex";
import moment from "moment";
import Navbar from "@/components/Utility/Header/index.vue";

export default {
  components: {
    Navbar,
    FooterOne,

  },
  data: () => ({
    displayMode: "d-none",

    advertSubjectTypes: null,
    AdvertsPaginatedSearchResultItem: null,
    sidebar: false,
    form: {
      pageSize: 25,
      pageTypeId: 43,//REFER TO ILAN
      currentPage: 1,
      checkedSubjectTypes: [],
      dateInterval: [moment().format("DD.MM.YYYY")]
    },
  }),
  created() {

    this.advertSubjectTypes = this.getAdvertSubjectTypes();
    if (this.advertSubjectTypes === null) {
      this.getSubjectTypes();
    }
  },
  mounted() {
    window.scrollTo(0, 0)
  },
  methods: {
    ...mapActions('announcementModule', ['getSubjectTypes']),
    ...mapActions('advertModule', ['getAdvertsPaginated']),
    ...mapGetters('announcementModule', ['getAdvertSubjectTypes']),
    ...mapGetters('advertModule', ['getAdvertsPaginatedSearchResultItem']),

    format_date(value) {
      return moment(value).format("DD.MM.YYYY");
    },
    clickRouteMethod(route) {
      this.$router.push(route);
    },
    selectTab(value) {
      this.displayMode = "d-block"
      this.form.checkedSubjectTypes[0] = value;
      this.getAdvertsPaginated(this.form);
    },
    routeTo(routeTo, routeProp) {
      if (routeProp != null) {
        this.$router.push(routeTo + `/` + routeProp)
      } else
        this.$router.push(routeTo)
    },
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
    topToBottom() {
      const result = document.querySelector(".go-top");
      if (
          document.body.scrollTop > window.innerHeight ||
          document.documentElement.scrollTop > window.innerHeight
      ) {
        result.classList.add("active");
      } else {
        result.classList.remove("active");
      }
    },
  },
  watch: {
    '$store.state.announcementModule.subjectTypes': function () {
      this.advertSubjectTypes = this.getAdvertSubjectTypes();
    },
    '$store.state.advertModule.AdvertsPaginatedSearchResult.item': function () {
      this.AdvertsPaginatedSearchResultItem = this.getAdvertsPaginatedSearchResultItem();
    },
  }
};
</script>

<style scoped>
.buton {
  display: flex;
  justify-content: center;

  margin-right: 1px;
  padding-top: 3px;
height: 30px;
  background-color: #fc1717;
  color: #ffffff;



  white-space: nowrap;
  width:120px;

}
.buton1 {
  margin-left: 5vw;
  display: flex;
  justify-content: center;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  margin-right: 1px;
  padding-top: 3px;
  background-color: #fc1717;
  color: #ffffff;
  font-size: large;
  white-space: nowrap;
  width:140px;
  height: 30px;
}
.buton2 {
  display: flex;
  justify-content: center;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  margin-right: 1px;
  padding-top: 3px;

  background-color: #fc1717;
  color: #ffffff;


  font-size: large;
  white-space: nowrap;

  width:120px;
  height: 30px;
}

.container {
  margin-top: 20px;
}

.center {
  justify-content: center;

}

@media (max-width: 992px) {
  .container {
    margin-top: 20px;
  }

}

@media (min-width: 991.98px) {
  .advert_card {
    height: 180px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .advert_card {
    height: 200px;
  }
}

@media (max-width: 768px) {
  .advert_card {
    height: 530px;
  }
}

.advert_card:hover {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.advert_card {
  cursor: pointer;
}

.adv-img {
  height: 170px;
  width: 170px;
}

.main_con {
  position: relative;
  height: 100vh;
}

.footer {
  position: absolute;
  width: 100%;

}

.default_div {
  height: 40vh;
}
</style>
