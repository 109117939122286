<template>
  <div>
    <div class="sticky-top">
      <Navbar class="navbar_container"/>
    </div>
    <drawer :sidebar="sidebar" @toggleSidebar="toggleSidebar"/>

    <div class="container content">
      <h4 class="title">Nöbetçi Eczaneler</h4>
      <div class="row mb-5">
        <div class="col-md-4 d-flex align-items-center">
          <label class="form-label fw-bold me-3" for="crisisDate">Tarih:</label>
          <input id="crisisDate" v-model="crisisDateValue" class="form-control" name="crisisDate" type="date">

        </div>
        <div class="col-md-4 d-flex align-items-center">
          <label class="form-label fw-bold me-3" for="crisisRegion">İlçe:</label>
          <select id="crisisRegion" v-model="crisisRegionValue" class="form-control" name="crisisRegion">
            <option v-for="(region, index) in Region" :key="index" :value="region.title">{{ region.title }}</option>
          </select>
          <p></p>
        </div>
        <div class="col-md-4 d-flex align-items-center">
          <!--          <div class="col-md-4 d-flex align-items-center">-->
          <!--            <label  class="form-label fw-bold me-3" for="crisisRegion">Bölge:</label>-->
          <!--            <select id="crisisRegion" v-model="section" class="form-control" name="crisisRegion">-->
          <!--              <option v-for="(region, index) in Section" :key="index" :value="region.title">{{ region.title }}</option>-->
          <!--            </select>-->
          <!--            <p></p>-->
          <!--          </div>-->
          <span class="button" @click="list(crisisDateValue)">Listele</span>
        </div>
      </div>

      <div class="row">
        <div class="row">
          <div v-if="error" class="alert alert-danger" role="alert">
            {{ error }}
          </div>
          <div v-for="(data, index) in filteredPharmacies" :key="index" class="col-lg-6 mb-4">
            <div class="card mb-3 advert_card align-items-center" style="max-width: 545px" @click="handlePharmacyClick(data)">
              <div class="row g-0">
                <div class="img_container col-md-5">
                  <img class="img-fluid rounded-start" src="@/assets/images/eczane-logo.jpg"/>
                </div>
                <div class="col-md-7">
                  <div class="card-body ms-0">
                    <div class="mb-2">
                      <p class="card-title fw-bold">{{ data.EczaneAdi }} ECZANESİ</p>
                      <p>{{ data.EczaneAdresi }}</p>
                      <small><p class="text-muted">{{ data.AdresAciklamasi }}</p></small>
                      <p class="card-text">
                        <button style="background-color: #ec0b22 ;border: none;color: white" @click="tel('0'+data.Telefon)" > {{ '0' + data.Telefon}}</button>
                        <small class="text-muted"> | {{ data.BolgeAdi }}</small>
                        <!--                        {{ crisisDateValue}}-->
                      </p>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer-one/>
    <div class="go-top-area" style="display: none">
      <div class="go-top-wrap">
        <div class="go-top-btn-wrap">
          <a class="go-top go-top-btn d-block" href="#">
            <i class="fa fa-angle-double-up"></i>
            <i class="fa fa-angle-double-up"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Drawer from "../Mobile/Drawer.vue";
import FooterOne from "../Utility/Footer/FooterOne.vue";
import CrisisPha from "../Data/CrisisPha.json";
import Region from "../Data/Region.json";
import Navbar from "@/components/Utility/Header/index";
import ShiftService from "../../services/shift.js";

export default {
  components: {
    FooterOne,
    Drawer,
    Navbar,
  },
  data() {
    return {
      shiftDate: null,
      content: null,
      sidebar: false,
      CrisisPha: CrisisPha.data,
      Region: Region.data,
      crisisDateValue: null,
      crisisRegionValue: null,
      error: "",
    };
  },
  created() {
    document.addEventListener("scroll", this.topToBottom);
    // Get current date in YYYY-MM-DD format
    let currentDate = new Date().toISOString().split('T')[0];
    this.crisisDateValue = currentDate; // Set current date as initial value
    this.list(this.crisisDateValue);
  },

  computed: {
    filteredPharmacies() {
      const selectedRegion = this.crisisRegionValue;

      if (!selectedRegion || selectedRegion === "Tümü") {
        return this.content;
      }

      return this.content.filter((pharmacy) => {
        return pharmacy.IlceAdi === selectedRegion;
      });
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    async list(crisisDateValue) {
      // Check if the selected date is more than 10 days ahead or behind of today
      const selectedDate = new Date(crisisDateValue);
      const currentDate = new Date();
      const timeDiff = selectedDate.getTime() - currentDate.getTime();
      const daysDiff = timeDiff / (1000 * 3600 * 24);

      if (daysDiff > 10 || daysDiff < -10) {
        this.error = "Lütfen 10 gün içerisinde  bir tarih seçiniz.";
        this.content = null; // Clear content to hide previous results
        return;
      }

      // Fetch data using ShiftService (assuming it returns correct format)
      this.shiftDate = await ShiftService.getShift(crisisDateValue);
      this.content = this.shiftDate.NobetciEczaneBilgisiListesi;
      this.error = ""; // Clear error message if date is within range
    },

    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
    tel(phoneNumber){
      window.location.href = "tel:" + phoneNumber;
    },

    handlePharmacyClick(pharmacy) {
      console.log("Tıklanan eczane:", pharmacy);
    },

    topToBottom() {
      const result = document.querySelector(".go-top");
      if (
          document.body.scrollTop > window.innerHeight ||
          document.documentElement.scrollTop > window.innerHeight
      ) {
        result.classList.add("active");
      } else {
        result.classList.remove("active");
      }
    },
  },
};
</script>


<style scoped>
.container {
  margin-top: 10vh;
  height: 100%;
}

@media (max-width: 992px) {
  .container {
    margin-top: 10vh;
  }
}

@media (max-width: 768px) {
  img {
    display: none;
  }
}

.button {
  cursor: pointer;
  background: linear-gradient(#ec0b22 30%, #3db1d5 100%);
  color: #fff;
  border: 0;
  width: 70px;
  height: 100%;
  text-align: center;
  line-height: 53px;
  font-size: 20px;
}
</style>
