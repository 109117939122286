<template>
  <div>
    <div>
      <Banner/>
      <drawer @toggleSidebar="toggleSidebar" :sidebar="sidebar" /> 
    </div>
    <!-- <div class="sticky-top">
      <Header @toggleSidebar="toggleSidebar" />
    </div> -->
    <section
        class="post-layout-1-area post-layout-3-area pb-80"

    >
      <div class="container">
        <div class="row">
          <div class="col-lg-8">
            <div class="post-layout-top-content post-layout-top-content-3">
              <div
                  class="post-categories d-flex justify-content-between align-content-center"
              >
                {{ socialEvent.eventDate }} | {{ socialEvent.id }}
              </div>
              <div class="post-content">
                <h3 class="title">
                  {{ socialEvent.header }}
                </h3>
                <div class="thumb">
                  <img :src="socialEvent.eventIconPath" alt=""/>
                </div>
              </div>
              <div class="post-text mt-30">
                <div>
                  <p>{{ socialEvent.content }}</p>
                  <br/>
                </div>
              </div>
            </div>
            <div
                class="post-reader-text post-reader-text-2 post-reader-text-3 pt-50"
            ></div>
          </div>
          <div class="col-lg-4">

            <!-- dates -->
            <div class="card mb-4">
              <div class="card-body">
                <div>
                  <h6 class="d-inline">Tarih | </h6>
                  <span>{{ socialEvent.eventDate }}</span>
                </div>
                <hr>
                <div>
                  <h6 class="d-inline">Son Başvuru | </h6>
                  <span>{{ socialEvent.endDate }}</span>
                </div>
              </div>
            </div>

            <!-- information card -->
            <div class="card mb-4">
              <div class="card-header">
                <h5 class="">Detaylı bilgi için</h5>
              </div>
              <div class="card-body">
                <div>
                  <h6 class="d-inline">Telefon | </h6>
                  <a>{{ socialEvent.eventPlacePhone }}</a>
                </div>
                <hr/>
                <div>
                  <h6 class="d-inline">Mail | </h6>
                  <a>{{ socialEvent.eventPlaceAddress }}</a>
                </div>
                <hr/>
                <p>
                  {{ socialEvent.content }}
                </p>
              </div>
            </div>

            <!-- reserve card -->
            <div class="card mb-4">
              <div class="card-header">
                <h5 class="">Başvuru için</h5>
              </div>
              <div class="card-body">
                <h6 class="d-inline">Telefon | </h6>
                <a>{{ socialEvent.eventPlacePhone }}</a>


              </div>
            </div>

          </div>
        </div>
      </div>
    </section>


    <!--====== POST LAYOUT 1 PART ENDS ======-->

    <!--====== LATEST NEWS PART START ======-->

    <!--====== POST COMMENTS PART ENDS ======-->

    <!--====== ADD PART ENDS ======-->

    <!-- <div class="add-area text-center">
      <a href="#">
        <img src="@/assets/images/ads/one_ad.png" alt="" />
      </a>
    </div> -->

    <!--====== ADD PART ENDS ======-->
    <Footer/>
    <!--====== GO TO TOP PART START ======-->
    <div class="go-top-area" style="display: none">
      <div class="go-top-wrap">
        <div class="go-top-btn-wrap">
          <a href="#" class="go-top go-top-btn d-block">
            <i class="fa fa-angle-double-up"></i>
            <i class="fa fa-angle-double-up"></i>
          </a>
        </div>
      </div>
    </div>
    <!--====== GO TO TOP PART ENDS ======-->
  </div>
</template>

<script>
import Banner from "../../Utility/Header/Banner";
// import Header from "../../Utility/Header/index";
// import StyleOne from "../../Utility/Sidebar/StyleOne.vue";

// import OurLatestNews from "../../Utility/Common/OurLatestNews.vue";
import Footer from "../../Utility/Footer/FooterOne";
import Drawer from "../../Mobile/Drawer.vue";
import {mapActions} from "vuex";

export default {
  components: { Footer, Drawer,Banner},
  data() {
    return {
      sidebar: false,
      image: "",
      displayModal: false,
      socialEvent: {}
    }
  },

  async created() {
    await this.getSocialEventById(this.$route.params.id);
    this.socialEvent  = this.$store.state.generalModule.selectedSocialEvent;
    document.addEventListener("scroll", this.topToBottom);
  },
  methods: {
    ...mapActions("generalModule", ["getSocialEventById"]),
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
    topToBottom() {
      const result = document.querySelector(".go-top");
      if (
          document.body.scrollTop > window.innerHeight ||
          document.documentElement.scrollTop > window.innerHeight
      ) {
        result.classList.add("active");
      } else {
        result.classList.remove("active");
      }
    },
    toggleModal(e) {
      console.log(e);
      this.image = e.target;
      console.log(this.image);
      this.displayModal = !this.displayModal;
    },
  },
};
</script>

<style>
.image_modal {
  position: absolute;
  top: 50%;
  left: 50%;
}
</style>
