<template>
  <div>
    <div class="sticky-top">
      <Navbar class="navbar_container"/>
    </div>
    <Banner/>
    <drawer @toggleSidebar="toggleSidebar" :sidebar="sidebar"/>

    <!-- <about-author /> -->
    <div class="container mb-5" v-for="(data,index) in sut" :key="index">
      <h4 class="title">{{data.title}}</h4>
      <div class="row text-center">
        <div class="card mb-5">
          <div class="card-header">
            <p>
              {{data.article}}
            </p>
          </div>
          <div class="card-body">
            <a :href="data.link">
            
              <img class="sut-image" :src="require(`@/assets/images/${data.image}`)">
            </a>
          </div>
        </div>
      </div>
    </div>

    <footer-one class="footer" />
    <div class="go-top-area" style="display: none">
      <div class="go-top-wrap">
        <div class="go-top-btn-wrap">
          <a href="#" class="go-top go-top-btn d-block">
            <i class="fa fa-angle-double-up"></i>
            <i class="fa fa-angle-double-up"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Drawer from "../Mobile/Drawer.vue";
import FooterOne from "../Utility/Footer/FooterOne.vue";
import Banner from "../Utility/Header/Banner";
// import Header from "../Utility/Header/index";
import SUT from "../Data/SUT.json";
// import AboutAuthor from "./AboutAuthor.vue";
import Navbar from "@/components/Utility/Header/index";
export default {
  components: {

    FooterOne,
    Drawer,
    Banner,
    Navbar

  },
  data: () => ({
    sidebar: false,
    sut: SUT.data,
  }),
  created() {
    document.addEventListener("scroll", this.topToBottom);
  },
  mounted () {
  window.scrollTo(0, 0)
},
  methods: {
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
    topToBottom() {
      const result = document.querySelector(".go-top");
      if (
        document.body.scrollTop > window.innerHeight ||
        document.documentElement.scrollTop > window.innerHeight
      ) {
        result.classList.add("active");
      } else {
        result.classList.remove("active");
      }
    },
  },
};
</script>

<style scoped>
.container {
  margin-top: 20px;
  height: 60vh;
  z-index: -1;
}


  @media (max-width: 992px) {
  .container{
    margin-top: 20px;
  }
  
}

.sut-image{
  height: 30vh;
}
</style>
