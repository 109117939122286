<template>
  <div>
    <div class="sticky-top">
      <Navbar class="navbar_container"/>
    </div>
    <!--    <Banner/>-->
    <drawer :sidebar="sidebar" @toggleSidebar="toggleSidebar"/>

    <!-- <about-author /> -->
    <div class="container" style="height: 100%">
      <h1 style="text-align: center">Protokol</h1>
      <div>
        <table style="width: 100%;font-size: 130%">
          <thead>
          <tr>

            <th>İsim</th>
            <th>Belge</th>

          </tr>
          </thead>
          <tbody>
          <tr v-for="user in users" :key="user.id">

            <td>{{ user.name }}</td>
            <td @click="clickHrefMethod(user.email)">
              <div style="cursor: pointer;"> Görmek için tıklayınız -></div>
            </td>
          </tr>
          </tbody>
        </table>

      </div>


    </div>

    <footer-one/>
    <div class="go-top-area" style="display: none">
      <div class="go-top-wrap">
        <div class="go-top-btn-wrap">
          <a class="go-top go-top-btn d-block" href="#">
            <i class="fa fa-angle-double-up"></i>
            <i class="fa fa-angle-double-up"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Drawer from "../Mobile/Drawer.vue";
import FooterOne from "../Utility/Footer/FooterOne.vue";
// import Banner from "../Utility/Header/Banner";
// import Header from "../Utility/Header/index";
import AboutChaPharma from "../Data/AboutChaPharma.json"
// import AboutAuthor from "./AboutAuthor.vue";
import Navbar from "@/components/Utility/Header/index";

export default {
  components: {
    FooterOne,
    Drawer,
    /*Banner,*/
    Navbar
  },
  data: () => ({
    sidebar: false,
    aboutChaPharma: AboutChaPharma.data,
    users: [
      {name: '2012 Yılı SGK Protokolünde Yer Alan Sözleşme Feshi ve Cezai Şartlar Özet Tablosu (Aralık 2013)', email: 'http://mvc.aeo.org.tr//UserFiles/files/20131230_ProtokolCezaiSartTablo.pdf' },
      {name: 'SGK-TEB 01.04.2016 Protokol - Ek (03.05.2017)', email: 'http://mvc.aeo.org.tr//UserFiles/files/20170503-EK-PROTOKOL.pdf'},
      {name: 'SGK-TEB 01.04.2016 Protokol (22.05.2019 Tarihli Revizyon işlenmiş)',email: 'http://mvc.aeo.org.tr//UserFiles/files/2016_SGK_TEB_Eczane_Protokol_2019.pdf'},
      {name: 'SGK-TEB 01.04.2016 Protokol - Ek (01.04.2019)', email: 'http://mvc.aeo.org.tr//UserFiles/files/2019_1_Ek_Protokol_Metni.pdf'},
      {name: 'Göç İdaresi Genel Müdürlüğü-TEB 01.03.2021 Protokol (İmza;07.01.2021)', email: 'http://mvc.aeo.org.tr//UserFiles/files/20210107_Gecici_Koruma_Altındaki_Yabancılarin_Serbest_Eczanelerden_ilac_Teminine_Yonelik_Protokol.pdf' },
      {name: 'SGK-TEB 01.04.2016 Protokol - Ek (01.04.2018)',email: 'http://mvc.aeo.org.tr//UserFiles/files/ilac-Temini-Ek-Protokol-Metni.pdf'},
      {name: 'SGK-TEB 01.10.2020 Protokol', email: 'http://mvc.aeo.org.tr//UserFiles/files/protokol-imzalanan-metin-25092020pdf.pdf' },
      {name: 'SGK-TEB 2012 Protokol (Güncel)', email: 'http://mvc.aeo.org.tr//UserFiles/files/SGK_TEB_2012_Protokol_Guncel.pdf' },
      {name: 'SGK-TEB 2012 Protokol (Revizyon İşlenmiş)', email: 'http://mvc.aeo.org.tr//UserFiles/files/SGK_TEB_2012_Protokol_Revizyon_islenmis.pdf' },
      {name: 'SGK-TEB 01.04.2016 Protokol', email: 'http://mvc.aeo.org.tr//UserFiles/files/SGKPROTOKOL-01_04_2016.pdf' },
      {name: 'SGK-TEB 01.10.2020 Protokol - Ek (04.03.2022)', email: 'https://mvc.aeo.org.tr//UserFiles/files/2022_1-ek-protokol.pdf' },
      {name: 'SGK-TEB 01.04.2016 Protokol (03.05.2017 Tarihli Revizyon işlenmiş)', email: 'https://mvc.aeo.org.tr//UserFiles/files/SGK-TEB 01_04_2016 Protokol (Revizyon islenmis).docx' },
      {name: 'SGK-TEB 01.10.2020 Protokol - (04.03.2022 Tarihli Revizyon işlenmiş)', email: 'https://mvc.aeo.org.tr//UserFiles/files/SGK-TEB-PROTOKOLU(2022-1-ek_Protokolislenmis).pdf' },
      {name: 'SGK-TEB 01.10.2020 Protokol - (28.04.2023 Tarihli Revizyon işlenmiş)', email: 'https://mvc.aeo.org.tr/UserFiles/files/1_SGK%20-%20TEB%20PROTOKOL%C3%9C%20(2023-1%20Ek%20Protokol%20%C4%B0%C5%9Flenmi%C5%9F).docx' },
      {name: 'SGK-TEB 01.10.2020 Protokol - Ek (28.04.2023)', email: 'https://mvc.aeo.org.tr/UserFiles/files/2_SGK%20-%20TEB%20%C4%B0LA%C3%87%20TEM%C4%B0N%C4%B0NE%20%C4%B0L%C4%B0%C5%9EK%C4%B0N%20PROTOKOL%202023-1.docx' },
    ]

  }),
  created() {
    document.addEventListener("scroll", this.topToBottom);
  }
  ,
  mounted() {
    window.scrollTo(0, 0)
  }
  ,
  methods: {
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    }
    ,
    clickHrefMethod(href) {
      window.open(href, "_blank");
    }
    ,
    topToBottom() {
      const result = document.querySelector(".go-top");
      if (
          document.body.scrollTop > window.innerHeight ||
          document.documentElement.scrollTop > window.innerHeight
      ) {
        result.classList.add("active");
      } else {
        result.classList.remove("active");
      }
    }
    ,
  }
  ,
}
;
</script>

<style scoped>

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f2f2f2;
}

tr:hover {
  background-color: #f5f5f5;
}

td div {
  cursor: pointer;
  text-decoration: underline;
  color: #0072a2;
}

.container {
  margin-top: 10vh;
}

@media (max-width: 992px) {
  .container {
    margin-top: 10vh;
  }
}
</style>
