import axios from 'axios';

const ShiftService = {
    getShift(payload) {
        return axios.get(`https://mvc.aeo.org.tr/home/NobetciEczaneGetirTarih?nobetTarihi=${payload}`)
            .then(response => {

                return response.data;
            })
            .catch(error => {
                console.error('Shift API Error:', error);
                throw new Error('Shift API Error');
            });
    }
};

export default ShiftService;
