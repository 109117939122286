<template>
  <section class="about-item-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="about-tab-btn mt-20">
            <hr/>
            <br/>
            <ul
                id="pills-tab"
                class="nav nav-pills mb-3 d-flex justify-content-center"
                role="tablist"
            >
              <li
                  class="nav-item"
                  role="presentation"
                  @click.prevent="selectTab('admin')"
              >
                <a
                    id="pills-home-tab"
                    :class="[selected === 'admin' ? 'active' : '']"
                    aria-controls="pills-home"
                    aria-selected="true"
                    class="nav-link"
                    data-toggle="pill"
                    href="#pills-home"
                    role="tab"
                >Yönetim Kurulu</a
                >
              </li>
              <li
                  class="nav-item"
                  role="presentation"
                  @click.prevent="selectTab('supervision')"
              >
                <a
                    id="pills-profile-tab"
                    :class="[selected === 'supervision' ? 'active' : '']"
                    aria-controls="pills-profile"
                    aria-selected="false"
                    class="nav-link"
                    data-toggle="pill"
                    href="#pills-profile"
                    role="tab"
                >Denetleme Kurulu</a
                >
              </li>
              <li
                  class="nav-item"
                  role="presentation"
                  @click.prevent="selectTab('honor')"
              >
                <a
                    id="pills-honor-tab"
                    :class="[selected === 'honor' ? 'active' : '']"
                    aria-controls="pills-honor"
                    aria-selected="false"
                    class="nav-link"
                    data-toggle="pill"
                    href="#pills-honor"
                    role="tab"
                >Haysiyet Kurulu</a
                >
              </li>
              <li
                  class="nav-item"
                  role="presentation"
                  @click.prevent="selectTab('teb')"
              >
                <a
                    id="pills-teb-tab"
                    :class="[selected === 'teb' ? 'active' : '']"
                    aria-controls="pills-teb"
                    aria-selected="false"
                    class="nav-link"
                    data-toggle="pill"
                    href="#pills-teb"
                    role="tab"
                >TEB Büyük Kongre Delegeleri</a
                >
              </li>
            </ul>
            <hr/>
            <div id="pills-tabContent" class="tab-content">
              <div id="pills-home" :class="[selected === 'admin' ? 'show active' : '']" aria-labelledby="pills-home-tab"
                   class="tab-pane fade" role="tabpanel">
                <!-- Admin Tab Content -->
                <div class="about-post-items">
                  <div class="row">
                    <template v-for="(data, index) in value.slice(0,1)">
                      <div :key="index" class="col-lg-4 col-md-6 mx-auto">
                        <div class="trending-news-item mb-30">
                          <div class="trending-news-thumb card-body">
                            <img :src="require(`@/assets/images/${data.image}`)" alt="admin"/>
                          </div>
                          <div class="trending-news-content text-center">
                            <h3 class="title text-center">
                              <div class="buttonabout"
                                     >
                                {{ data.degree }}
                                <br/>
                                {{ data.title }}
                              </div>
                            </h3>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                  <div class="row">
                    <template v-for="(data, index) in value.slice(1)">
                      <div :key="index" class="col-lg-4 col-md-6">
                        <div class="trending-news-item mb-30">
                          <div class="trending-news-thumb card-body">
                            <img :src="require(`@/assets/images/${data.image}`)" alt="admin"/>
                          </div>
                          <div class="trending-news-content text-center">
                            <h3 class="title text-center">
                              <div class="buttonabout"
                                     >
                                {{ data.degree }}
                                <br/>
                                {{ data.title }}

                              </div>
                            </h3>
                          </div>
                        </div>
<!--                        <div-->
<!--                            :id="'modal-' + index"-->
<!--                            aria-hidden="true"-->
<!--                            aria-labelledby="staticBackdropLabel"-->
<!--                            class="modal fade"-->
<!--                            data-bs-backdrop="static"-->
<!--                            data-bs-keyboard="false"-->
<!--                            tabindex="-1"-->
<!--                        >-->
<!--                          &lt;!&ndash; Modal Content &ndash;&gt;-->
<!--                          <div class="modal-dialog">-->
<!--                            <div class="modal-content">-->
<!--                              <div class="modal-header">-->
<!--                                <h5 id="staticBackdropLabel" class="modal-title">Biyografi</h5>-->
<!--                                <button aria-label="Close" class="btn-close" data-bs-dismiss="modal"-->
<!--                                        type="button"></button>-->
<!--                              </div>-->
<!--                              <div class="modal-body">-->
<!--                                <div>-->
<!--                                  {{ data.article }}-->
<!--                                </div>-->
<!--                              </div>-->
<!--                              <div class="modal-footer">-->
<!--                                <button class="btn btn-secondary" data-bs-dismiss="modal" type="button">Kapat</button>-->
<!--                              </div>-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </div>-->
                      </div>
                    </template>
                  </div>
                </div>
              </div>
              <div id="pills-profile" :class="[selected === 'supervision' ? 'show active' : '']" aria-labelledby="pills-profile-tab"
                   class="tab-pane fade" role="tabpanel">
                <!-- Supervision Tab Content -->
                <div class="about-post-items">
                  <div class="row">
                    <template v-for="(data, index) in value.slice(0,1)">
                      <div :key="index" class="col-lg-4 col-md-6 mx-auto">
                        <div class="trending-news-item mb-30 card-body">
                          <div class="trending-news-thumb">
                            <img :src="require(`@/assets/images/${data.image}`)" alt="trending"/>
                          </div>
                          <div class="trending-news-content text-center">
                            <h3 class="title text-center">
                              <div class="buttonabout"
                                      >
                                {{ data.degree }}
                                <br/>
                                {{ data.title }}
                              </div>
                            </h3>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                  <div class="row justify-content-center">
                    <template v-for="(data, index) in value.slice(1)">
                      <div :key="index" class="col-lg-4 col-md-6">
                        <div class="trending-news-item mb-30">
                          <div class="trending-news-thumb card-body">
                            <img :src="require(`@/assets/images/${data.image}`)" alt="trending"/>
                          </div>
                          <div class="trending-news-content text-center">
                            <h3 class="title text-center">
                              <div class="buttonabout"
                                     >
                                {{ data.degree }}
                                <br/>
                                {{ data.title }}
                              </div>
                            </h3>
                          </div>
                        </div>
<!--                        <div-->
<!--                            :id="'modal-' + index"-->
<!--                            aria-hidden="true"-->
<!--                            aria-labelledby="staticBackdropLabel"-->
<!--                            class="modal fade"-->
<!--                            data-bs-backdrop="static"-->
<!--                            data-bs-keyboard="false"-->
<!--                            tabindex="-1"-->
<!--                        >-->
<!--                          &lt;!&ndash; Modal Content &ndash;&gt;-->
<!--                          <div class="modal-dialog">-->
<!--                            <div class="modal-content">-->
<!--                              <div class="modal-header">-->
<!--                                <h5 id="staticBackdropLabel" class="modal-title">Biyografi</h5>-->
<!--                                <button aria-label="Close" class="btn-close" data-bs-dismiss="modal"-->
<!--                                        type="button"></button>-->
<!--                              </div>-->
<!--                              <div class="modal-body">-->
<!--                                <div>-->
<!--                                  {{ data.article }}-->
<!--                                </div>-->
<!--                              </div>-->
<!--                              <div class="modal-footer">-->
<!--                                <button class="btn btn-secondary" data-bs-dismiss="modal" type="button">Kapat</button>-->
<!--                              </div>-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </div>-->
                      </div>
                    </template>

                  </div>
                </div>
              </div>
              <div id="pills-honor" :class="[selected === 'honor' ? 'show active' : '']" aria-labelledby="pills-honor-tab"
                   class="tab-pane fade" role="tabpanel">
                <!-- Honor Tab Content -->
                <div class="about-post-items">
                  <div class="row">
                    <template v-for="(data, index) in value.slice(0,1)">
                      <div :key="index" class="col-lg-4 col-md-6 mx-auto">
                        <div class="trending-news-item mb-30 card-body">
                          <div class="trending-news-thumb">
                            <img :src="require(`@/assets/images/${data.image}`)" alt="trending"/>
                          </div>
                          <div class="trending-news-content text-center">
                            <h3 class="title text-center">
                              <div class="buttonabout"
                                     >
                                {{ data.degree }}
                                <br/>
                                {{ data.title }}
                                <br/>
                                {{ data.article }}
                              </div>
                            </h3>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                  <div class="row justify-content-center">
                    <template v-for="(data, zindex) in value.slice(1)">
                      <div :key="zindex" class="col-lg-4 col-md-6">
                        <div class="trending-news-item mb-30">
                          <div class="trending-news-thumb card-body">
                            <img :src="require(`@/assets/images/${data.image}`)" alt="trending"/>
                          </div>
                          <div class="trending-news-content text-center">
                            <h3 class="title text-center">
                              <div class="buttonabout"
                                     >
                                {{ data.degree }}
                                <br/>
                                {{ data.title }}
                                <br/>

                              </div>
                            </h3>
                          </div>
                        </div>
<!--                        <div-->
<!--                            :id="'modal-' + zindex"-->
<!--                            aria-hidden="true"-->
<!--                            aria-labelledby="staticBackdropLabel"-->
<!--                            class="modal fade"-->
<!--                            data-bs-backdrop="static"-->
<!--                            data-bs-keyboard="false"-->
<!--                            tabindex="-1"-->
<!--                        >-->
<!--                          &lt;!&ndash; Modal Content &ndash;&gt;-->
<!--                          <div class="modal-dialog">-->
<!--                            <div class="modal-content">-->
<!--                              <div class="modal-header">-->
<!--                                <h5 id="staticBackdropLabel" class="modal-title">Biyografi</h5>-->
<!--                                <button aria-label="Close" class="btn-close" data-bs-dismiss="modal"-->
<!--                                        type="button"></button>-->
<!--                              </div>-->
<!--                              <div class="modal-body">-->
<!--                                <div>-->
<!--                                  {{ data.article }}-->
<!--                                </div>-->
<!--                              </div>-->
<!--                              <div class="modal-footer">-->
<!--                                <button class="btn btn-secondary" data-bs-dismiss="modal" type="button">Kapat</button>-->
<!--                              </div>-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </div>-->
                      </div>
                    </template>


                  </div>
                </div>
              </div>
              <div id="pills-teb" :class="[selected === 'teb' ? 'show active' : '']" aria-labelledby="pills-teb-tab"
                   class="tab-pane fade" role="tabpanel">
                <!-- TEB Tab Content -->
                <div class="about-post-items">
                  <div class="row">
                    <template v-for="(data, zindex) in value">
                      <div :key="zindex" class="col-lg-4 col-md-6">
                        <div class="trending-news-item mb-30 card-body">
                          <div class="trending-news-thumb">
                            <img :src="require(`@/assets/images/${data.image}`)" alt="trending"/>
                          </div>
                          <div class="trending-news-content text-center">
                            <h3 class="title text-center">
                              <div class="buttonabout"
                                      >
                                {{ data.degree }}
                                <br/>
                                {{ data.title }}
                                <br/>
<!--                                {{ data.article }}-->
                              </div>
                            </h3>

                          </div>
<!--                          <div-->
<!--                              :id="'modal-' + zindex"-->
<!--                              aria-hidden="true"-->
<!--                              aria-labelledby="staticBackdropLabel"-->
<!--                              class="modal fade"-->
<!--                              data-bs-backdrop="static"-->
<!--                              data-bs-keyboard="false"-->
<!--                              tabindex="-1"-->
<!--                          >-->
<!--                            &lt;!&ndash; Modal Content &ndash;&gt;-->
<!--                            <div class="modal-dialog">-->
<!--                              <div class="modal-content">-->
<!--                                <div class="modal-header">-->
<!--                                  <h5 id="staticBackdropLabel" class="modal-title">Biyografi</h5>-->
<!--                                  <button aria-label="Close" class="btn-close" data-bs-dismiss="modal"-->
<!--                                          type="button"></button>-->
<!--                                </div>-->
<!--                                <div class="modal-body">-->
<!--                                  <div>-->
<!--                                    {{ data.article }}-->
<!--                                  </div>-->
<!--                                </div>-->
<!--                                <div class="modal-footer">-->
<!--                                  <button class="btn btn-secondary" data-bs-dismiss="modal" type="button">Kapat</button>-->
<!--                                </div>-->
<!--                              </div>-->
<!--                            </div>-->
<!--                          </div>-->
                        </div>

                      </div>
                    </template>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
<!--    <div-->
<!--        id="staticBackdrop"-->
<!--        aria-hidden="true"-->
<!--        aria-labelledby="staticBackdropLabel"-->
<!--        class="modal fade"-->
<!--        data-bs-backdrop="static"-->
<!--        data-bs-keyboard="false"-->
<!--        tabindex="-1"-->
<!--    >-->
<!--      <div class="modal-dialog">-->
<!--        <div class="modal-content">-->
<!--          <div class="modal-header">-->
<!--            <h5 id="staticBackdropLabel" class="modal-title">Biyografi</h5>-->
<!--            <button-->
<!--                aria-label="Close"-->
<!--                class="btn-close"-->
<!--                data-bs-dismiss="modal"-->
<!--                type="button"-->
<!--            ></button>-->
<!--          </div>-->
<!--          <div class="modal-body">-->
<!--            <template v-for="(data, index) in value.slice(0,1)">-->
<!--              <div :key="index" class="">-->
<!--                {{ data.article }}-->
<!--              </div>-->
<!--            </template>-->
<!--          </div>-->
<!--          <div class="modal-footer">-->
<!--            <button-->
<!--                class="btn btn-secondary"-->
<!--                data-bs-dismiss="modal"-->
<!--                type="button"-->
<!--            >-->
<!--              Kapat-->
<!--            </button>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </section>
</template>

<script>
import aboutAdmin from "../Data/About";
import AboutSupervision from "../Data/AboutSupervision";
import AboutHonor from "../Data/AboutHonor";
import AboutTEB from "../Data/AboutTEB";

export default {
  data() {
    return {
      selected: "admin",
      value: aboutAdmin.data,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    selectTab(value) {
      switch (value) {
        case "admin":
          this.selected = "admin";
          this.value = aboutAdmin.data;
          break;
        case "supervision":
          this.selected = "supervision";
          this.value = AboutSupervision.data;
          break;
        case "honor":
          this.selected = "honor";
          this.value = AboutHonor.data;
          break;
        case "teb":
          this.selected = "teb";
          this.value = AboutTEB.data;
          break;
      }
    },
  },
};
</script>

<style scoped>
.buttonabout{

  color: #fff;
  background-color: #c82333;

  display: inline-block;
  font-weight: 400;
border: none;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;

  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.trending-news-thumb img {
  padding-left: 80px;
  padding-right: 80px;
}

.about-item-area {
  margin-top: 20px;
}
</style>
