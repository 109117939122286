<template>
  <div>
    <div class="sticky-top">
      <Navbar class="navbar_container"/>
    </div>
    <!--    <Banner/>-->
    <drawer @toggleSidebar="toggleSidebar" :sidebar="sidebar"/>

    <!-- <about-author /> -->
    <div class="container" style="height: 100%">
      <h1 style="text-align: center">Dilekçeler</h1>
      <div>
        <table style="width: 100% ; margin-bottom: 5% " >
          <thead>
          <tr>

            <th>İsim</th>
            <th>Belge</th>

          </tr>
          </thead>
          <tbody>
          <tr v-for="dilekce in dilekce" :key="dilekce.id">

            <td style="font-size: 135%">{{ dilekce.name }}</td>
            <td style="font-size: 135%" @click="clickHrefMethod(dilekce.email)">
              <div style="cursor: pointer;"> Görmek için tıklayınız -></div>
            </td>
          </tr>
          </tbody>
        </table>

      </div>
      <h1 style="text-align: center"> Formlar</h1>
      <div>
        <table style="width: 100% ; margin-bottom: 5%;">
          <thead>
          <tr>

            <th>İsim</th>
            <th>Belge</th>

          </tr>
          </thead>
          <tbody>
          <tr v-for="forms in forms" :key="forms.id">

            <td style="font-size: 135%">{{ forms.name }}</td>
            <td style="font-size: 135%" @click="clickHrefMethod(forms.email)">
              <div style="cursor: pointer;"> Görmek için tıklayınız -></div>
            </td>
          </tr>
          </tbody>
        </table>

      </div>



    </div>

    <footer-one />
    <div class="go-top-area" style="display: none">
      <div class="go-top-wrap">
        <div class="go-top-btn-wrap">
          <a href="#" class="go-top go-top-btn d-block">
            <i class="fa fa-angle-double-up"></i>
            <i class="fa fa-angle-double-up"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Drawer from "../Mobile/Drawer.vue";
import FooterOne from "../Utility/Footer/FooterOne.vue";
// import Banner from "../Utility/Header/Banner";
// import Header from "../Utility/Header/index";
import AboutChaPharma from "../Data/AboutChaPharma.json"
// import AboutAuthor from "./AboutAuthor.vue";
import Navbar from "@/components/Utility/Header/index";
export default {
  components: {
    FooterOne,
    Drawer,
    /*Banner,*/
    Navbar
  },
  data: () => ({
    sidebar : false,
    aboutChaPharma : AboutChaPharma.data,
    dilekce:[

      { name: ' SGK’ya Verilen Reçetelerin Tamamının İncelenmesi İçin Dilekçe', email: ' https://mvc.aeo.org.tr//UserFiles/files/Recetelerin_incelenmesi_SGK-Dilekce-Ornegi.pdf ' },
      { name: 'SGK’ya Sadece Kesinti Yapılan Reçetelerin İncelenmesi İçin Dilekçe', email: 'https://mvc.aeo.org.tr//UserFiles/files/Yalnizca_kesinti_yapilan-recete_SGK-Dilekce-Ornegi.pdf' },
      { name: ' SGK Kavaklıdere Eczaneye Ait Banka Değişikliği Dilekçesi', email: 'https://mvc.aeo.org.tr//UserFiles/files/_2020_hesap_no_dilekce_ornegi.pdf' },
      { name: ' SGK Fatura Tarih/No Düzeltme Dilekçesi', email: 'https://mvc.aeo.org.tr//UserFiles/files/SGK_Fatura_Duzeltme_Dilekcesi_.pdf' },



      {  name: 'SGK Doz Düzeltme Dilekçesi', email: 'https://mvc.aeo.org.tr//UserFiles/files/hatali-doz-duzeltme-dilekcesi_.pdf' },
      { name: 'SGK Döküm İptal Dilekçesi', email: 'http://mvc.aeo.org.tr//UserFiles/files/dokum_iptal_dilekcesi.pdf' },
      { name: ' TEB Yardımlaşma Sandığına Üyelik Dilekçesi ', email: 'https://mvc.aeo.org.tr//UserFiles/files/2024-Yardimlasma-Sandigina-Katilma-Dilekcesi.pdf' },

      ],
    forms:[
      {  name: 'AEO Sıralı ve Kotalı Reçete Dağıtım Başvuru Formu', email: 'https://docs.google.com/forms/d/e/1FAIpQLSfXPujCJT1iXlebv-qhBCEWK02N-lRdo0ruUORsepYom6Y3dA/viewform' },
      { name: 'Eczane Çalışanı Özlük Dosyası ', email: 'https://mvc.aeo.org.tr//UserFiles/files/ÖZLÜK DOSYASI (1).pdf ' },
      { name: 'Eczane Buzdolabı Isı-Nem Kontrol Formu', email: 'https://mvc.aeo.org.tr//UserFiles/files/2024-BUZDOLABI_ISI_NEM_Kontrol-Formu.pdf' },
      { name: 'Eczane Ortam Isı-Nem Kontrol Formu', email: 'https://mvc.aeo.org.tr//UserFiles/files/ISI_NEM_Kontrol_Formu.pdf' },
      { name: 'TEB Kredi Talep Formu', email: 'https://mvc.aeo.org.tr//UserFiles/files/kredi_talep_formu_2023.pdf' },

      { name: ' TİTCK Farmakovijilans Advers Etki Bildirim Formu', email: 'https://www.titck.gov.tr/faaliyetalanlari/ilac/18' },
      { name: 'TİTCK Kozmetik Ürün İstenmeyen Etki Bildirim Formu\n', email: ' https://mvc.aeo.org.tr//UserFiles/files/Kozmetik_Urun_Istenmeyen_Etki_Bildirim_Formu.pdf' },

    ]

  }),
  created() {
    document.addEventListener("scroll", this.topToBottom);
  },
  mounted () {
    window.scrollTo(0, 0)
  },
  methods: {
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
    clickHrefMethod(href) {
      window.open(href, "_blank");
    },
    topToBottom() {
      const result = document.querySelector(".go-top");
      if (
          document.body.scrollTop > window.innerHeight ||
          document.documentElement.scrollTop > window.innerHeight
      ) {
        result.classList.add("active");
      } else {
        result.classList.remove("active");
      }
    },
  },
};
</script>

<style scoped>

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f2f2f2;
}

tr:hover {
  background-color: #f5f5f5;
}

td div {
  cursor: pointer;
  text-decoration: underline;
  color: #0072a2;
}

.container {
  margin-top: 10vh;
}

@media (max-width: 992px) {
  .container {
    margin-top: 10vh;
  }
}
</style>
