import SearchService from "@/services/search.service";
import AnnouncementService from "@/services/announcement.service";

const initialState = {
    AdvertsPaginatedSearchResult: {dtPagination: {}, item: []},

    selectedAdvert: {},
}

export const advertModule = {
    namespaced: true,
    state: initialState,
    actions: {
        getAdvertsPaginated({commit}, payload) {
            return SearchService.getAnnouncementSearchPaginated(payload).then(res => {
                if (res.data) commit('setAdvertsPaginated', res.data);
                return Promise.resolve(res.data);
            });
        },
        getAdvertDetail({commit}, payload) {
            return AnnouncementService.getAnnouncementDetail(payload).then(res => {
                if (res.data) commit('setSelectedAdvert', res.data);
                return Promise.resolve(res.data);
            });
        }
    },
    getters: {
        getAdvertsPaginatedSearchResultItem(state) {
            return state.AdvertsPaginatedSearchResult.item;
        },
        getSelectedAdvert(state) {
            return state.selectedAdvert.data;
        },
    },
    mutations: {
        setAdvertsPaginated(state, payload) {
            state.AdvertsPaginatedSearchResult.item = Object.assign([], payload.endpointResult.data);
            state.AdvertsPaginatedSearchResult.dtPagination = Object.assign({}, payload.pagination);
        },
        setSelectedAdvert(state, payload) {
            state.selectedAdvert = Object.assign({}, payload);
        },

    },
}