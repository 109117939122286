import axios from 'axios';


class SearchService {


    getAnnouncementSearchPaginated(payload) {
        return axios.post(`${process.env.VUE_APP_AEO_FRONTEND_API}Page/filter-announcements-paginated`,
            {
                Keyword: payload?.keyword,
                PublishPlaceIds: payload?.checkedPublishPlaces,
                PageTypeId: payload?.pageTypeId,
                SubjectTypeIds: payload?.checkedSubjectTypes,
                DateInterval: payload?.dateInterval,
                IsApproved: 1,
                PageNumber: payload.currentPage, PageLength: payload.pageSize
            }
        )
    }


}

export default new SearchService();