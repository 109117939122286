<template>
  <div>
    <div class="sticky-top">
      <Navbar class="navbar_container"/>
    </div>
    <!--<Banner/>    -->
    <drawer @toggleSidebar="toggleSidebar" :sidebar="sidebar"/>


    <!-- <about-author /> -->
    <div class="container">
      <other/>
    </div>

    <footer-one/>
    <div class="go-top-area">
      <div class="go-top-wrap">
        <div class="go-top-btn-wrap">
          <a href="#" class="go-top go-top-btn d-block">
            <i class="fa fa-angle-double-up"></i>
            <i class="fa fa-angle-double-up"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Drawer from "../Mobile/Drawer.vue";
import FooterOne from "../Utility/Footer/FooterOne.vue";
/*import Banner from "../Utility/Header/Banner";*/
// import Header from "../Utility/Header/index";
// import AboutAuthor from "./AboutAuthor.vue";
import Other from "./Other.vue";
import Navbar from "@/components/Utility/Header/index";

export default {
  components: {
    // Header,
    Other,
    FooterOne,
    Drawer,
    /*     Banner,*/
    Navbar
  },
  data: () => ({
    sidebar: false,
  }),
  created() {
    document.addEventListener("scroll", this.topToBottom);
  },
  methods: {
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
    mounted() {
      window.scrollTo(0, 0)
    },
    topToBottom() {
      const result = document.querySelector(".go-top");
      if (
          document.body.scrollTop > window.innerHeight ||
          document.documentElement.scrollTop > window.innerHeight
      ) {
        result.classList.add("active");
      } else {
        result.classList.remove("active");
      }
    },
  },
};
</script>

<style scoped>
.container {
  margin-top: 10vh;
}

@media (max-width: 992px) {
  .container {
    margin-top: 10vh;
  }
}

</style>
