import { render, staticRenderFns } from "./AcademicEducation.vue?vue&type=template&id=b9320112&scoped=true&"
import script from "./AcademicEducation.vue?vue&type=script&lang=js&"
export * from "./AcademicEducation.vue?vue&type=script&lang=js&"
import style0 from "./AcademicEducation.vue?vue&type=style&index=0&id=b9320112&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b9320112",
  null
  
)

export default component.exports