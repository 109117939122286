<template>
  <div>
    <div class="sticky-top">
      <Navbar class="navbar_container"/>
    </div>
    <!--    <Banner/>-->
    <drawer :sidebar="sidebar" @toggleSidebar="toggleSidebar"/>

    <!-- <about-author /> -->
    <div class="container">
      <h4>
        POLİTİKA: </h4>

        Ankara Eczacı Odası ilgili mevzuat ile kendisine verilen görev ve sorumluluklar çerçevesinde meslektaşlarımızın
        beklentilerini tarafsızlık ve güvenilirlik ilkelerine bağlı kalarak eksiksiz, doğru ve zamanında karşılamayı
        amaç edinir. Eczacılığın gelişimi için yeni hedefler belirlemeyi, bunlara yönelik planlar yapmayı, gerekli
        kaynakları ayırarak bu planları hayata geçirmeyi ve üye memnuniyetini sağlamayı benimser. Sürekli gelişme
        düşüncesiyle misyonunu ve politikalarını düzenli olarak gözden geçirmeyi amaçlar. Üstlendiği misyon ve vizyon
        aracılığıyla ürettiği hizmetin kalitesini, kamuya ve diğer kurum ve kuruluşlara örnek olmayı taahhüt eder.

      <h4> MİSYON:</h4>

        Ankara Eczacı Odası’nın misyonu meslek etiği ve deontolojisine bağlı çalışmak, üyelerinin katılımıyla güçlü,
        aktif ve katılımcı bir mesleki örgütlenme oluşturmak, mevcut örgütlenme gücü ile eczacılık mesleğinin (eczane,
        hastane, kamu; özel sektör vb.) tüm alanlarındaki sorunlarına çözümler aramak, kamu kaynaklarını etkin ve doğru
        kullanmak, ilaç- eczacılık hizmetinin kalitesini arttırarak toplum sağlığına ve yurttaşların yaşam kalitesine
        olumlu katkıda bulunmak, toplum sağlığını koruyacak politikalar oluşturup hayata geçirmek, düzenli olarak
        gerçekleştirilen meslek içi eğitim programlar, kongre, sempozyum, bilgi yarışmaları ve yayınlar ile üyelerinin
        ve öğrencilerin mesleki gelişimine katkı sunmak, eczacıların ekonomik bakımdan güvence altına alınmasını
        sağlayan ecza kooperatiflerinin gelişimi için kooperatiflerle dayanışma içinde bulunmak, eczacılık fakültesi
        öğrencilerinin mesleğini önemseyen, araştırmacı/sorgulayıcı ve yaratıcı niteliklere sahip bireyler olarak
        yetişmelerine katkı sağlamak için öğrenciler ile yakın ilişkiler kurmak, eczacılık eğitiminin güncel bilimsel
        gelişmeler ve toplumsal ihtiyaçlar doğrultusunda geliştirilmesi, eczacılıkta uzmanlık ve akreditasyon
        çalışmalarının verimli bir şekilde yürütülmesi için eczacılık fakülteleri iş birliği içinde olmaktır.
<br>
       <h4> VİZYON: </h4>

        Ülkemiz ve dünyadaki eczacılık meslek odaları arasında öncü konuma bulunan, üyelerine ve toplum sağlığına karşı
        sorumluluk bilinciyle hareket eden, etik ilkelerden taviz vermeyen, değişen sosyal ve bilimsel koşullara uyum
        sağlayan, bilgiyi ve teknolojiyi etkin şekilde kullanabilen, değişime ve yeniliğe açık, yönetimde katılımcılık,
        hesap verebilirlik ve şeffaflığı savunan, paydaşlarıyla iş birliğini önceleyen, evrensel düzeyde kabul gören
        saygın, yenilikçi ve özgün bir Eczacı Odası olmaktır.

    </div>

    <footer-one/>
    <div class="go-top-area" style="display: none">
      <div class="go-top-wrap">
        <div class="go-top-btn-wrap">
          <a class="go-top go-top-btn d-block" href="#">
            <i class="fa fa-angle-double-up"></i>
            <i class="fa fa-angle-double-up"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Drawer from "../Mobile/Drawer.vue";
import FooterOne from "../Utility/Footer/FooterOne.vue";
// import Banner from "../Utility/Header/Banner";
// import Header from "../Utility/Header/index";
import AboutChaPharma from "../Data/AboutChaPharma.json"
// import AboutAuthor from "./AboutAuthor.vue";
import Navbar from "@/components/Utility/Header/index";

export default {
  components: {
    FooterOne,
    Drawer,
    /*Banner,*/
    Navbar
  },
  data: () => ({
    sidebar: false,
    aboutChaPharma: AboutChaPharma.data

  }),
  created() {
    document.addEventListener("scroll", this.topToBottom);
  },
  mounted() {
    window.scrollTo(0, 0)
  },
  methods: {
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
    topToBottom() {
      const result = document.querySelector(".go-top");
      if (
          document.body.scrollTop > window.innerHeight ||
          document.documentElement.scrollTop > window.innerHeight
      ) {
        result.classList.add("active");
      } else {
        result.classList.remove("active");
      }
    },
  },
};
</script>

<style scoped>
.container {
  margin-top: 10vh;
}

@media (max-width: 992px) {
  .container {
    margin-top: 10vh;
  }
}
</style>
