<template>
  <div>
    <div class="sticky-top">
      <Navbar class="navbar_container"/>
    </div>
    <Banner/>
    <drawer @toggleSidebar="toggleSidebar" :sidebar="sidebar"/>

    <!-- <about-author /> -->
    <div class="container">
      <h4 class="title">Ankara Eczacı Odası Anlaşmalı Firmalar</h4>
      <div class="row">
        <div class="col-md-6 mb-4" v-for="data in post" :key="data">
          <div class="card mb-3" style="max-width: 540px">
            <div class="row g-0">
              <div class="img_container col-3">
                <img
                  :src="require(`@/assets/images/${data.image}`)"
                  class="img-fluid rounded-start"
                />
              </div>
              <div class="col-9">
                <div class="card-body justify-content-between">
                  <div class="">
                    <h5 class="card-title">{{ data.title }}</h5>
                    <p class="card-text">
                      <small class="text-muted">Last updated 3 mins ago</small>
                    </p>
                  </div>
                  <div class="mt-3">
                    <router-link
                      to="/posts/postthree"
                      tag="button"
                      class="btn btn-outline-danger btn-sm"
                      >Devamını okuyun...</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <footer-one />
    <div class="go-top-area" style="display: none">
      <div class="go-top-wrap">
        <div class="go-top-btn-wrap">
          <a href="#" class="go-top go-top-btn d-block">
            <i class="fa fa-angle-double-up"></i>
            <i class="fa fa-angle-double-up"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Drawer from "../Mobile/Drawer.vue";
import FooterOne from "../Utility/Footer/FooterOne.vue";
// import Header from "../Utility/Header/index";
import Post from "../Data/post.json";
import Banner from "../Utility/Header/Banner";
import Navbar from "@/components/Utility/Header/index";
// import AboutAuthor from "./AboutAuthor.vue";
export default {
  components: {

    FooterOne,
    Drawer,
    Banner,
    Navbar
  },
  data: () => ({
    sidebar: false,
    post: Post.data,
  }),
  created() {
    document.addEventListener("scroll", this.topToBottom);
  },
  mounted () {
  window.scrollTo(0, 0)
},
  methods: {
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
    topToBottom() {
      const result = document.querySelector(".go-top");
      if (
        document.body.scrollTop > window.innerHeight ||
        document.documentElement.scrollTop > window.innerHeight
      ) {
        result.classList.add("active");
      } else {
        result.classList.remove("active");
      }
    },
  },
};
</script>

<style scoped>
  .container{
    margin-top: 20px;
  }
  @media (max-width: 992px) {
  .container{
    margin-top: 20px;
  }
  
}
.img-fluid {
  height: 100%;
}

.img_container {
  height: 180px;
  width: 120px;
}

.card {
  max-height: 180px;
  border-left: 5px solid gray; 
}
.card:hover{
  border-left: 5px solid #c51e2f; 
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

@media (max-width: 576px) { 
  .img_container {
  height: 200px;
  width: 120px;
}

.card {
  max-height: 200px;
  
}
 }



.card-body{
  
}


</style>
