<template>
  <div>
    <div class="sticky-top">
      <Navbar class="navbar_container"/>
    </div>
    <!--    <Banner/>-->
    <drawer :sidebar="sidebar" @toggleSidebar="toggleSidebar"/>

    <!-- <about-author /> -->
    <div class="container">
      <div class="row">
      <h1 class="ortalayıcı"> Üyelere Özel</h1>
      </div>
      <div class="row " style="display: flex; justify-content: center">
        <div class="col-lg-6 ortalayıcı">
          <button style="border: 1px solid #e5e5e5; background-color: white"
                  @click="clickHrefMethod('https://mvc.aeo.org.tr/account/login')">
            <img
                :src="require(`@/assets/images/eos.png`)"
                alt="trending"
                height="300px"
                width="540px"
            />
            <h5 style="text-align: center"> EOS GİRİŞ</h5>
          </button>
        </div>


        <div class="col-lg-6 ortalayıcı">

          <button style="border: 1px solid #e5e5e5; background-color: white"
                  @click="clickHrefMethod('https://mvc.aeo.org.tr/AeoRehber/RehberGiris')">
            <img :src="require(`@/assets/images/rehberfoto.png`)"
                 alt="trending"
                 class="ortalayıcı"
                 height="300px"
                 width="540px"
            />
            <h5 style="text-align: center"> AEO REHBER</h5>
          </button>
        </div>
      </div>

      <div class="row " style=" justify-content: center;display: flex; text-align: center">

        <div class=" ortalayıcı">
          <img :src="require(`@/assets/images/qr.jpeg`)"
               alt="trending"
               class="ortalayıcı"
               style="width: 30%"



          />

        </div>
        <h5 style="text-align: center"> Uygulamalarımız</h5>
      </div>


    </div>

    <footer-one/>
    <div class="go-top-area" style="display: none">
      <div class="go-top-wrap">
        <div class="go-top-btn-wrap">
          <a class="go-top go-top-btn d-block" href="#">
            <i class="fa fa-angle-double-up"></i>
            <i class="fa fa-angle-double-up"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Drawer from "../Mobile/Drawer.vue";
import FooterOne from "../Utility/Footer/FooterOne.vue";
// import Banner from "../Utility/Header/Banner";
// import Header from "../Utility/Header/index";
import AboutChaPharma from "../Data/AboutChaPharma.json"
// import AboutAuthor from "./AboutAuthor.vue";
import Navbar from "@/components/Utility/Header/index";

export default {
  components: {
    FooterOne,
    Drawer,
    /*Banner,*/
    Navbar
  },
  data: () => ({
    sidebar: false,
    aboutChaPharma: AboutChaPharma.data

  }),
  created() {
    document.addEventListener("scroll", this.topToBottom);
  },
  mounted() {
    window.scrollTo(0, 0)
  },
  methods: {
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
    clickHrefMethod(href) {
      window.open(href, "_blank");
    },
    topToBottom() {
      const result = document.querySelector(".go-top");
      if (
          document.body.scrollTop > window.innerHeight ||
          document.documentElement.scrollTop > window.innerHeight
      ) {
        result.classList.add("active");
      } else {
        result.classList.remove("active");
      }
    },
  },
};
</script>

<style scoped>
.ortalayıcı {
  display: flex;
  justify-content: center;
  vertical-align: center;
  horiz-align: center;
}

.container {
  margin-top: 10vh;
}

@media (max-width: 992px) {
  .container {
    margin-top: 10vh;
  }
}

</style>
