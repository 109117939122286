import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'
import Dark from '../views/Dark'
import HomeTwo from '../views/HomeTwo'
import HomeThree from '../views/HomeThree'
import Contact from '../views/Contact.vue'
import CvLoading from '../components/Contacts/CvLoading.vue'
// import About from '../views/About.vue'
import AboutHistory from '../components/About/AboutHistory'
import Teb from '../components/Posts/Teb'
import AboutChaPharma from '../components/About/AboutChaPharma.vue'
import AboutPolicy from '../components/About/AboutPolicy.vue'
import Archive from '../views/Archive.vue'
import fourOfour from '../components/404.vue'
import AllPosts from '../components/Posts/AllPosts'
import PostThree from '../components/Posts/PostThree/index'
import PostEvent from '../components/Posts/PostThree/postEvent'
import PostEventAcademic from '../components/Posts/PostThree/postEventAcademic'
import ImpInformation from '../components/Posts/ImpInformation'
import InfoDocs from '../components/Posts/InfoDocs'
import DOZ from '../components/Posts/DOZ'
import Yayinlar from '../components/Posts/Yayinlar'
import AcademicVideo from '../components/Posts/AcademicVideo'
import ShortMovie from '../components/Posts/ShortMovie'
import SUT from '../components/Posts/SUT'
import Agreement from '../components/Posts/Agreement'
import SocialEvent from '../components/Posts/SocialEvent'
import AcademicEducation from '../components/Posts/AcademicEducation'
import AdvertList from '../components/Posts/AdvertList'
import AdvertPost from '../components/Posts/AdvertPost'
import CrisisPha from '../components/Posts/CrisisPha'

import Search from '../components/Utility/Search'
import PhaSearch from '../components/Utility/PhaSearch'
import Business from '../components/category/Buisness.vue'
import Entertainment from '../components/category/Entertainment.vue'
import Features from '../components/category/Feature.vue'
import Sports from '../components/category/Sports.vue'
import Trending from '../components/category/Trending.vue'
import AboutIndex from '../components/About/index'

import Sutmen from "@/components/E-Library/Sut.vue";
import AboutQuality from '../components/About/AboutQuality.vue'
import lawVideo from "@/components/E-Library/lawVideo.vue";
import protocol from "@/components/E-Library/protocol.vue";
import guides from "@/components/E-Library/Guides.vue";
import memberOnly from "@/components/Member process/memberOnly.vue";
import pharmacyGuide from "@/components/Member process/pharmacyGuide.vue";
import petitionForms from "@/components/Member process/petitionForms.vue";
import yayinlar from "@/components/E-Library/yayinlar.vue";
import renkli from "@/components/E-Library/renkli.vue";
import baskandan from "@/components/Duyuru/Baskandan.vue";
import Apps from "@/components/Apps/apps.vue";
// import kısaFilm from "@/components/E-Library/kısaFilm.vue"
Vue.use(VueRouter)

const routes = [
    {
        path: '/yayinlar',
        name: 'yayinlar',
        component: yayinlar
    },
    // {
    //     path: '/kisa',
    //     name: 'kısaFilm',
    //     component: kısaFilm
    // },
    {
        path: '/baskan',
        name: 'baskan',
        component: baskandan
    },
    {
        path: '/colorful',
        name:'renkliİlac',
        component: renkli,
    },
    {
        path: '/law-video',
        name: 'lawVideo',
        component: lawVideo
    },{
        path: '/protocol',
        name: 'protocol',
        component: protocol
    },{
        path: '/Guides',
        name: 'guides',
        component: guides
    },{
        path: '/member-only',
        name: 'memberOnly',
        component: memberOnly
    },{
        path: '/pharmacy-guide',
        name: 'pharmacyGuide',
        component: pharmacyGuide
    },{
        path: '/petition-forms',
        name: 'petitionForms',
        component: petitionForms
    },


    {
        path: '/',
        name: 'Home',
        component: Home
    },

    {
        path: '/dark',
        name: 'Dark',
        component: Dark
    },
    {
        path: '/home-two',
        name: 'HomeTwo',
        component: HomeTwo
    },
    {
        path: '/duyurular/:subjectTypeId/:publishPlaceId/:id/:url',
        name: 'Post',
        component: PostThree
    },
    {
        path: '/home-three',
        name: 'HomeThree',
        component: HomeThree
    },
    {
        path: '/sutmenu',
        name: 'alkolCevirme',
        component: Sutmen
    },
    {
        path: '/search',
        name: 'Search',
        component: Search,
        props : true
    },

    {
        path: '/phaSearch',
        name: 'PhaSearch',
        component: PhaSearch
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact
    },
    {
        path: '/cv-loading',
        name: 'CvLoading',
        component: CvLoading
    },
    {
        path: '/about-policy',
        name: 'About',
        component: AboutPolicy
    },
    {
        path: '/about-history',
        name: 'AboutHistory',
        component: AboutHistory
    },
    {
        path: '/about-chapharma',
        name: 'AboutChaPharma',
        component: AboutChaPharma
    },
    {
        path: '/about-quality',
        name: 'AboutQuality',
        component: AboutQuality
    },
    {
        path: '/archive',
        name: 'Archive',
        component: Archive
    },
    {
        path: '/404',
        name: '404',
        component: fourOfour
    },
    {
        path: '/about-us',
        name: 'AboutUs',
        component: AboutIndex
    },
    {
        path: '/allposts/:name/:id',
        name: 'AllPosts',
        props: true,
        component: AllPosts
    },
    {
        path: '/teb/TEB/34',
        name: 'TEB',
        props: true,
        component: Teb
    },
    {
        path: '/posts/post-event-academic/:id',
        name: 'PostEventAcademic',
        props: true,
        component: PostEventAcademic
    },
    {
        path: '/posts/post-event/:id',
        name: 'PostEvent',
        props: true,
        component: PostEvent
    },
    {
        path: '/imp-information',
        name: 'ImpInformation',
        component: ImpInformation
    },
    {
        path: '/Info-Docs',
        name: 'InfoDocs',
        component:InfoDocs
    },
    {
        path: '/doz',
        name: 'DOZ',
        component: DOZ
    },
    {
        path: '/yayinlar',
        name: 'Yayinlar',
        component: Yayinlar
    },
    {
        path: '/academic-video',
        name: 'AcademicVideo',
        component: AcademicVideo
    },
    {
        path: '/short-movie',
        name: 'ShortMovie',
        component: ShortMovie
    },
    {
        path: '/sut',
        name: 'SUT',
        component: SUT
    },
    {
        path: '/agreement',
        name: 'Agrement',
        component: Agreement
    },
    {
        path: '/social-event',
        name: 'SocialEvent',
        component: SocialEvent
    },
    {
        path: '/academic-education',
        name: 'AcademicEducation',
        component: AcademicEducation
    },
    {
        path: '/advert-list',
        name: 'AdvertList',
        component: AdvertList
    },
    {
        path: '/crisis-pha',
        name: 'CrisisPha',
        component: CrisisPha
    },
    {
        path: '/advert-post/:subjectTypeId/:publishPlaceId/:id',
        name: 'AdvertPost',
        props: true,
        component: AdvertPost
    },
    {
        path: '/business',
        name: 'Business',  
        component: Business
    },
    {
        path: '/entertainment',
        name: 'Entertainment',
        component: Entertainment
    },
    {
        path: '/features',
        name: 'Features',
        component: Features
    },
    {
        path: '/sports',
        name: 'Sports',
        component: Sports
    },
    {
        path: '/trending',
        name: 'Trending',
        component: Trending
    },
    {
        path: '/apps',
        name: 'Apps',
        component: Apps
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
