var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"trending-news-area"},[_c('div',{staticClass:"container",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"row",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"col-lg-12"},[_c('div',{class:[
            'section-title',
            _vm.darkClass && 'title' in _vm.darkClass ? _vm.darkClass.title : '' ]},[_c('h3',{staticClass:"title",staticStyle:{"text-align":"center"}},[_vm._v("Konu Grupları ")])]),_c('div',{staticClass:"trending-news-slider position-relative"},[_c('VueSlickCarousel',_vm._b({attrs:{"arrows":true,"dots":true}},'VueSlickCarousel',_vm.trendingSettings,false),[_c('div',{staticClass:"px-2"},[_c('divide-card',{attrs:{"datas":_vm.objdata[0]}})],1),_c('div',{staticClass:"px-2"},[_c('divide-card',{class:[
                    _vm.darkClass && 'item' in _vm.darkClass ? _vm.darkClass.item : '' ],attrs:{"datas":_vm.objdata[4],"stype":"col"}})],1),_c('div',{staticClass:"px-2"},[_c('divide-card',{class:[
                    _vm.darkClass && 'item' in _vm.darkClass ? _vm.darkClass.item : '' ],attrs:{"datas":_vm.objdata[5],"stype":"col"}})],1),_c('div',{staticClass:"px-2"},[_c('divide-card',{class:[
                    _vm.darkClass && 'item' in _vm.darkClass ? _vm.darkClass.item : '' ],attrs:{"datas":_vm.objdata[6],"stype":"col"}})],1),_c('div',{staticClass:"px-2"},[_c('divide-card',{class:[
                    _vm.darkClass && 'item' in _vm.darkClass ? _vm.darkClass.item : '' ],attrs:{"datas":_vm.objdata[7],"stype":"col"}})],1),_c('div',{staticClass:"px-2"},[_c('divide-card',{class:[
                    _vm.darkClass && 'item' in _vm.darkClass ? _vm.darkClass.item : '' ],attrs:{"datas":_vm.objdata[8],"stype":"col"}})],1),_c('div',{staticClass:"px-2"},[_c('divide-card',{class:[
                    _vm.darkClass && 'item' in _vm.darkClass ? _vm.darkClass.item : '' ],attrs:{"datas":_vm.objdata[9],"stype":"col"}})],1)])],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }