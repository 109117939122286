<template>
  <div>
    <div class="sticky-top">
      <Navbar class="navbar_container"/>
    </div>
    <!--    <Banner/>-->
    <drawer :sidebar="sidebar" @toggleSidebar="toggleSidebar"/>
    <!-- <about-author /> -->
    <div class="container content">
      <h4>Bilgi ve Belgeler</h4>
      <div class="row">
        <div class="col-md-6">
          <div
              id="accordionFlushExample row"
              class="accordion accordion-flush card"
          >
            <div
                v-for="(data, index) in impInformation.slice(0,3)"
                :key="index"
                class="accordion-item "
            >
              <h2 :id="data._id.header" class="accordion-header card-header">
                <button
                    :aria-controls="data._id.list"
                    :data-bs-target="`#${data._id.list}`"
                    aria-expanded="false"
                    class="btn btn-light w-100 accordion-button collapsed"
                    data-bs-toggle="collapse"
                    type="button"
                >
                  {{ data.title }}
                </button>
              </h2>
              <div
                  :id="data._id.list"
                  :aria-labelledby="data._id.header"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  <ul
                      v-for="(item, itemIndex) in data.list"
                      :key="itemIndex"
                      class="list-group"
                  >
                    <li class="list-group-item">
                      <a :href="item.link">{{ item.item }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

          </div>

        </div>
        <div class="col-md-6">
          <div
              id="accordionFlushExample row"
              class="accordion accordion-flush card"
          >
            <div
                v-for="(data, index) in impInformation.slice(3)"
                :key="index"
                class="accordion-item "
            >
              <h2 :id="data._id.header" class="accordion-header card-header">
                <button
                    :aria-controls="data._id.list"
                    :data-bs-target="`#${data._id.list}`"
                    aria-expanded="false"
                    class="btn btn-light w-100 accordion-button collapsed"
                    data-bs-toggle="collapse"
                    type="button"
                >
                  {{ data.title }}
                </button>
              </h2>
              <div
                  :id="data._id.list"
                  :aria-labelledby="data._id.header"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  <ul
                      v-for="(item, itemIndex) in data.list"
                      :key="itemIndex"
                      class="list-group"
                  >
                    <li class="list-group-item">
                      <a :href="item.link">{{ item.item }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
    <footer-one/>
    <div class="go-top-area" style="display: none">
      <div class="go-top-wrap">
        <div class="go-top-btn-wrap">
          <a class="go-top go-top-btn d-block" href="#">
            <i class="fa fa-angle-double-up"></i>
            <i class="fa fa-angle-double-up"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FooterOne from "../Utility/Footer/FooterOne.vue";
import ImpInformation from "../Data/ImpInformation.json";
import Navbar from "@/components/Utility/Header/index";
/*import Banner from "../Utility/Header/Banner";*/
import Drawer from "../Mobile/Drawer.vue";
// import Header from "../Utility/Header/index";
// import AboutAuthor from "./AboutAuthor.vue";
export default {
  components: {
    FooterOne,
    Drawer,
    /*    Banner,*/
    Navbar
  },
  data: () => ({
    sidebar: false,
    impInformation: ImpInformation.data,
  }),
  created() {
    document.addEventListener("scroll", this.topToBottom);
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
    topToBottom() {
      const result = document.querySelector(".go-top");
      if (
          document.body.scrollTop > window.innerHeight ||
          document.documentElement.scrollTop > window.innerHeight
      ) {
        result.classList.add("active");
      } else {
        result.classList.remove("active");
      }
    },
  },
};
</script>

<style scoped>
.container {
  margin-top: 10vh;
}

@media (max-width: 992px) {
  .container {
    margin-top: 10vh;
  }
}

.card-header {
  border-left: 5px solid gray;
}

.card-header:hover {
  border-left: 5px solid #c51e2f;
}
</style>
