import Vue from 'vue'
import Vuex from 'vuex'
import {announcementModule} from './announcement.module';
import {generalModule} from './general.module';
import {academicModule} from './academic.module';
import {searchModule} from './search.module';
import {advertModule} from './advert.module';

Vue.use(Vuex);
export const store = new Vuex.Store({
    state: {
        videoPopup: false
    },
    mutations: {
        SET_VALUE_VIDEO: (state, payload) => {
            console.log(payload)
            state.videoPopup = payload
        }
    },
    actions: {
        toggleVideo({state, commit}) {
            commit('SET_VALUE_VIDEO', !state.videoPopup)
        }
    },
    modules: {
        announcementModule,
        generalModule,
        academicModule,
        searchModule,
        advertModule
    }

})