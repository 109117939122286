import GeneralService from "@/services/general.service";

const initialState = {
    socialEventsPaginated: {dtPagination: {}, item: []},
    showOverlay: false,

    selectedSocialEvent: {},
}

export const generalModule = {
    namespaced: true,
    state: initialState,
    actions: {

        getSocialEventsPaginated({commit}, payload) {
            return GeneralService.getSocialEventsPaginated(payload).then(res => {
                if (res.data) commit('setSocialEvents', res.data);
                return Promise.resolve(res.data);
            });
        }, getSocialEventById({commit}, payload) {
            return GeneralService.getSocialEventById(payload).then(res => {
                if (res.data) commit('setSelectedSocialEvent', res.data);
                return Promise.resolve(res.data);
            });
        },
    },
    getters: {},
    mutations: {
        setSocialEvents(state, payload) {
            state.socialEventsPaginated.item = Object.assign([], payload.endpointResult.data);
            state.socialEventsPaginated.dtPagination = Object.assign({}, payload.pagination);
            state.Overlay = false
        }, setSelectedSocialEvent(state, payload) {
            state.selectedSocialEvent = Object.assign({}, payload.data);
        },
    },
}