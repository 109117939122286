<template>
  <div>
    <div class="sticky-top">
      <Navbar class="navbar_container"/>
    </div>
    <!--    <Banner/>-->
    <drawer @toggleSidebar="toggleSidebar" :sidebar="sidebar"/>

    <!-- <about-author /> -->
    <div class="container" style="height: 100%">
      <h1 style="text-align: center">Eczane Rehber</h1>
      <div>
        <table style="width: 100%;font-size: 130%" >
          <thead>
          <tr>

            <th>İsim</th>
            <th>Belge</th>
            
          </tr>
          </thead>
          <tbody>
          <tr v-for="user in users" :key="user.id">

            <td>{{ user.name }}</td>
            <td @click="clickHrefMethod(user.email)">
              <div style="cursor: pointer;"> Görmek için tıklayınız -></div>
            </td>
          </tr>
          </tbody>
        </table>

      </div>



    </div>

    <footer-one />
    <div class="go-top-area" style="display: none">
      <div class="go-top-wrap">
        <div class="go-top-btn-wrap">
          <a href="#" class="go-top go-top-btn d-block">
            <i class="fa fa-angle-double-up"></i>
            <i class="fa fa-angle-double-up"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Drawer from "../Mobile/Drawer.vue";
import FooterOne from "../Utility/Footer/FooterOne.vue";
// import Banner from "../Utility/Header/Banner";
// import Header from "../Utility/Header/index";
import AboutChaPharma from "../Data/AboutChaPharma.json"
// import AboutAuthor from "./AboutAuthor.vue";
import Navbar from "@/components/Utility/Header/index";
export default {
  components: {
    FooterOne,
    Drawer,
    /*Banner,*/
    Navbar
  },
  data: () => ({
    sidebar : false,
    aboutChaPharma : AboutChaPharma.data,
    users:[
      { name: 'Üyelik İçin Gerekli Evraklar', email: 'https://mvc.aeo.org.tr/UserFiles/files/2024-uyelik-Icın-Istenen-Belgeler-ve-Formlar.pdf' },
      { name: 'Eczane Açılış Evrakları', email: 'https://mvc.aeo.org.tr/UserFiles/files/2024-Eczane-Acilis-Dilekcesi-ve-Gerekli-Belgeler.pdf' },
      { name: 'Eczane Devir Evrakları', email: 'https://mvc.aeo.org.tr/UserFiles/files/2024-Eczane-Devir-Dilekcesi-ve-Gerekli-Belgeler.pdf' },


      { name: 'Eczane Nakil Evrakları ', email: 'https://mvc.aeo.org.tr/UserFiles/files/2024-Eczane-Nakil-Dilekcesi-ve-Gerekli-Belgeler.pdf' },

      { name: 'Meslekten Men Olmadığına Dair Dilekçe', email: 'https://mvc.aeo.org.tr/UserFiles/files/2024-Men-Cezasi-Sorgu-Dilekcesi.pdf' },
      { name: 'Mesul Müdür Eczacı Evrakları', email: 'https://mvc.aeo.org.tr/UserFiles/files/2024-Mesul-Mudurluk-Icin-Istenen-Belgeler.pdf' },
      { name: 'Oda Kayıt Belgesi Talep Dilekçesi', email: 'https://mvc.aeo.org.tr/UserFiles/files/2024-Oda-Kayit-Belgesi-Talebi.pdf' },


      { name: 'Yardımcı ve II. Eczacı Belgeleri', email: 'https://mvc.aeo.org.tr/UserFiles/files/2024-Yardimci-ve-II-Eczacilik-Icın-Istenen-Belgeler.pdf' },
      { name: 'SGK İle İlk Kez Sözleşme Yapacak Eczacılarımız İçin İstenilen Belgeler', email: 'https://mvc.aeo.org.tr/UserFiles/files/2024-sozlesme-talep-dilekcesi.pdf' },
    ]
  }),
  created() {
    document.addEventListener("scroll", this.topToBottom);
  },
  mounted () {
    window.scrollTo(0, 0)
  },
  methods: {
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
    clickHrefMethod(href) {
      window.open(href, "_blank");
    },
    topToBottom() {
      const result = document.querySelector(".go-top");
      if (
          document.body.scrollTop > window.innerHeight ||
          document.documentElement.scrollTop > window.innerHeight
      ) {
        result.classList.add("active");
      } else {
        result.classList.remove("active");
      }
    },
  },
};
</script>

<style scoped>

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f2f2f2;
}

tr:hover {
  background-color: #f5f5f5;
}

td div {
  cursor: pointer;
  text-decoration: underline;
  color: #0072a2;
}

.container {
  margin-top: 10vh;
}

@media (max-width: 992px) {
  .container {
    margin-top: 10vh;
  }
}
</style>
