<template>
  <div id="app">
    <CookieConsent />
    <router-view/>
  </div>
</template>

<script>
import CookieConsent from './components/CookieConsent.vue';

export default {
  components: {
    CookieConsent
  }
}
</script>
