<template>
  <div v-if="datas">
    <a :href="'/duyurular/'+datas.subjectTypeIds[0]+'/'+datas.publishPlaceIds[0]+'/'+datas.id+'/'+datas.url" >
      <div>
        <div>
          <img class="imgslider" :src="datas.pageIconPath" alt=""/>
        </div>
      </div>
    </a>
  </div>

</template>

<script>
export default {
  props: ["datas"],
};
</script>

<style scoped>
.imgslider{
 height: 450px;width: 810px
}
@media (max-width: 1191px) {
  .imgslider{
    height: 250px;width: 810px;

  }

}

</style>

