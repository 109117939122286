<template>
  <div>
    <div class="sticky-top">
      <Navbar class="navbar_container"/>
    </div>
    <!--    <Banner/>-->
    <drawer @toggleSidebar="toggleSidebar" :sidebar="sidebar"/>
    <!-- <div class="sticky-top">
      <Header @toggleSidebar="toggleSidebar" />
    </div> -->

    <!-- <about-author /> -->
    <div class="container">
      <h4 class="title">Ankara Eczacı Odası Yayınlarımız</h4>
      <div class="row ">
        <div class="col-md-4 mb-4" v-for="(data,index) in yayinlar" :key="index">
          <div class="card">
            <a :href="data.src">
              <img :src="require(`@/assets/images/${data.image}`)"/>
            </a>
          </div>
        </div>
      </div>
    </div>

    <footer-one/>
    <div class="go-top-area" style="display: none">
      <div class="go-top-wrap">
        <div class="go-top-btn-wrap">
          <a href="#" class="go-top go-top-btn d-block">
            <i class="fa fa-angle-double-up"></i>
            <i class="fa fa-angle-double-up"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Drawer from "../Mobile/Drawer.vue";
import FooterOne from "../Utility/Footer/FooterOne.vue";
/*import Banner from "../Utility/Header/Banner";*/
// import Header from "../Utility/Header/index";
import Yayinlar from "../Data/Yayinlar.json";
import Navbar from "@/components/Utility/Header/index";
// import AboutAuthor from "./AboutAuthor.vue";
export default {
  components: {

    FooterOne,
    Drawer,
    /*Banner,*/
    Navbar
  },
  data: () => ({
    sidebar: false,
    yayinlar: Yayinlar.data,
  }),
  created() {
    document.addEventListener("scroll", this.topToBottom);
  },
  mounted() {
    window.scrollTo(0, 0)
  },
  methods: {
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
    topToBottom() {
      const result = document.querySelector(".go-top");
      if (
          document.body.scrollTop > window.innerHeight ||
          document.documentElement.scrollTop > window.innerHeight
      ) {
        result.classList.add("active");
      } else {
        result.classList.remove("active");
      }
    },
  },
};
</script>

<style scoped>
.container {
  margin-top: 10vh;
}

@media (max-width: 992px) {
  .container {
    margin-top: 10vh;
  }
}

.card {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

</style>
