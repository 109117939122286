 <template>
  <div>
    <div>
      <Banner/>
      <drawer @toggleSidebar="toggleSidebar" :sidebar="sidebar" /> 
    </div>
    <!-- <div class="sticky-top">
      <Header @toggleSidebar="toggleSidebar" />
    </div> -->
    <section
      class="post-layout-1-area post-layout-3-area pb-80"
      
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-8">
            <div class="post-layout-top-content post-layout-top-content-3">
              <div
                class="post-categories d-flex justify-content-between align-content-center"
              >
                {{ academicEducation.date }} | {{ academicEducation._id }}
              </div>
              <div class="post-content">
                <h3 class="title">
                  {{ academicEducation.title }}
                </h3>
                <div class="thumb">
                  <img :src="require(`@/assets/images/${academicEducation.image}`)" alt="" />
                </div>
              </div>
              <div class="post-text mt-30">
                <div v-for="art in academicEducation.article" :key="art">
                  <p>{{ art }}</p>
                  <br />
                </div>
              </div>
            </div>
            <div
              class="post-reader-text post-reader-text-2 post-reader-text-3 pt-50"
            ></div>
          </div>
          <div class="col-lg-4">
            <!-- dates -->
            <div class="card mb-4">
              <div class="card-body">
                <div >
                  <h6 class="d-inline">Tarih | </h6>
                  <span>{{academicEducation.eventDate}}</span>
                </div>
                <hr>
                <div >
                  <h6 class="d-inline">Son Başvuru | </h6>
                  <span>{{academicEducation.lastAppDate}}</span>
                </div>
              </div>
            </div>

            <!-- information card -->
            <div class="card mb-4">
              <div class="card-header">
                <h5 class="">Detaylı bilgi için</h5>
              </div>
              <div class="card-body">
                <div v-for="inf in academicEducation.information.phones" :key="inf">
                  <h6 class="d-inline">Telefon |</h6>
                  <a :href="inf.src">{{ inf.text }}</a>
                </div>
                <hr />
                <div v-for="inf in academicEducation.information.links" :key="inf">
                  <h6 class="d-inline">Mail |</h6>
                  <a :href="inf.src">{{ inf.text }}</a>
                </div>
                <hr />
                <p>
                  {{ academicEducation.information.article }}
                </p>
              </div>
            </div>

            <!-- reserve card -->
            <div class="card mb-4">
              <div class="card-header">
                <h5 class="">Başvuru için</h5>
              </div>
              <div class="card-body">
                <div v-for="res in academicEducation.reserve.phones" :key="res">
                  <h6 class="d-inline">Telefon | </h6>
                  <a :href="res.src">{{ res.text }}</a>
                </div>
                <hr />
                <div v-for="res in academicEducation.reserve.links" :key="res">
                  <h6 class="d-inline"></h6>
                  <a :href="res.src">{{ res.text }}</a>
                </div>
                <hr />
                <p>
                  {{ academicEducation.reserve.article }}
                </p>
              </div>
            </div>
            <div class="card mb-4">
              <div class="card-header"><h5 class="">Not</h5></div>
              <div class="card-body">
                <ul class="list-group" v-for="note in academicEducation.notes" :key="note">
                  <li class="list-group-item">
                    <a href="">{{ note.text }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--====== POST LAYOUT 1 PART ENDS ======-->

    <!--====== LATEST NEWS PART START ======-->
    <our-latest-news :Posts="posts" />

    <!--====== POST COMMENTS PART ENDS ======-->

    <!--====== ADD PART ENDS ======-->

    <!-- <div class="add-area text-center">
      <a href="#">
        <img src="@/assets/images/ads/one_ad.png" alt="" />
      </a>
    </div> -->

    <!--====== ADD PART ENDS ======-->
    <Footer />
    <!--====== GO TO TOP PART START ======-->
    <div class="go-top-area">
      <div class="go-top-wrap">
        <div class="go-top-btn-wrap">
          <a href="#" class="go-top go-top-btn d-block">
            <i class="fa fa-angle-double-up"></i>
            <i class="fa fa-angle-double-up"></i>
          </a>
        </div>
      </div>
    </div>
    <!--====== GO TO TOP PART ENDS ======-->
  </div>
</template>

<script>
import Banner from "../../Utility/Header/Banner";  
// import Header from "../../Utility/Header/index";
// import StyleOne from "../../Utility/Sidebar/StyleOne.vue";


// import OurLatestNews from "../../Utility/Common/OurLatestNews.vue";
import Footer from "../../Utility/Footer/FooterOne";
import Drawer from "../../Mobile/Drawer.vue";
export default {
  components: {  Footer, Drawer, Banner },
  data: () => ({
    academicEducation: null,
    sidebar: false,
    image: "",
    displayModal: false,
  }),
  created() {
    document.addEventListener("scroll", this.topToBottom);
    this.academicEducation = this.$store.state.academicModule.selectedAcademicEvents.find(x=>x._id === this.$route.params.id);
    console.log(this.academicEducation)
    console.log("here")
  },
  methods: {
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
    topToBottom() {
      const result = document.querySelector(".go-top");
      if (
        document.body.scrollTop > window.innerHeight ||
        document.documentElement.scrollTop > window.innerHeight
      ) {
        result.classList.add("active");
      } else {
        result.classList.remove("active");
      }
    },
    toggleModal(e) {
      console.log(e);
      this.image = e.target;
      console.log(this.image);
      this.displayModal = !this.displayModal;
    },
  },
};
</script>

<style>
.image_modal {
  position: absolute;
  top: 50%;
  left: 50%;
}
</style>
