<template>
  <div>
    <div class="sticky-top">
      <Navbar class="navbar_container"/>
    </div>
    <!--    <Banner/>-->
    <drawer :sidebar="sidebar" @toggleSidebar="toggleSidebar"/>

    <!-- <about-author /> -->
    <div class="container" style="height: 100%">
      <h1 style="text-align: center">Kılavuzlar</h1>
      <div>
        <table style="width: 100%;font-size: 130%">
          <thead>
          <tr>

            <th>İsim</th>
            <th style="display: flex; justify-content: center">Belge</th>

          </tr>
          </thead>
          <tbody>
          <tr v-for="user in users" :key="user.id">

            <td > {{ user.name }}</td>
            <td style="display: flex; justify-content: end"  @click="clickHrefMethod(user.email)">
              <div style="cursor: pointer;"> Görmek için tıklayınız -></div>
            </td>
          </tr>
          </tbody>
        </table>
        <h1 style="text-align: center">İlaç İmha</h1>
        <div>
          <table style="width: 100%;font-size: 130%">
            <thead>
            <tr>

              <th>İsim</th>
              <th style="display: flex; justify-content: center">Belge</th>

            </tr>
            </thead>
            <tbody>
            <tr v-for="user in usersilac" :key="user.id">

              <td>{{ user.name }}</td>
              <td style="display: flex; justify-content: end" @click="clickHrefMethod(user.email)">
                <div style="cursor: pointer;"> Görmek için tıklayınız -></div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>


    </div>

    <footer-one/>
    <div class="go-top-area" style="display: none">
      <div class="go-top-wrap">
        <div class="go-top-btn-wrap">
          <a class="go-top go-top-btn d-block" href="#">
            <i class="fa fa-angle-double-up"></i>
            <i class="fa fa-angle-double-up"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Drawer from "../Mobile/Drawer.vue";
import FooterOne from "../Utility/Footer/FooterOne.vue";
// import Banner from "../Utility/Header/Banner";
// import Header from "../Utility/Header/index";
import AboutChaPharma from "../Data/AboutChaPharma.json"
// import AboutAuthor from "./AboutAuthor.vue";
import Navbar from "@/components/Utility/Header/index";

export default {
  components: {
    FooterOne,
    Drawer,
    /*Banner,*/
    Navbar
  },
  data: () => ({
    sidebar: false,
    aboutChaPharma: AboutChaPharma.data,
    users: [

      {
        name: 'İTS Deaktivasyon Talep Oluşturma Kılavuzu\n',
        email: 'https://mvc.aeo.org.tr//UserFiles/files/%C4%B0la%C3%A7%20Takip%20Sistemi%20Deaktivasyon%20Talep%20Olu%C5%9Fturma%20K%C4%B1lavuzu.pdf '
      },
      {
        name: 'SGK Tarafından Mevzuattan İade Edilen Reçeteler İçin İzlenecek Yol\n',
        email: 'https://mvc.aeo.org.tr//UserFiles/files/SGK%20TARAFINDAN%20MEVZUATTAN%20IADE%20EDILEN%20RECETELER%20ICIN%20IZLENECEK%20YOL.pdf '
      },
      {
        name: 'Yardımcı Eczacı Yönlendirme Kılavuzu',
        email: 'https://mvc.aeo.org.tr/UserFiles/files/AEO_YarEczYonKlv.pdf'
      },
      {
        name: 'VERBİS KVKK Sistemine Başvuru Kılavuzu',
        email: 'https://mvc.aeo.org.tr//UserFiles/files/kvkk-verbis-basvuru-kilavuzu.pdf'
      },
    ],
    usersilac: [
      {
        name: 'İlaç İmha Yönetim Sistemi Kılavuzu',
        email: 'https://mvc.aeo.org.tr//UserFiles/files/atikilackilavuzuYEN%C4%B0.pdf '
      },
      {
        name: 'Bölge Temsilcisi Talep Formu',
        email: 'https://docs.google.com/forms/d/e/1FAIpQLSdERAAgqXI8vb1WSeJHjoyOa-2ul0F0M0t9harzGBuOSUSgeg/viewform'
      },
      {
        name: ' Atık İlaçların Yönetimi   ',
        email: 'https://mvc.aeo.org.tr//UserFiles/files/atik-ilaclarin-yonetimi.pdf'
      },
    ]
  }),
  created() {
    document.addEventListener("scroll", this.topToBottom);
  },
  mounted() {
    window.scrollTo(0, 0)
  },
  methods: {
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
    clickHrefMethod(href) {
      window.open(href, "_blank");
    },
    topToBottom() {
      const result = document.querySelector(".go-top");
      if (
          document.body.scrollTop > window.innerHeight ||
          document.documentElement.scrollTop > window.innerHeight
      ) {
        result.classList.add("active");
      } else {
        result.classList.remove("active");
      }
    },
  },
};
</script>

<style scoped>

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f2f2f2;
}

tr:hover {
  background-color: #f5f5f5;
}

td div {
  cursor: pointer;
  text-decoration: underline;
  color: #0072a2;
}

.container {
  margin-top: 10vh;
}

@media (max-width: 992px) {
  .container {
    margin-top: 10vh;
  }
}
</style>
