<template>
  <div>
    <div>
      <div class="sticky-top">
        <Navbar class="navbar_container"/>
      </div>
      <!--    <Banner/>-->
      <drawer :sidebar="sidebar" @toggleSidebar="toggleSidebar"/>
      <!-- <about-author /> -->
      <div>


<!--        <div class="row" style="margin-top: 5vh; display: flex; justify-content: center" >-->
<!--          <div class=" buton1" @click="clickRouteMethod('/allposts/Satıyorum/44')"> Eleman Arıyorum-->
<!--          </div>-->
<!--          <div class=" buton" @click="clickRouteMethod('/allposts/Devir%20Eczane/45')">  Eşya Satıyorum-->
<!--          </div>-->
<!--          <div class=" buton "  @click="clickRouteMethod('/allposts/Personel/46')"> Devren Eczane-->
<!--          </div>-->
<!--          <div class=" buton" @click="clickRouteMethod('/allposts/Personel/44')"> Yardımcı Eczacı-->
<!--          </div>-->
<!--          <div class=" buton" @click="clickRouteMethod('/allposts/Personel/44')"> İkinci Eczacı-->
<!--          </div>-->
<!--          <div class=" buton" @click="clickRouteMethod('/allposts/Personel/44')"> Mesul Müdür-->
<!--          </div>-->
<!--          <div class=" buton2" @click="clickRouteMethod('/allposts/Personel/44')"> Stajyer Eczacı-->
<!--          </div>-->


<!--        </div>-->

        <div class="container " style="margin-top: 13vh">

          <div
              v-if="
          postsAccorCategory != null &&
          this.$store.state.announcementModule.subjectTypes != null
        "
              class="row container mx-auto mt-5 mb-5"
          >
            <!--        <h2 class="col-lg-12 ann-title border-bottom">- {{ this.$route.params.name }} -</h2>-->
            <h4 class="title">{{ this.$route.params.name }}</h4>

            <div v-if="postsAccorCategory !== null" class="">
              <div class="row mx-auto ">
                <div class="col-lg-3">
                  <div class="">
                    <home-one
                        :ad="false"
                        :darkClass="darkClass"
                        :datas="postsAccorCategory.slice(0,1 )"
                        :sharePost="false"
                        :signup="false"
                        :trendingBigPost="false"
                        :trendingShortPost="false"
                        role="sidebar"

                    />
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="">
                    <home-one
                        :ad="false"
                        :darkClass="darkClass"
                        :datas="postsAccorCategory.slice(1, 2)"
                        :sharePost="false"
                        :signup="false"
                        :trendingBigPost="false"
                        :trendingShortPost="false"
                        role="sidebar"

                    />
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="">
                    <home-one
                        :ad="false"
                        :darkClass="darkClass"
                        :datas="postsAccorCategory.slice(2, 3)"
                        :sharePost="false"
                        :signup="false"
                        :trendingBigPost="false"
                        :trendingShortPost="false"
                        role="sidebar"

                    />
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="">
                    <home-one
                        :ad="false"
                        :darkClass="darkClass"
                        :datas="postsAccorCategory.slice(3, 4)"
                        :sharePost="false"
                        :signup="false"
                        :trendingBigPost="false"
                        :trendingShortPost="false"
                        role="sidebar"

                    />
                  </div>
                </div>


              </div>
              <div class="row mx-auto ">
                <div class="col-lg-3">
                  <div class="">
                    <home-one
                        :ad="false"
                        :darkClass="darkClass"
                        :datas="postsAccorCategory.slice(4,5 )"
                        :sharePost="false"
                        :signup="false"
                        :trendingBigPost="false"
                        :trendingShortPost="false"
                        role="sidebar"

                    />
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="">
                    <home-one
                        :ad="false"
                        :darkClass="darkClass"
                        :datas="postsAccorCategory.slice(5, 6)"
                        :sharePost="false"
                        :signup="false"
                        :trendingBigPost="false"
                        :trendingShortPost="false"
                        role="sidebar"

                    />
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="">
                    <home-one
                        :ad="false"
                        :darkClass="darkClass"
                        :datas="postsAccorCategory.slice(6, 7)"
                        :sharePost="false"
                        :signup="false"
                        :trendingBigPost="false"
                        :trendingShortPost="false"
                        role="sidebar"

                    />
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="">
                    <home-one
                        :ad="false"
                        :darkClass="darkClass"
                        :datas="postsAccorCategory.slice(7, 8)"
                        :sharePost="false"
                        :signup="false"
                        :trendingBigPost="false"
                        :trendingShortPost="false"
                        role="sidebar"

                    />
                  </div>
                </div>

              </div>
              <div class="row mx-auto ">
                <div class="col-lg-3">
                  <div class="">
                    <home-one
                        :ad="false"
                        :darkClass="darkClass"
                        :datas="postsAccorCategory.slice(8,9 )"
                        :sharePost="false"
                        :signup="false"
                        :trendingBigPost="false"
                        :trendingShortPost="false"
                        role="sidebar"

                    />
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="">
                    <home-one
                        :ad="false"
                        :darkClass="darkClass"
                        :datas="postsAccorCategory.slice(9,10)"
                        :sharePost="false"
                        :signup="false"
                        :trendingBigPost="false"
                        :trendingShortPost="false"
                        role="sidebar"

                    />
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="">
                    <home-one
                        :ad="false"
                        :darkClass="darkClass"
                        :datas="postsAccorCategory.slice(10,11)"
                        :sharePost="false"
                        :signup="false"
                        :trendingBigPost="false"
                        :trendingShortPost="false"
                        role="sidebar"

                    />
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="">
                    <home-one
                        :ad="false"
                        :darkClass="darkClass"
                        :datas="postsAccorCategory.slice(11, 12)"
                        :sharePost="false"
                        :signup="false"
                        :trendingBigPost="false"
                        :trendingShortPost="false"
                        role="sidebar"

                    />
                  </div>
                </div>

              </div>
              <div class="row mx-auto ">
                <div class="col-lg-3">
                  <div class="">
                    <home-one
                        :ad="false"
                        :darkClass="darkClass"
                        :datas="postsAccorCategory.slice(12,13 )"
                        :sharePost="false"
                        :signup="false"
                        :trendingBigPost="false"
                        :trendingShortPost="false"
                        role="sidebar"

                    />
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="">
                    <home-one
                        :ad="false"
                        :darkClass="darkClass"
                        :datas="postsAccorCategory.slice(13, 14)"
                        :sharePost="false"
                        :signup="false"
                        :trendingBigPost="false"
                        :trendingShortPost="false"
                        role="sidebar"

                    />
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="">
                    <home-one
                        :ad="false"
                        :darkClass="darkClass"
                        :datas="postsAccorCategory.slice(14, 15)"
                        :sharePost="false"
                        :signup="false"
                        :trendingBigPost="false"
                        :trendingShortPost="false"
                        role="sidebar"

                    />
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="">
                    <home-one
                        :ad="false"
                        :darkClass="darkClass"
                        :datas="postsAccorCategory.slice(15, 16)"
                        :sharePost="false"
                        :signup="false"
                        :trendingBigPost="false"
                        :trendingShortPost="false"
                        role="sidebar"

                    />
                  </div>
                </div>

              </div>
              <div class="row mx-auto ">
                <div class="col-lg-3">
                  <div class="">
                    <home-one
                        :ad="false"
                        :darkClass="darkClass"
                        :datas="postsAccorCategory.slice(16,17 )"
                        :sharePost="false"
                        :signup="false"
                        :trendingBigPost="false"
                        :trendingShortPost="false"
                        role="sidebar"

                    />
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="">
                    <home-one
                        :ad="false"
                        :darkClass="darkClass"
                        :datas="postsAccorCategory.slice(17, 18)"
                        :sharePost="false"
                        :signup="false"
                        :trendingBigPost="false"
                        :trendingShortPost="false"
                        role="sidebar"

                    />
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="">
                    <home-one
                        :ad="false"
                        :darkClass="darkClass"
                        :datas="postsAccorCategory.slice(18, 19)"
                        :sharePost="false"
                        :signup="false"
                        :trendingBigPost="false"
                        :trendingShortPost="false"
                        role="sidebar"

                    />
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="">
                    <home-one
                        :ad="false"
                        :darkClass="darkClass"
                        :datas="postsAccorCategory.slice(19, 20)"
                        :sharePost="false"
                        :signup="false"
                        :trendingBigPost="false"
                        :trendingShortPost="false"
                        role="sidebar"

                    />
                  </div>
                </div>

              </div>
            </div>









            <!--
                    <div class="row mt-3 mx-auto">
                      <img
                          class="mb-3 col-lg-4"
                          src="@/assets/images/trending-thumb.jpg"
                          alt=""
                      />
                      <img
                          class="mb-3 col-lg-4"
                          src="@/assets/images/trending-thumb.jpg"
                          alt=""
                      />
                      <img
                          class="mb-3 col-lg-4"
                          src="@/assets/images/trending-thumb.jpg"
                          alt=""
                      />
                    </div>-->
            <nav aria-label="..." class="mt-5">
              <ul class="pagination">
                <li class="page-item ">
                  <a class="page-link" tabindex="-1" @click="pageNumberDec()">Previous</a>
                </li>

                <li class="page-item">
                  <a class="page-link" @click="pageNumberInc()">Next</a>
                </li>
              </ul>
            </nav>

          </div>
          <div v-else class="card-body">
            <Spinner></Spinner>
          </div>
        </div>
      </div>
    </div>
    <footer-one/>
    <div class="go-top-area" style="display: none">
      <div class="go-top-wrap">
        <div class="go-top-btn-wrap">
          <a class="go-top go-top-btn d-block" href="#">
            <i class="fa fa-angle-double-up"></i>
            <i class="fa fa-angle-double-up"></i>
          </a>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Drawer from "../Mobile/Drawer.vue";
import AnnouncementCategory from "../Data/AnnouncementCategory";
import FooterOne from "../Utility/Footer/FooterOne.vue";
import {mapActions, mapGetters} from "vuex";
import Spinner from "../Spinner/HollowDotsSpinner/Spinner.vue";
import moment from "moment";
import HomeOne from "../Utility/Sidebar/StyleOne.vue";
// import Header from "../Utility/Header/index";
/*import Banner from "../Utility/Header/Banner";*/
import Navbar from "@/components/Utility/Header/index";
// import AboutAuthor from "./AboutAuthor.vue";
export default {
  components: {
    HomeOne,
    FooterOne,
    Drawer,
    Spinner,
    /*Banner,*/
    Navbar,
  },
  props: {
    darkClass: {
      type: Object,
      default: () => {
      },
    },

  },
  data: () => ({
    form: {
      pageSize: 20,
      currentPage: 1,
      checkedSubjectTypes: [],
    },
    postsAccorCategory: null,
    postCategoryId: null,
    sidebar: false,
    AnnouncementCategory,
    sliderGallery: [],
    selectedGallery: "trendy",
    miniCardsSettings: {
      slidesToShow: 8,
      slideToScroll: 1,
      autoplay: true,
      arrows: false,
      responsive: [
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 3,
            slideToScroll: 1,
            autoplay: true,
          },
        },
      ],
    },
    BigCardsSettings: {
      slidesToShow: 1,
      slideToScroll: 1,
      autoplay: true,
      arrows: false,
    },
  }),
  async created() {

    this.form.checkedSubjectTypes.push(this.$route.params.id);
    await this.getPostsAccorCategory(this.form);
    this.postsAccorCategory = this.getterPostsAccorCategory();




    if (this.$store.state.announcementModule.publishPlaces.length === 0) {
      this.getPublishPlaces();
    }
    if (this.$store.state.announcementModule.subjectTypes.length === 0) {
      this.getSubjectTypes();
    }
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    ...mapActions("announcementModule", [
      "getTodaysNews",
      "getSubjectTypes",
      "getPublishPlaces",
      "getPostsAccorCategory",
    ]),
    ...mapGetters("announcementModule", [
      "getBaskandan",
      "getterPublishPlaces",
      "getSliderGallery",
      "getDuyurular",
      "getterPostsAccorCategory",
    ]),
    clickRouteMethod(route) {
      this.$router.push(route);
    },

    format_date(value) {
      return moment(value).format("DD.MM.YYYY");
    },
    //post gallery
    postGalleryPrev() {
      this.$refs.BigCards.prev();
    },
    postGalleryNext() {
      this.$refs.BigCards.next();
    },
    selectGalleryTab(value) {
      this.selectedGallery = value;
    },
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
    topToBottom() {
      const result = document.querySelector(".go-top");
      if (
          document.body.scrollTop > window.innerHeight ||
          document.documentElement.scrollTop > window.innerHeight
      ) {
        result.classList.add("active");
      } else {
        result.classList.remove("active");
      }

    },
    async pageNumberDec() {
      this.form.currentPage--;
      await this.getPostsAccorCategory(this.form);
      this.postsAccorCategory = this.getterPostsAccorCategory();
      console.log(this.form.currentPage);
    },
    async pageNumberInc() {
      this.form.currentPage++;
      await this.getPostsAccorCategory(this.form);
      this.postsAccorCategory = this.getterPostsAccorCategory();
      console.log(this.form.currentPage);
    },

  },
  watch: {
    $route() {
      window.location.reload();
    },
  }
};
</script>

<style scoped>
.buton {
  display: flex;
  justify-content: center;

  margin-right: 1px;
  padding-top: 3px;
  height: 5vh;
  background-color: #fc1717;
  color: #ffffff;
  margin-top: 3vh;

  font-size: large;
  white-space: nowrap;
  width: 10vw;

}
.buton1 {
  display: flex;
  justify-content: center;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  margin-right: 1px;
  padding-top: 3px;
  height: 5vh;
  background-color: #fc1717;
  color: #ffffff;
  margin-top: 3vh;

  font-size: large;
  white-space: nowrap;
  width: 10vw;

}
.buton2 {
  display: flex;
  justify-content: center;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  margin-right: 1px;
  padding-top: 3px;
  height: 5vh;
  background-color: #fc1717;
  color: #ffffff;
  margin-top: 3vh;

  font-size: large;
  white-space: nowrap;
  width: 10vw;

}

.kutu {
  position: relative;
}

.container {
  margin-top: 10vh;
}

@media (max-width: 992px) {
  .container {
    margin-top: 10vh;
  }
}

.card:hover {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.ann-title {
  color: #c51e2f;
}

.card-footer a {
  color: #c51e2f;
  font-weight: bold;
}

.card-img img {
  width: 100%;
}

.post__gallery__area {
  margin-top: 300px;
  margin-bottom: 100px;
}
</style>
