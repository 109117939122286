<template>
  <div>
    <div class="cami" style="">


    </div>

    <footer class="footer-area-aeo mb-3">

      <div class="container" style="color: white; text-align: right;font-weight: bold;font-family: Arial ">
        <div class=" " style="">
          <div class="row align-items-center">
            <div class="row mx-auto footer-logo" style="text-align: center">
              <div class="col-lg-3">
                <ul>
                  <img alt="Ankara Eczacı Odası Logo" src="@/assets/images/aeologofooter.png" style="height: 180px"/>

                </ul>
              </div>
              <div class="col-lg-6">
                <div class="row">
                  <div class="col-lg-4" style="text-align: left">
                    <ul>
                      <li style="margin-top: 10px " type="button" @click="clickRouteMethod('/about-us')">Odamız
                      </li>
                      <br>
                      <li style="margin-top: 19px" type="button" @click="clickRouteMethod('/about-history')">
                        Hakkımızda
                      </li>
                      <br>
                      <li style="margin-top: 19px" type="button" @click="clickRouteMethod('/contact')">İletişim</li>
                      <br>
                      <li style="margin-top: 19px" type="button" @click="clickRouteMethod('/crisis-pha')">Nöbetçi Eczaneler</li>
                      <br>
                    </ul>
                  </div>
                  <div class="col-lg-4" style="text-align: left">
                    <ul>


                      <li style="margin-top: 19px" type="button" @click="clickRouteMethod('/allposts/:name/:id')">
                        Duyurular
                      </li>
                      <br>
                      <li style="margin-top: 19px" type="button" @click="clickRouteMethod('/advert-list')">İlan Panosu</li>
                      <br>
                      <li style="margin-top: 19px" type="button" @click="clickRouteMethod('/doz')">Doz Dergisi</li>
                      <br>
                    </ul>
                  </div>
                  <div class="col-lg-4" style="text-align: left">
                    <ul>
                      <li style="margin-top: 10px " type="button" @click="clickRouteMethod('/cv-loading')">Cv Yükle
                      </li>
                      <br>
                      <li style="margin-top: 19px" type="button" @click="clickHrefMethod('https://ebys.aeo.org.tr/enVision/Validate_Doc.aspx?')">
                        Evrak Doğrulama
                      </li>
                      <br>
                      <li style="margin-top: 19px" type="button" @click="clickHrefMethod('http://ebys.aeo.org.tr/envision')">EBYS</li>
                      <br>
                      <li style="margin-top: 19px" type="button" @click="clickHrefMethod('https://mvc.aeo.org.tr//UserFiles/files/AnkaraeczaciodasiCMX.exe')">Alpemix</li>
                      <br>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-3" style="margin-top: 50px" >
                <ul>
                  <li>
                    <a href="https://twitter.com/i/flow/login?redirect_after_login=%2Fankaraeczaci" target="_blank"><i
                        class="fab fa-twitter"></i></a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/ankaraeczaci" target="_blank"><i
                        class="fab fa-facebook-f"></i></a>
                  </li>
                  <li>
                    <a href="https://www.youtube.com/@ankeczaci" target="_blank"><i class="fab fa-youtube"></i></a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/ankaraeczaci" ><i
                        class="fab fa-instagram"></i></a>
                  </li>

                </ul>
                <ul>
                  <li>
                    <i
                        class="col-lg-8 mt-1" style="color: white ; align-items: center">Version:1.6(mapped)</i>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row mx-auto" style="text-align: center; ">
          <div class="col-lg-12">
            Imontech© Copyright 2024, All Rights Reserved
          </div>
        </div>

      </div>
    </footer>
  </div>
</template>

<script>
import navItems from "../../Data/NavItems.json";

export default {
  data: () => ({
    nav_items: navItems.data,
  }),
  methods: {
    clickRouteMethod(route) {
      this.$router.push(route);
    },
    clickHrefMethod(href) {
      window.open(href, "_blank");
    },
  }
};

</script>

<style scoped>
.footer_sub {
  cursor: pointer;
}

.cami {
  background: url('@/assets/images/cami.png') center center no-repeat;
  background-size: cover;
  width: 100vw;
  height: 15rem;
}

.footer-area-aeo {
  background: #C11627;
  width: 100%;
  height: 24rem; /* Footer height */

}


</style>
