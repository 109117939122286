<template>
  <!--====== OFFCANVAS MENU PART START ======-->
  <div>
    <div
        class="off_canvars_overlay"
        :class="[sidebar ? 'active' : '']"
        @click.prevent="hideSidebar"
    ></div>
    <div class="offcanvas_menu">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div
                class="offcanvas_menu_wrapper"
                :class="[sidebar ? 'active' : '']"
                style="overflow-y: scroll; overflow-x: hidden"
            >
              <div class="canvas_close">
                <a href="javascript:void(0)" @click="hideSidebar"
                ><i class="fa fa-times"></i
                ></a>
              </div>
              <div class="offcanvas-brand text-center mb-40">
                <img src="@/assets/images/logo.jpg" alt="appie"/>
              </div>
              <div id="menu" :style="heightPro" class="text-left">
                <ul class="offcanvas_main_menu list-unstyled">
                  <li
                      v-for="(item, index) in menuItems.slice(0,1)"
                      :key="'q' + index"
                      class="menu-item-has-children active"
                      :class="[menuOpen === item.linkText ? 'menu-open' : '']"
                  >
                    <span
                        v-if="item.submenu && item.submenu.length > 0 && nasted"
                        class="menu-expand"
                    ><i class="fa fa-angle-down"></i
                    ></span>
                    <router-link
                        style="text-transform: capitalize"
                        v-if="item.link"
                        :to="item.link"
                    >
                      {{ item.linkText }}
                    </router-link
                    >
                    <a v-else href="#" @click.prevent="show(item.linkText)">
                      {{ item.linkText }}</a
                    >
                    <ul
                        v-if="item.submenu && nasted"
                        :id="item.linkText"
                        class="sidebar-sub-menu list-unstyled ml-3"
                        :class="[
                        menuOpen === item.linkText ? 'expend_menu_item' : '',
                      ]"
                    >
                      <li v-for="(subItem, i) in item.submenu" :key="'w' + i">
                        <router-link :to="subItem.link ? subItem.link : '#'">{{
                            subItem.linkText
                          }}
                        </router-link>
                      </li>
                    </ul>
                  </li>
                  <!-- duyurular -->

                  <li
                      v-for="(item, index) in menuItems.slice(2,3)"
                      :key="'x' + index"
                      class="menu-item-has-children active"
                      :class="[menuOpen === item.linkText ? 'menu-open' : '']"
                  >
                    <span
                        v-if="item.submenu && item.submenu.length > 0 && nasted"
                        class="menu-expand"
                    ><i class="fa fa-angle-down"></i
                    ></span>
                    <router-link
                        style="text-transform: capitalize"
                        v-if="item.link"
                        :to="item.link"
                    >
                      {{ item.linkText }}
                    </router-link
                    >
                    <a v-else href="#" @click.prevent="show(item.linkText)">
                      {{ item.linkText }}</a
                    >
                    <ul
                        v-if="item.submenu && nasted"
                        :id="item.linkText"
                        class="sidebar-sub-menu list-unstyled ml-3"
                        :class="[
                        menuOpen === item.linkText ? 'expend_menu_item' : '',
                      ]"
                    >
                      <li v-for="(data, index) in subjectTypesCount" :key="'z' + index">
                        <span
                            class="new_post_amount me-3  text-center">{{ data.countOfPublication }}</span>
                        <router-link :to="{ path: `/allposts/${data.text}/${data.id}`}">{{ data.text }}</router-link>
                      </li>
                    </ul>
                  </li>

                  <!-- geriye kalan menu linkleri -->
                  <li
                      v-for="(item, index) in menuItems.slice(3)"
                      :key="'y' + index"
                      class="menu-item-has-children active"
                      :class="[menuOpen === item.linkText ? 'menu-open' : '']"
                  >
                    <span
                        v-if="item.submenu && item.submenu.length > 0 && nasted"
                        class="menu-expand"
                    ><i class="fa fa-angle-down"></i
                    ></span>
                    <router-link
                        style="text-transform: capitalize"
                        v-if="item.link"
                        :to="item.link"
                    >
                      {{ item.linkText }}
                    </router-link
                    >
                    <a v-else href="#" @click.prevent="show(item.linkText)">
                      {{ item.linkText }}</a
                    >
                    <ul
                        v-if="item.submenu && nasted"
                        :id="item.linkText"
                        class="sidebar-sub-menu list-unstyled ml-3"
                        :class="[
                        menuOpen === item.linkText ? 'expend_menu_item' : '',
                      ]"
                    >
                      <li v-for="(subItem, i) in item.submenu" :key="'v' +i">
                        <router-link :to="subItem.link ? subItem.link : '#'">{{
                            subItem.linkText
                          }}
                        </router-link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div>
                <router-link tag="p" to="/search" class="_canvas fw-bold"><i class="fas fa-search fw-bold"></i>
                </router-link>
              </div>
              <div class="offcanvas-social">
                <ul class="text-center list-unstyled">
                  <li>
                    <a href="https://tr-tr.facebook.com/ankaraeczaci/"><i class="fab fa-facebook-f text-danger"></i></a>
                  </li>
                  <li>
                    <a href="https://twitter.com/ankaraeczaci"><i class="fab fa-twitter text-danger"></i></a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/ankaraeczaci/"><i class="fab fa-instagram text-danger"></i></a>
                  </li>
                  <li>
                    <a href="https://www.youtube.com/channel/UCc6oChnQtwkTlTQd-NT09OA"><i
                        class="fab fa-youtube text-danger"></i
                    ></a>
                  </li>
                </ul>
              </div>
              <div class="footer-widget-info">
                <ul>
                  <li>
                    <a href="#"
                    ><i class="fal fa-envelope"></i> aeo@aeo.org.tr</a
                    >
                  </li>
                  <li>
                    <a href="#"><i class="fal fa-phone"></i> (312) 425-0708</a>
                  </li>
                  <li>
                    <a href="#"
                    ><i class="fal fa-map-marker-alt"></i> Olgunlar Sokak
                      No:32 Kızılay /ANKARA</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--====== OFFCANVAS MENU PART ENDS ======-->
</template>

<script>
import Navs from "../Data/NavItems";
import {mapActions, mapGetters} from "vuex";

export default {
  props: {
    sidebar: {
      type: Boolean,
      required: true,
    },
    nasted: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      menuOpen: null,
      subMenuHeight: null,
      menuItems: Navs.data,
      subjectTypesCount: null,
    };
  },
  async created() {
    await this.getSubjectTypeCount();
    this.subjectTypesCount = this.getterSubjectTypesCount();
  },
  computed: {
    heightPro() {
      return {
        "--height": this.subMenuHeight,
      };
    },
  },
  methods: {

    ...mapActions("announcementModule", [
      "getSubjectTypeCount",
    ]),
    ...mapGetters("announcementModule", [
      "getterSubjectTypesCount",
    ]),
    hideSidebar(e) {
      this.$emit("toggleSidebar", e);
    },
    show(value) {
      if (value !== this.menuOpen) {
        this.menuOpen = value;
      } else {
        this.menuOpen = null;
      }
      const getListItem = document.querySelectorAll(`#${value} li`).length;
      this.subMenuHeight = 43 * getListItem + "px";
    },
  },
};
</script>

<style scoped>
.sidebar-sub-menu {
  padding-left: 20px;
  overflow: hidden;
  height: 0px;
}

.sidebar-sub-menu {
  transition: all linear 0.65s;
}

.sidebar-sub-menu.expend_menu_item {
  transition: all linear 0.65s;
}

.expend_menu_item.sidebar-sub-menu {
  height: var(--height);
}

._canvas {
  color: #c11627;
  cursor: pointer;
}

.new_post_amount {
  background: rgb(255, 107, 54);
  display: inline-block;
  padding: 1px;
  width: 25px;
  border-radius: 50%;
  color: #fff;
  position: absolute;
  top: -10px;
  right: -8px;
  font-size: 12px;
}
</style>
