<template>
  <div>
    <div class="sticky-top">
      <Navbar class="navbar_container"/>
    </div>
    <!--    <Banner/>-->
    <drawer @toggleSidebar="toggleSidebar" :sidebar="sidebar"/>

    <!-- <about-author /> -->
    <div class="container" style="height: 100%">
      <h1>Renkli Reçete İlaçları</h1>
      <div>
        <table style="width: 100%">
          <thead>
          <tr>

            <th>İsim</th>
            <th>Belge</th>

          </tr>
          </thead>
          <tbody>
          <tr v-for="user in users" :key="user.id">

            <td style="font-size: 135%">{{ user.name }}</td>
            <td style="font-size: 135%" @click="clickHrefMethod(user.email)">
              <div style="cursor: pointer;"> Görmek için tıklayınız -></div>
            </td>
          </tr>
          </tbody>
        </table>

      </div>



    </div>

    <footer-one />
    <div class="go-top-area" style="display: none">
      <div class="go-top-wrap">
        <div class="go-top-btn-wrap">
          <a href="#" class="go-top go-top-btn d-block">
            <i class="fa fa-angle-double-up"></i>
            <i class="fa fa-angle-double-up"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Drawer from "../Mobile/Drawer.vue";
import FooterOne from "../Utility/Footer/FooterOne.vue";
// import Banner from "../Utility/Header/Banner";
// import Header from "../Utility/Header/index";
import AboutChaPharma from "../Data/AboutChaPharma.json"
// import AboutAuthor from "./AboutAuthor.vue";
import Navbar from "@/components/Utility/Header/index";
export default {
  components: {
    FooterOne,
    Drawer,
    /*Banner,*/
    Navbar
  },
  data: () => ({
    sidebar : false,
    aboutChaPharma : AboutChaPharma.data,
    users:[
      {  name: ' TİTCK Ek Onayı Alınmadan Kullanılabilecek Endikasyon Dışı İlaç Listeleri\n', email: ' https://www.titck.gov.tr/dinamikmodul/45' },
      {  name: ' TİTCK Kontrole Tabi İlaçlar', email: 'https://titck.gov.tr/storage/Archive/2021/contentFile/Normal%20Reçete%20İle%20Verilmesi%20Gereken%20İzlemeye%20Tabi%20İlaçlar-15102021_40db70ca-49a1-4bbd-87c3-c415c6d9c31e.pdf' },
      { name: 'TİTCK Kırmızı Reçete İle Verilen İlaçlar ve Maksimum Dozları', email: 'https://titck.gov.tr/storage/Archive/2024/contentFile/K%C4%B1rm%C4%B1z%C4%B1%20Re%C3%A7eteye%20Tabi%20%C4%B0la%C3%A7lar%20Listesi_b0fa74a7-68b9-4edc-a853-6123c37292a4.pdf' },
      { name: ' TİTCK Yeşil Reçeteye Tabi İlaçlar', email: 'https://titck.gov.tr/storage/Archive/2019/contentFile/29112019%20SKRS%20Yes%CC%A7il%20Rec%CC%A7eteli%20I%CC%87lac%CC%A7lar%20Aktif%20SON%20-%20GU%CC%88NCEL_1e7e6495-f097-4b9e-9153-36b313277a49.pdf' },]

  }),
  created() {
    document.addEventListener("scroll", this.topToBottom);
  },
  mounted () {
    window.scrollTo(0, 0)
  },
  methods: {
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
    clickHrefMethod(href) {
      window.open(href, "_blank");
    },
    topToBottom() {
      const result = document.querySelector(".go-top");
      if (
          document.body.scrollTop > window.innerHeight ||
          document.documentElement.scrollTop > window.innerHeight
      ) {
        result.classList.add("active");
      } else {
        result.classList.remove("active");
      }
    },
  },
};
</script>

<style scoped>

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f2f2f2;
}

tr:hover {
  background-color: #f5f5f5;
}

td div {
  cursor: pointer;
  text-decoration: underline;
  color: #0072a2;
}

.container {
  margin-top: 10vh;
}

@media (max-width: 992px) {
  .container {
    margin-top: 10vh;
  }
}
</style>
