<template>
  <div>

    <div class="pc">
      <header class="header-area-sticky">

        <div class="header-menubar">

          <div class="row   ">
            <div class="col-lg-2  openClosed">
              <router-link class="ribbon-logo-aeo_container " tag="span" to="/">
                <img alt="Ankara Eczacı Odası Logo" class="" src="../../../assets/images/slider/aeologobeyazlı.png"
                     style="margin-left: 120px;margin-top: 30px;width: 150px"/>
              </router-link>
            </div>
            <div class="col-lg-7 bosluk ">
              <nav-items/>
            </div>
            <div class="col-lg-2 " style="margin-top: 20px">


              <img :src="require(`@/assets/images/navbarphoto_.png`)"
                   alt="trending"
                   width="200px"


              />


            </div>
          </div>


        </div>
      </header>
    </div>

    <div class="mobile">

      <div class=" headermobile">
        <router-link class="ribbon-logo-aeo_container " tag="span" to="/">
          <img alt="Ankara Eczacı Odası Logo" class="" src="../../../assets/images/slider/aeologobeyazlı.png"
               style=";margin-top: 30px;width: 150px"/>
        </router-link>

        <div class=" menumobil">
          <nav class="navbar">


            <ul class="menu">

              <li class="has-dropdown" style="background-color: black">

                <a class="menu-link">
                  <span class="arrow"></span>
                </a>
                <ul class="submenu">
                  <li><a class="menu-link" href="/">Anasayfa</a></li>
                  <li><a class="menu-link" href="https://eski.aeo.org.tr/">Eski Site </a></li>

                  <li class="has-dropdown">
                    <a class="menu-link">Odamız
                      <span class="arrow"></span>
                    </a>
                    <ul class="submenu">
                      <li><a class="menu-link" href="/about-us">Yönetim</a></li>
                      <li><a class="menu-link" href="/about-history">Tarihçe</a></li>
                      <li><a class="menu-link" href="/about-policy">Politika</a></li>
                      <li><a class="menu-link"
                             @click="clickHrefMethod('https://www.aeo.org.tr/Files/Yayinlar/aeo-iso.pdf')"> Kalite
                        Belgesi</a></li>
                    </ul>
                  </li>
                  <li class="has-dropdown">
                    <a class="menu-link">Duyurular
                      <span class="arrow"></span>
                    </a>
                    <ul class="submenu">
                      <li v-for="(data, index) in subjectTypesCount" :key="'d' + index">
                        <div
                            v-if="
                    data.text !== 'Mesul Müdür\n'  &&
                data.text !== 'Devir Eczane' &&
                data.text !== 'Personel' &&
                data.text !== 'Satıyorum' &&
                data.text !== 'Yardımcı\u00A0Eczacı' &&
                data.text !== 'İkinci Eczacı\n' &&

                 data.text !== 'Stajyer\u00A0Eczacı'
              "
                            class="p-0 m-0"
                            type="button"
                        >
              <span class="new_post_amount me-3 text-center">{{
                  data.countOfPublication
                }}</span>
                          <router-link
                              :to="{ path: `/allposts/${data.text}/${data.id}` }"
                              class="fw-bold text-secondary"
                          >
                            {{ data.text }}
                          </router-link>
                        </div>
                      </li>

                    </ul>
                  </li>
                  <li class="has-dropdown">
                    <a class="menu-link">Üye İşlem
                      <span class="arrow"></span>
                    </a>
                    <ul class="submenu">
                      <li><a class="menu-link" href="/member-only">Üyelere Özel</a></li>
                      <li><a class="menu-link" href="/pharmacy-guide">Eczane rehber</a></li>
                      <li><a class="menu-link" href="/petition-forms">Dilekçe ve Formlar</a></li>
                      <li><a class="menu-link" href="/advert-list">İlan Panosu </a></li>
                    </ul>
                  </li>
                  <li class="has-dropdown">
                    <a class="menu-link">E-Kütüphane
                      <span class="arrow"></span>
                    </a>
                    <ul class="submenu">
                      <li><a class="menu-link" href="/doz">Doz Dergisi </a></li>
                      <li><a class="menu-link" href="/yayinlar">Ankara Eczacı Odası Yayınları </a></li>
                      <li><a class="menu-link" href="/academic-video">Akademik eğitim videoları </a></li>
                      <li><a class="menu-link" href="/Guides">Kılavuzlar </a></li>
                      <li><a class="menu-link" href="/protocol">Protokol </a></li>
                      <li><a class="menu-link" href="/colorful">Renkli Reçete İlaçları </a></li>
                      <li><a class="menu-link" href="#">SUT </a></li>
                    </ul>
                  </li>
                  <li><a class="menu-link" href="/contact">İletişim</a></li>
                </ul>
              </li>

            </ul>
          </nav>
        </div>
        <div @click="clickRouteMethod('/search')" class=" fas fa-search" style=" justify-content: start;
  position: absolute;
  margin-top: -100px;margin-left: 330px;color: white"></div>
      </div>


    </div>

  </div>


</template>

<script>
// import TopBar from "./TopBar.vue";
import NavItems from "../Common/NavItems.vue";
import navItems from "@/components/Data/NavItems.json";
import {mapActions, mapGetters} from "vuex";

export default {

  components: {NavItems},
  props: {
    image: {
      type: String,
      default: "logo.png",
    },
    darkClass: {
      type: Object,
    },
  },
  data: () => ({
    test: null,
    value: null,
    options: ["English", "Bangla", "Hinde"],
    searchText: null,
    submitted: {
      searchText: null
    },
    nav_items: navItems.data,
    subjectTypesCount: null,


  }),
  methods: {
    clickRouteMethod(route) {
      this.$router.push(route);
    },
    hideSidebar(e) {
      this.$emit("toggleSidebar", e);
    },
    login() {
      this.$store.state.searchModule.keywordToSearch = this.searchText;
      this.$router.push('/search')
      //this.$router.push({ name: 'Search', params: { text: this.searchText } })


    },
    ...mapActions("announcementModule", ["getSubjectTypeCount"]),
    ...mapGetters("announcementModule", ["getterSubjectTypesCount"]),

    routeTo(routeTo, routeProp) {
      if (routeProp != null) {
        this.$router.push(routeTo + "/" + routeProp);
      } else this.$router.push(routeTo);
    },
    search() {
      this.$store.state.searchModule.keywordToSearch = this.searchText;
      this.$router.push("/search");
      //this.$router.push({ name: 'Search', params: { text: this.searchText } })
    },
  },
  async created() {
    await this.getSubjectTypeCount();
    this.subjectTypesCount = this.getterSubjectTypesCount();
  },
};
</script>
<style>
.pc {

}

@media (min-width: 1100px) and (max-width: 1504px) {
  .bosluk {
    margin-top: 25px;

  }
}

.bosluk {
  margin-top: 25px;
  margin-left: 60px;
}

.menumobil {
  justify-content: start;
  position: absolute;
  margin-top: -120px;

}

.headermobile {
  background-color: #C11627;
  height: 100px;
  width: 100%;

}

.mobile {
  display: none;

}

@media (max-width: 1191px) {
  .pc {
    display: none;
  }

  .mobile {

    display: flex;
    justify-content: start;
  }

}


@media only screen and (max-width: 50em) {

  .menu li a {
    padding: 0.8em 1.2em; /* Yazı padding'ini azalttım */
    font-size: 0.9rem; /* Yazı boyutunu küçülttüm */
  }

  .submenu {
    min-width: 12em; /* Alt menülerin minimum genişliğini azalttım */
  }

  .submenu > li > a {
    padding: 0.6em 1.2em; /* Alt menü yazılarının padding'ini azalttım */
    font-size: 0.85rem; /* Alt menü yazı boyutunu küçülttüm */
  }


  /* reveal menu */
  input[type="checkbox"]:checked + .menu {
    position: absolute;
    opacity: 1;
    transform: scaleY(1);
  }
}

@media only screen and (max-width: 400px) {


  /* reveal menu */
  input[type="checkbox"]:checked + .menu {
    position: absolute;
    opacity: 1;
    transform: scaleY(1);
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1648px) {
  .ribbon-logo-aeo {

    height: 80%;

  }
}

:root {
  box-sizing: border-box;

  --primary: #e70146;
  --hover-color: #c11627;
  --dark: #1c2022;
  --light: #000000;


}

*,
*::after,
*::before {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body {
  font-family: "josefin sans", "helvetica", sans-serif;
  font-size: 1rem;
}


.branding-logo {
  color: var(--light);
  font-size: calc(0.8rem + 1vw);
  text-decoration: none;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu {
  display: flex;
  flex-direction: row;
}

.menu li {
  list-style: none;
}

.menu li a {
  display: block;
  text-decoration: none;
  color: var(--light);
  padding: 1em 1.5em;
  font-size: 1rem;
}

/* Styling submenu */
.has-dropdown {
  position: relative;
}

.submenu {
  position: absolute;
  left: 0;
  background-color: var(--dark);
  white-space: nowrap;
  padding: 1.5em 0;
  min-width: 16em;


  /* hide submenus */
  opacity: 0;
  transform: scaleY(0);
  transform-origin: top center;
}

.submenu > li > a {
  padding: 0.8em 1.5em;
  left: -20%;

}

.submenu .submenu {
  left: 60%;
  z-index: 999;
  top: -60px;
}

.submenu .submenu .submenu {
  left: 20%;


}

.menu > li:hover > a,
.submenu > li:hover > a {
  background-color: white;
  color: var(--hover-color);
}

.menu > li:hover > a {
  background-color: #110305;

}

/* Arrows */

.arrow {
  width: 0.5em;
  height: 0.5em;
  display: inline-block;
  vertical-align: middle;
  border-left: 0.15em solid currentColor;
  border-bottom: 0.15em solid currentColor;
  transform: rotate(-45deg);
  margin-top: -0.25em;
  transition: transform 100ms ease-in-out;
}

/* Reveal  */
.menu > li:hover > a + .submenu,
.submenu > li:hover > a + .submenu {
  opacity: 1;
  transform: scaleY(1);

}

/* ANIMATE aRROWS */
.menu > li:hover > a > .arrow,
.submenu > li:hover > a > .arrow {
  transform: rotate(225deg);
}

@media only screen and (max-width: 78.75em) {
  .submenu .submenu .submenu {
    left: -100%;

  }

  .submenu {
    min-width: 16em;

    ;
  }
}

.logo_title {
  color: #c11627;
}

.ribbon-logo-aeo_container {
  display: flex;
  justify-content: center;
}

.form_ani, .input_ani, .caret {
  margin: auto;
}

.form_ani {
  position: relative;
  width: 100%;
  max-width: 18em;
}

.input_ani, .caret {
  display: block;
  transition: all calc(1s * 0.5) linear;
  color: #c11627;
}

.input_ani:hover {
  cursor: pointer;
}

.aeotext {
  font-family: Agency FB;
  align-items: center;
  display: block;
  font-weight: bold;
  padding-left: 46%;
}

#c51e2f
.input_ani {
  background: transparent;
  border-radius: 50%;
  box-shadow: 0 0 0 0.25em inset;
  caret-color: #c11627;
  width: 2em;
  height: 2em;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #c11627;
}

.den {
  margin-top: 17px;
}


@media only screen and (min-width: 991px) and (max-width: 1191px) {


}


@media only screen and (min-width: 775px) and (max-width: 992px) {


}

@media only screen and (min-width: 666px) and (max-width: 775px) {


}

@media only screen and (min-width: 586px) and (max-width: 666px) {


}

@media only screen and (min-width: 464px) and (max-width: 586px) {


}

@media only screen and (min-width: 368px) and (max-width: 464px) {


}

@media only screen and (min-width: 0px) and (max-width: 368px) {


}

.input_ani:focus, .input_ani:valid {
  background: #ffffff;
  border-radius: 0.25em;
  box-shadow: none;
  padding: 0.75em 1em;
  transition-duration: calc(1s * 0.25);
  transition-delay: calc(1s * 0.25);
  width: 100%;
  height: 3em;
}

.input_ani:focus {
  animation: showCaret 1s steps(1);
  outline: transparent;
}

.input_ani:focus + .caret, .input_ani:valid + .caret {
  animation: handleToCaret 1s linear;
  background: transparent;
  width: 1px;
  height: 1.5em;
  transform: translate(0, -1em) rotate(-180deg) translate(7.5em, -0.25em);
}

.input_ani::-webkit-search-decoration {
  -webkit-appearance: none;
}

.label_ani {
  color: #e3e4e8;
  overflow: hidden;
  position: absolute;
  width: 0;
  height: 0;
}

.caret {
  background: #c11627;
  border-radius: 0 0 0.125em 0.125em;
  margin-bottom: -0.6em;
  width: 0.25em;
  height: 1em;
  transform: translate(0, -1em) rotate(-45deg) translate(0, 0.875em);
  transform-origin: 50% 0;
}

@keyframes showCaret {
  from {
    caret-color: transparent;
  }
  to {
    caret-color: #c11627;
  }
}

@keyframes handleToCaret {
  from {
    background: currentColor;
    width: 0.25em;
    height: 1em;
    transform: translate(0, -1em) rotate(-45deg) translate(0, 0.875em);
  }
  25% {
    background: currentColor;
    width: 0.25em;
    height: 1em;
    transform: translate(0, -1em) rotate(-180deg) translate(0, 0.875em);
  }
  50%, 62.5% {
    background: #c11627;
    width: 1px;
    height: 1.5em;
    transform: translate(0, -1em) rotate(-180deg) translate(7.5em, 2.5em);
  }
  75%, 99% {
    background: #c11627;
    width: 1px;
    height: 1.5em;
    transform: translate(0, -1em) rotate(-180deg) translate(7.5em, -0.25em);
  }
  87.5% {
    background: #c11627;
    width: 1px;
    height: 1.5em;
    transform: translate(0, -1em) rotate(-180deg) translate(7.5em, 0.125em);
  }
  to {
    background: transparent;
    width: 1px;
    height: 1.5em;
    transform: translate(0, -1em) rotate(-180deg) translate(7.5em, -0.25em);
  }
}


.select-lang {
  border: 1px solid #bababa;
  width: 100px;
  padding: 2px;
  color: #17191d;
  outline: none;
  border-radius: 7px;
}

.logo-pic {
  width: 100px;
  display: inline;
  margin-right: 20px;
}

.main-title {
  display: inline-block;
  font-size: 35px;
  position: absolute;
  transform: translate(0px, 50px);
  color: #C11627;
}


@media (max-width: 576px) {
  .icon_item {
    width: 5px;
  }

  .banner_container {
    height: 80px;
    align-items: center;
  }

  .banner_container .icon_container {
    height: 45px;
    align-items: center;
  }

  .banner_container img {
    max-width: 180%;
  }
}

@media (max-width: 768px) {
  .banner_container {
    height: 100px;

  }

  .banner_container img {
    max-width: 180%;
  }

  .banner_img_ata {
    display: none;
  }

  .banner_text {
    display: none;
  }
}

@media (min-width: 768px) {

  .mobile_banner {
    display: none;
  }

}

@media (min-width: 992px) {
  .header-menubar .container {
    max-width: 1800px;
  }

  .nav-container {
    justify-content: center;
  }

}


@media (min-width: 1200px) {

}

.web_banner {
  display: none;
}

@media (max-width: 768px) {
  .web_banner {
    display: none;
  }
}

.headerkonum {
  position: absolute;
  top: 0px;
  z-index: 2;
}


</style>
