<template>
  <div>
    <div>
      <div class="sticky-top">
        <Navbar class="navbar_container"/>
      </div>
      <drawer @toggleSidebar="toggleSidebar" :sidebar="sidebar"/>



    <!--====== POST LAYOUT 1 PART START ======-->

    <section class="post-layout-1-area post-layout-3-area pb-80">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <div class="post-layout-top-content post-layout-top-content-3">
              <div
                  class="post-categories d-flex justify-content-between align-content-center"
              ></div>
              <div v-if="this.page !== null" class="d-flex justify-content-end">
                <small class="text-muted "
                >{{ format_date(this.page.startDate) }}</small
                >
              </div>
              <div v-else class="post-content">
                <Spinner></Spinner>
              </div>

              <div v-if="this.page !== null" class="post-content">
                <h3 class="title">
                  {{ this.page.header }}
                </h3>
                <div v-if="page !== null" class="thumb">
                  <img class="" :src="this.page.pageIconPath" alt=""/>
<!--                  <img class="" :src="this.page.pageIconPath" alt=""/>-->
                </div>
              </div>
              <div v-else class="post-content">
                <Spinner></Spinner>
              </div>
              <div v-if="this.page !== null" class="post-text mt-30">
                <div v-html="this.page.content"></div>

                <div v-if="this.imageGallery !== null" class="row mb-3">
                  <div
                      v-for="(data, index) in this.imageGallery"
                      :key="index"
                      class="col-lg-3"
                  >
                    <a href=""><img :src="data" alt=""/></a>
                  </div>
                </div>
                <div v-else>
                  <Spinner></Spinner>
                </div>
              </div>
            </div>
          </div>
          <div class="">
            <!--<style-one :datas="posts"/>-->
          </div>
        </div>
      </div>
    </section>

    <!--====== POST LAYOUT 1 PART ENDS ======-->

    <!--====== LATEST NEWS PART START ======-->

    <Footer/>
    <!--====== GO TO TOP PART START ======-->
    <div class="go-top-area">
      <div class="go-top-wrap">
        <div class="go-top-btn-wrap">
          <a href="#" class="go-top go-top-btn d-block">
            <i class="fa fa-angle-double-up"></i>
            <i class="fa fa-angle-double-up"></i>
          </a>
        </div>
      </div>
    </div>
    <!--====== GO TO TOP PART ENDS ======-->
  </div>
  </div>
</template>

<script>

// import Header from "../../Utility/Header/index";
import Posts from "../../Data/NewsRowCard";


// import OurLatestNews from "../../Utility/Common/OurLatestNews.vue";
import Footer from "../../Utility/Footer/FooterOne";
import Drawer from "../../Mobile/Drawer.vue";
import {mapActions} from "vuex";
import moment from "moment";

import Spinner from "../../Spinner/HollowDotsSpinner/Spinner.vue";
import Navbar from "@/components/Utility/Header/index.vue";

export default {
  components: {Navbar, Footer, Drawer, Spinner},
  data: () => ({
    posts: Posts.data,
    sidebar: false,
    image: "",
    displayModal: false,
    pageDetail: {
      PublishPlaceId: null,
      SubjectTypeId: null,
      Id: null,
    },
    imageGallery: null,
    page: null
  }),
  created() {
    document.addEventListener("scroll", this.topToBottom);
    this.pageDetail = {
      SubjectTypeId: this.$route.params.subjectTypeId,
      PublishPlaceId: this.$route.params.publishPlaceId,
      Id: this.$route.params.id
    }
    this.getAnnouncementDetail(this.pageDetail);
    this.getImageGalleryById(this.pageDetail.Id);
  },

  methods: {
    ...mapActions("announcementModule", ["getAnnouncementDetail", "getImageGalleryById", "getSubjectTypeCount"]),
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
    topToBottom() {
      const result = document.querySelector(".go-top");
      if (
          document.body.scrollTop > window.innerHeight ||
          document.documentElement.scrollTop > window.innerHeight
      ) {
        result.classList.add("active");
      } else {
        result.classList.remove("active");
      }

    },


    toggleModal(e) {
      console.log(e)
      this.image = e.target
      console.log(this.image)
      this.displayModal = !this.displayModal
    },
    getImageURL(payload) {
      return "https://backend.aeo.org.tr/" + payload;
    },
    format_date(value) {
      return moment(value).format("DD.MM.YYYY");
    },
  },
  watch: {
    '$store.state.announcementModule.selectedPage': function () {
      this.page = this.$store.state.announcementModule.selectedPage;
    },
    '$store.state.announcementModule.currentPageImageGallery': function () {
      this.imageGallery = this.$store.state.announcementModule.currentPageImageGallery;
    }
  }
};
</script>

<style scoped>
.container {
  margin-top: 20px;
}

.image_modal {
  position: absolute;
  top: 50%;
  left: 50%;
}


</style>
