<template>
  <div>
    <div class="sticky-top">
      <Navbar class="navbar_container"/>
    </div>
    <!--    <Banner/>-->
    <drawer :sidebar="sidebar" @toggleSidebar="toggleSidebar"/>
    <!-- <about-author /> -->
    <div class="container content">
      <h4>Bilgi ve Belgeler</h4>
      <b-card no-body style="background: unset">
        <div class="bottomtabs">
          <b-tabs
              v-model="tabIndex"
              align="left"
              card
              justified
              nav-class="custom col-12 mx-auto"
          >
            <b-tab v-for="(item, index) in impInformation" :key="index" :title=item.title active>
              <ul
                  v-for="(elem, itemIndex) in item.list"
                  :key="itemIndex"
                  class="list-group"
              >
                <li class="list-group-item">
                  <a :href="elem.link">{{ elem.item }}</a>
                </li>
              </ul>
            </b-tab>
          </b-tabs>
        </div>
      </b-card>
    </div>
    <footer-one/>
    <div class="go-top-area" style="display: none">
      <div class="go-top-wrap">
        <div class="go-top-btn-wrap">
          <a class="go-top go-top-btn d-block" href="#">
            <i class="fa fa-angle-double-up"></i>
            <i class="fa fa-angle-double-up"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FooterOne from "../Utility/Footer/FooterOne.vue";
import ImpInformation from "../Data/ImpInformation.json";
import Navbar from "@/components/Utility/Header/index";
import Drawer from "../Mobile/Drawer.vue";
/*import Banner from "../Utility/Header/Banner";*/
// import Header from "../Utility/Header/index";
// import AboutAuthor from "./AboutAuthor.vue";
export default {
  components: {
    FooterOne,
    Drawer,
    Navbar
    /*    Banner,*/
  },
  data: () => ({
    sidebar: false,
    impInformation: ImpInformation.data,
    tabIndex: 0,
  }),
  created() {
    document.addEventListener("scroll", this.topToBottom);
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
    topToBottom() {
      const result = document.querySelector(".go-top");
      if (
          document.body.scrollTop > window.innerHeight ||
          document.documentElement.scrollTop > window.innerHeight
      ) {
        result.classList.add("active");
      } else {
        result.classList.remove("active");
      }
    },
  },
};
</script>

<style scoped>
.container {
  margin-top: 10vh;
}

@media (max-width: 992px) {
  .container {
    margin-top: 10vh;
  }
}

.card-header {
  border-left: 5px solid gray;
}

.card-header:hover {
  border-left: 5px solid #c51e2f;
}
</style>
