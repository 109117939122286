<template>
  <div class="kutucuk">
    <div class="row" style="min-height: 307.5px">
      <a class="button" :href="'/duyurular/'+datas.subjectTypeIds[0]+'/'+datas.publishPlaceIds[0]+'/'+datas.id+'/'+datas.url">
        <div v-if="category">
          <template v-if="datas">
            <div>
              <div>
                <div v-if="datas.pageIconPath">
                  <img :src="datas.pageIconPath" alt="gallery" class="resim">
                  <div class="info" style="position: relative;margin-top: 5px;  color: #ecfdff ;background-color: #ff0000; ">
                    {{ announcementTimeInfo }}
                  </div>
                </div>
              </div>
              <div class="yazı">
                <div v-if="$store.state.announcementModule.subjectTypes.length > 0">
                  {{
                    $store.state.announcementModule.subjectTypes.find(
                        (x) => x.id === datas.subjectTypeIds[0]
                    ).text
                  }}
                </div>
              </div>
              <div class="yazı2">
                <a :href="'/duyurular/'+datas.subjectTypeIds[0]+'/'+datas.publishPlaceIds[0]+'/'+datas.id+'/'+datas.url" >
                </a>
                <span class="title">{{ shortenedText }}</span>
              </div>
              <div class="yazı2">
              {{
                format_date(this.datas.startDate)
                }}
              </div>


            </div>
          </template>
        </div>
       </a>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  methods: {
    format_date(value) {
      return moment(value).format("DD.MM.YYYY");
    },


  },
  computed: {
    announcementTimeInfo() {

      const publishDate = new Date(this.datas.startDate);
      const today = new Date();

      const diffDays = Math.floor((today - publishDate) / (1000 * 60 * 60 * 24));

      if (diffDays === 0) {
        return "Bugün";
      } else if (diffDays === 1) {
        return "Dün";
      } else {
        return "Yeni";
      }
    },
    shortenedText() {
      const maxLength = 30;
      if (this.datas.header.length <= maxLength) {
        return this.datas.header;
      } else {
        return this.datas.header.substring(0, maxLength) + '...';
      }
    },
  },
  props: {
    category: {
      type: Boolean,
    },
    datas: {
      type: Object,
    },
  },
};
</script>


<style scoped>
.resim {
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.kutucuk {
  width: 277.5px;
  min-height: 320px;
  margin-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
}

.yazı {
  font-size: 20px;
  color: #c0102f;
  text-align: center;
  font-weight: bold;
}

.yazı2 {
  color: black;
  font-size: 15px;
  width: 230px;
  text-align: center;
}
.info{
  display: block;
 width: 40px;
}
.button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  color: white; /* Metin rengi */
  border: 0.5px solid #cbcbcb;

  border-radius: 5px;
  text-decoration: none; /* Alt çizgiyi kaldır */
  text-align: center;
}


</style>
