<template>
  <div
      :class="[
      'newspark-header-main-menu',
      darkClass && 'menuItem' in darkClass ? darkClass.menuItem : '',
    ]"
  >
    <ul v-if="nav_items" class="nav_container">
      <li v-for="(item, index) in nav_items.slice(0, 1)"
          :key="'a' + index"
          class="nav_item"
          type="button"
      >
        <router-link tag="a" to="/" type="button">
          <i class="fa-solid fa-house-chimney text-white p-3"></i>
        </router-link>
      </li>
      <li
          v-for="(item, index) in nav_items.slice(1, 2)"
          :key="'b' + index"
          class="nav_item"
          type="button"
      >
        <a v-if="item.child" class="nav_salt_item" type="button"
        >{{ item.linkText }}
          <i v-if="item.child" class="fal fa-angle-down"></i
          ></a>
        <router-link v-else :to="`${item.link}`" class="nav_salt_item" type="button"
        >{{ item.linkText }}
        </router-link>
        <!-- Üçüncül menü öğeleri -->
        <ul v-if="item.child" class="sub-menu">
          <!-- JSON verisinden okunan menü öğeleri -->
          <li v-for="(lvlTwo, index) in item.submenu" :key="'f' + index" class="nav_salt_item" type="button">
            <!-- Tüm menü öğeleri için blank sayfada açan kod -->
            <a :href="lvlTwo.link" class="nav_salt_item" rel="noopener noreferrer">
              {{ lvlTwo.linkText }}
              <i v-if="lvlTwo.child" class="fal fa-angle-down"></i>
            </a>
            <!-- Diğer menü öğeleri -->
            <ul v-if="lvlTwo.child" class="sub-menu">
              <!-- Dördüncül menü öğeleri -->
              <li v-for="(lvlThree, index) in lvlTwo.third_menu" :key="index" class="nav_salt_item" type="button">
                <router-link :to="`${lvlThree.link}`" type="button">{{ lvlThree.linkText }}</router-link>
              </li>
            </ul>
          </li>
        </ul>

      </li>
      <li class="nav_item" type="button">
        <a class="nav_salt_item">Duyurular <i class="fal fa-angle-down"></i></a>
        <ul class="sub-menu">
          <li v-for="(data, index) in subjectTypesCount" :key="'d' + index">
            <div
                v-if="
                data.text !== 'Mesul Müdür\n'  &&
                data.text !== 'Devir Eczane' &&
                data.text !== 'Personel' &&
                data.text !== 'Satıyorum' &&
                data.text !== 'Yardımcı\u00A0Eczacı' &&
                data.text !== 'İkinci Eczacı\n' &&

                 data.text !== 'Stajyer\u00A0Eczacı'
              "
                class="p-0 m-0"
                type="button"
            >
              <span class="new_post_amount me-3 text-center">{{
                  data.countOfPublication
                }}</span>
              <router-link
                  :to="{ path: `/allposts/${data.text}/${data.id}` }"
                  class=" " style="font-size: 15px ; font-weight: bold"
              >
                {{ data.text }}
              </router-link>
            </div>
          </li>
          <!--          <li>-->
          <!--            <div class="p-0 m-0">-->
          <!--              <router-link class="fw-bold text-secondary" to="/social-event">-->
          <!--                Sosyal Etkinlikler-->
          <!--              </router-link>-->
          <!--            </div>-->
          <!--          </li>-->
          <!--          <li>-->
          <!--            <div class="p-0 m-0">-->
          <!--              <router-link-->
          <!--                  class="fw-bold text-secondary"-->
          <!--                  to="/academic-education"-->
          <!--              >-->
          <!--                Akademik Eğitimler-->
          <!--              </router-link>-->
          <!--            </div>-->
          <!--          </li>-->
        </ul>
      </li>
      <li v-for="(item, index) in nav_items.slice(3, 4)"
          :key="'e' + index"
          class="nav_item"
          type="button"
      >
        <a v-if="item.child" class="nav_salt_item"
        >{{ item.linkText }}
          <i v-if="item.child" class="fal fa-angle-down"></i
          ></a>
        <router-link v-else :to="`${item.link}`" class="nav_salt_item" type="button"
        >{{ item.linkText }}
        </router-link>
        <ul v-if="item.child" class="sub-menu">
          <li v-for="(lvlTwo, index) in item.submenu" :key="'f' + index" class="nav_salt_item" type="button">
            <router-link :to="`${lvlTwo.link}`" class="nav_salt_item" type="button">
              {{ lvlTwo.linkText }}
              <i v-if="lvlTwo.child" class="fal fa-angle-down"></i
              ></router-link>
            <ul v-if="lvlTwo.child" class="sub-menu">
              <li v-for="(lvlThree, index) in lvlTwo.third_menu" :key="index" class="nav_salt_item" type="button">
                <router-link :to="`${lvlThree.link}`" type="button"
                >{{ lvlThree.linkText }}
                </router-link>
              </li>
            </ul>
          </li>
        </ul>


      </li>
      <li v-for="(item, index) in nav_items.slice(4 , 8)"
          :key="'g' + index"
          class="nav_item"
          type="button"
      >
        <a v-if="item.child" class="nav_salt_item"
        >{{ item.linkText }}
          <i v-if="item.child" class="fal fa-angle-down"></i
          ></a>
        <router-link v-else :to="`${item.link}`" class="nav_salt_item" type="button"
        >{{ item.linkText }}
        </router-link>
        <ul v-if="item.child" class="sub-menu">
          <li v-for="(lvlTwo, index) in item.submenu" :key="'h' + index" class="nav_salt_item" type="button">

            <!-- jsonda değişiklik yapılıp geçici olarak farklı bir linke bağlandı. Nöbet kısmı kart bastırma */-->
            <a :href="lvlTwo.link" class="nav_salt_item" rel="noopener noreferrer">
              {{ lvlTwo.linkText }}
              <i v-if="lvlTwo.child" class="fal fa-angle-down"></i>
            </a>


            <ul v-if="lvlTwo.child" class="sub-menu">
              <li v-for="(lvlThree, index) in lvlTwo.third_menu" :key="index" class="nav_salt_item" type="button">
                <router-link :to="`${lvlThree.link}`" type="button"
                >{{ lvlThree.linkText }}
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li class="ser" type="button">
        <input id="search" v-model="searchText" class="form-control "
               pattern=".*\S.*"
               placeholder="Site içi Arama"
               required type="search" @keypress.enter="search">
      </li>

      <!--      <li type="button">-->
      <!--        <button class=" yazieski" @click="clickHrefMethod(' https://eski.aeo.org.tr/')">-->
      <!--          Eski Site-->
      <!--        </button>-->
      <!--      </li>-->


    </ul>
    <button class="yazieski" @click="clickHrefMethod('https://eski.aeo.org.tr/')">

      Eski Site
    </button>
    <!--    <div class="atatürk">-->
    <!--      <img-->
    <!--          src="@/assets/images/ataturkbayraksiyah.png"-->
    <!--          alt="Atatürk Logo"-->
    <!--          height="100%"-->
    <!--          width="150vh"-->

    <!--      />-->
    <!--    </div>-->
  </div>


</template>

<script>
import navItems from "../../Data/NavItems.json";
import {mapActions, mapGetters} from "vuex";

export default {
  props: {
    darkClass: {
      type: Object,
    },
  },
  data: () => ({
    nav_items: navItems.data,
    subjectTypesCount: null,
    value: null,
    options: ["English", "Bangla", "Hinde"],
    searchText: null,
    submitted: {
      searchText: null,
    },
  }),

  methods: {
    ...mapActions("announcementModule", ["getSubjectTypeCount"]),
    ...mapGetters("announcementModule", ["getterSubjectTypesCount"]),
    clickRouteMethod(route) {
      this.$router.push(route);
    },
    clickHrefMethod(href) {
      window.open(href, "_blank");
    },
    routeTo(routeTo, routeProp) {
      if (routeProp != null) {
        this.$router.push(routeTo + "/" + routeProp);
      } else this.$router.push(routeTo);
    },
    search() {
      this.$store.state.searchModule.keywordToSearch = this.searchText;
      this.$router.push("/search");
      //this.$router.push({ name: 'Search', params: { text: this.searchText } })
    },
  },
  async created() {
    await this.getSubjectTypeCount();
    this.subjectTypesCount = this.getterSubjectTypesCount();

  },


};
</script>

<style>
.fa-search {
  color: #C11627;
}

.a nav_salt_item2:hover {
  color: white;
}

.newspark-header-main-menu ul > li .sub-menu > li a {
  font-size: 15px;
}

newspark-header-main-menu ul > li .sub-menu > li a > hover {
  color: black;
}

.newspark-header-main-menu ul > li > a {

  font-size: 20px;
  font-weight: 500;
  color: #ffffff;
  line-height: 45px;

}

.sub-menu > li > a {
  color: #c11627;


}

.nav_container {
  font-size: 23px;
  padding-left: 10px;
}

.nav_salt_item {
{
  padding-left: 10px;
  padding-right: 10px;
  font-size: 13px;
}

  .nav_salt_item:hover {
  {
    color: black;
  } padding-left: 10px;
    padding-right: 10px;
    font-size: 13px;
  }
}

.nav_salt_item1 {
  font-size: 13px;
  padding-left: 10px;
  padding-right: 10px;
}

.nav_salt_item1:hover {
  color: #070505;

}

.nav_salt_item2 {
  font-size: 13px;
  padding-left: 10px;
  padding-right: 10px;

}

@media (min-width: 1504px) and (max-width: 1631px) {
  .yazieski {
    color: #ffffff;
  }

  .newspark-header-main-menu ul > li > a {

    font-size: 17px;
    font-weight: 500;
    color: #ffffff;
    line-height: 45px;

  }
}

@media (min-width: 1462px) and (max-width: 1504px) {
  .yazieski {
    color: #ffffff;
  }

  .newspark-header-main-menu ul > li > a {

    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    line-height: 45px;

  }
}

@media (min-width: 1420px) and (max-width: 1462px) {
  .yazieski {
    color: #ffffff;
  }

  .newspark-header-main-menu ul > li > a {

    font-size: 15px;
    font-weight: 500;
    color: #ffffff;
    line-height: 45px;

  }
}

@media (min-width: 1304px) and (max-width: 1420px) {
  .yazieski {
    color: #ffffff;
  }

  .newspark-header-main-menu ul > li > a {

    font-size: 13px;
    font-weight: 500;
    color: #ffffff;
    line-height: 45px;

  }

  .nav_salt_item {
  {
    padding-left: 1px;
    padding-right: 1px;
  }
  }

  .nav_salt_item1 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .nav_salt_item1:hover {
    color: white;

  }

  .nav_salt_item2 {

    padding-left: 1px;
    padding-right: 1px;

  }
}

.nav_salt_item2:hover {
  color: white;
  padding-left: 1px;
  padding-right: 1px;

}

.yazieski {
  color: white;
  background-color: transparent;
  border: none;
  font-weight: 600;
  margin-left: 300px;
  font-size: 20px;
}

@media (min-width: 1045px) and (max-width: 1833px) {
  .yazieski {
    color: #ffffff;
  }
}

.input_ani:focus + .caret,
.input_ani:valid + .caret {
  animation: handleToCaret 1s linear;
  background: transparent;
  width: 1px;
  height: 1.5em;
  transform: translate(0, -1em) rotate(-180deg) translate(7.5em, -0.25em);
}

.atatürk {
  margin-right: -150vh;
  margin-top: -11vh;
}

@keyframes showCaret {
  from {
    caret-color: transparent;
  }
  to {
    caret-color: #c11627;
  }
}

@keyframes handleToCaret {
  from {
    background: currentColor;
    width: 0.25em;
    height: 1em;
    transform: translate(0, -1em) rotate(-45deg) translate(0, 0.875em);
  }
  25% {
    background: currentColor;
    width: 0.25em;
    height: 1em;
    transform: translate(0, -1em) rotate(-180deg) translate(0, 0.875em);
  }
  50%,
  62.5% {
    background: #c11627;
    width: 1px;
    height: 1.5em;
    transform: translate(0, -1em) rotate(-180deg) translate(7.5em, 2.5em);
  }
  75%,
  99% {
    background: #c11627;
    width: 1px;
    height: 1.5em;
    transform: translate(0, -1em) rotate(-180deg) translate(7.5em, -0.25em);
  }
  87.5% {
    background: #c11627;
    width: 1px;
    height: 1.5em;
    transform: translate(0, -1em) rotate(-180deg) translate(7.5em, 0.125em);
  }
  to {
    background: transparent;
    width: 1px;
    height: 1.5em;
    transform: translate(0, -1em) rotate(-180deg) translate(7.5em, -0.25em);
  }
}

.fa-house-chimney {
  font-size: 18px;
}


.nav_salt_item:hover {
  color: #b9b9b9 !important;
}


.nav_drop_item {
  color: rgb(255, 255, 255) !important;
  padding: 10px;
}


.newspark-header-main-menu.header-two ul > li > a {
  color: #ffffff;
}

.newspark-header-main-menu.header-three-nav ul > li > a {
  color: rgb(255, 255, 255);
}

.header-three-nav {
  margin-left: 35px;
}

.nav_item {
  color: #c11627;
}

.nav_itemhov:hover {
  color: #f2b680;
  cursor: pointer;
}

.sub-menu {
  border-left: 3px solid rgb(255, 107, 54);
}

.form_ani,
.input_ani,
.caret {
  margin: auto;
}

.form_ani {
  position: relative;
  width: 100%;
  max-width: 18em;
}

.input_ani,
.caret {
  display: block;
  transition: all calc(1s * 0.5) linear;
  color: #c11627;
}

.input_ani:hover {
  cursor: pointer;
}

.input_ani {
  background: transparent;
  border-radius: 50%;
  box-shadow: 0 0 0 0.25em inset;
  caret-color: #c11627;
  width: 2em;
  height: 2em;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #c11627;
}

.input_ani:focus,
.input_ani:valid {
  background: #ffffff;
  border-radius: 0.25em;
  box-shadow: none;
  padding: 0.75em 1em;
  transition-duration: calc(1s * 0.25);
  transition-delay: calc(1s * 0.25);
  width: 100%;
  height: 3em;
}

.input_ani:focus {
  animation: showCaret 1s steps(1);
  outline: transparent;
}

.input_ani:focus + .caret,
.input_ani:valid + .caret {
  animation: handleToCaret 1s linear;
  background: transparent;
  width: 1px;
  height: 1.5em;
  transform: translate(0, -1em) rotate(-180deg) translate(7.5em, -0.25em);
}

.input_ani::-webkit-search-decoration {
  -webkit-appearance: none;
}

.label_ani {
  color: #e3e4e8;
  overflow: hidden;
  position: absolute;
  width: 0;
  height: 0;
}

.caret {
  background: #c11627;
  border-radius: 0 0 0.125em 0.125em;
  margin-bottom: -0.6em;
  width: 0.25em;
  height: 1em;
  transform: translate(0, -1em) rotate(-45deg) translate(0, 0.875em);
  transform-origin: 50% 0;
}

.new_post_amount {
  background: rgb(255, 107, 54);
  font-size: 16px;
  display: inline-block;
  padding: 2px;
  width: 40px;
  border-radius: 50%;
  color: #fff;
  position: absolute;
  top: 2px;
  right: 10px;
}
</style>

