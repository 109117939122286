<template>

  <div class="alan">

    <div class="tab-content">
      <div :class="['tab-pane fade', selectedGallery === 'trendy' ? 'show active' : '']" role="tabpanel" aria-labelledby="pills-home-tab">
        <div class="" v-if="datas && datas.length > 0">
          <div v-for="(small, index) in datas.slice(0, 8)" :key="index" class="col-lg-3">
            <div class="square">
              <row-card :class="[darkClass && 'item' in darkClass ? darkClass.item : '']" :category="true" :datas="small" :key="index"/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="tab-content">
      <div :class="['tab-pane fade', selectedGallery === 'latest' ? 'show active' : '']" role="tabpanel" aria-labelledby="pills-home-tab">
        <div v-if="datas && datas.length > 0" class="">
          <div v-for="(small, index) in datas.slice(0, 5)" :key="index" class="col-lg-3">
            <div class="square">
              <row-card :category="true" :datas="small" :key="index"/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="tab-content">
      <div :class="['tab-pane fade', selectedGallery === 'popular' ? 'show active' : '']" role="tabpanel" aria-labelledby="pills-home-tab">
        <div v-if="datas && datas.length > 0" class="">
          <div v-for="(small, index) in datas.slice(0, 5)" :key="index" class="col-lg-3">
            <div class="square">
              <row-card :category="true" :datas="small" :key="index"/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <signup-style-two v-if="signup"/>
    <div v-if="ad" class="sidebar-add pt-35">
      <a href="#"><img src="@/assets/images/ads/two_ad.jpg" alt="ad"/></a>
    </div>
    <share-post v-if="sharePost" class="mt-40"/>
  </div>
</template>

<script>
import RowCard from "../Cards/RowCard.vue";

export default {
  components: {RowCard},
  props: {
    datas: {
      type: Array,
      default: () => [],
    },
    darkClass: {
      type: Object,
      default: () => {
      },
    },
    signup: {
      type: Boolean,
      default: true,
    },
    trendingBigPost: {
      type: Boolean,
      default: true,
    },
    trendingShortPost: {
      type: Boolean,
      default: true,
    },
    ad: {
      type: Boolean,
      default: true,
    },
    sharePost: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    selectedGallery: "trendy",
    trendingNews: {
      autoplay: true,
      slideToScroll: 1,
      slidesToShow: 1,
      arrows: false,
      dots: false,
    },
  }),
  methods: {
    selectGalleryTab(value) {
      this.selectedGallery = value;
    },
    trendingNewsPrev() {
      this.$refs.trendingNews.prev();
    },
    trendingNewsNext() {
      this.$refs.trendingNews.next();
    },
  },
};
</script>

<style>
.alan{
  margin-bottom: 10px;
  display: flex;

}
.square{

}

</style>
