const initialState = {

    selectedAcademicEvents: [],
}

export const academicModule = {
    namespaced: true,
    state: initialState,
    actions: {},
    getters: {},
    mutations: {

    },
}