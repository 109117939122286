<template>
  <div v-if="!cookieAccepted" class="cookie-consent">
    <div class="content">
      <h2>Çerez Bildirimi</h2>
      <p>
        Web sitemizde size daha iyi bir deneyim sunabilmek için çerezler
        kullanıyoruz. Lütfen tercihlerinizi seçin:
      </p>
      <div class="buttons">
        <button @click="acceptCookies" class="accept">Tüm Çerezleri Kabul Et</button>
        <button @click="acceptNecessaryCookies" class="necessary">Gerekli Çerezleri Kabul Et</button>
        <button @click="rejectCookies" class="reject">Reddet</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cookieAccepted: false,
    };
  },
  mounted() {
    this.checkCookieConsent();
  },
  methods: {
    checkCookieConsent() {
      const consent = this.getCookie("cookieConsent");
      this.cookieAccepted = consent === "accepted" || consent === "necessary";
    },
    acceptCookies() {
      this.setCookie("cookieConsent", "accepted", 365);
      this.cookieAccepted = true;
    },
    acceptNecessaryCookies() {
      this.setCookie("cookieConsent", "necessary", 365);
      this.cookieAccepted = true;
    },
    rejectCookies() {
      alert("Çerezleri reddettiniz, Bu sayfada çerezler kullanmaktadır .");
      this.cookieAccepted = true;
    },
    setCookie(name, value, days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/`;
    },
    getCookie(name) {
      const nameEQ = name + "=";
      const ca = document.cookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0)
          return c.substring(nameEQ.length, c.length);
      }
      return null;
    },
  },
};
</script>

<style scoped>
.cookie-consent {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(51, 51, 51, 0.95);
  color: white;
  padding: 20px;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
  font-family: 'Arial', sans-serif;
}

.content {
  max-width: 800px;
  text-align: center;
}

h2 {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
}

p {
  margin: 10px 0 20px;
  font-size: 16px;
}

.buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 12px 24px;
  margin: 5px;
  cursor: pointer;
  border-radius: 25px;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.2s;
}

button.necessary {
  background-color: #17a2b8;
}

button.reject {
  background-color: #dc3545;
}

button:hover {
  transform: scale(1.05);
}

button.reject:hover {
  background-color: #c82333;
}

button.necessary:hover {
  background-color: #138496;
}
</style>
