<template>
  <div>
    <div class="sticky-top">
      <Navbar class="navbar_container"/>
    </div>
    <!--<Banner/>-->
    <drawer @toggleSidebar="toggleSidebar" :sidebar="sidebar"/>
    <!--    <div class="sticky-top">-->
    <!-- <HeaderOne @toggleSidebar="toggleSidebar" /> -->
    <!--    </div>-->

    <!--====== HEADER  PART ENDS ======-->

    <!--====== POST PART START ======-->

    <!--====== POST PART ENDS ======-->

    <!--====== POST GALLERY PART START ======-->
    <post-gallery style="padding-top: 35px"/>

    <!--====== ALL POST PART ENDS ======-->

    <!--====== FOOTER PART START ======-->
    <footer-one/>

    <!--====== FOOTER PART ENDS ======-->

    <!--====== GO TO TOP PART START ======-->
    <div class="go-top-area" style="display: none">
      <div class="go-top-wrap">
        <div class="go-top-btn-wrap">
          <a href="#" class="go-top go-top-btn d-block" style="display: none" >

          </a>
        </div>
      </div>
    </div>
    <!--====== GO TO TOP PART ENDS ======-->
  </div>
</template>
<script>
// import HeaderOne from "../Utility/Header/index";
//import Banner from "../Utility/Header/Banner";
import FooterOne from "../Utility/Footer/FooterOne.vue";
import Navbar from "@/components/Utility/Header/index";

import Drawer from "../Mobile/Drawer.vue";
import PostGallery from "../Home/PostGallery";

export default {
  components: {
    // HeaderOne,
    PostGallery,
    FooterOne,
    Drawer,
    //Banner,
    Navbar,
  },
  data: () => ({
    sidebar: false,
  }),
  created() {
    document.addEventListener("scroll", this.topToBottom);
  },
  methods: {
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
    topToBottom() {
      const result = document.querySelector(".go-top");
      if (
          document.body.scrollTop > window.innerHeight ||
          document.documentElement.scrollTop > window.innerHeight
      ) {
        result.classList.add("active");
      } else {
        result.classList.remove("active");
      }
    },
  },
};
</script>

<style scoped>
/* play post */
.single-play-post-area .single-play-box {
  padding: 23px 12px !important;
}


</style>
