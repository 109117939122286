import { render, staticRenderFns } from "./AboutHistory.vue?vue&type=template&id=38eb7eba&scoped=true&"
import script from "./AboutHistory.vue?vue&type=script&lang=js&"
export * from "./AboutHistory.vue?vue&type=script&lang=js&"
import style0 from "./AboutHistory.vue?vue&type=style&index=0&id=38eb7eba&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38eb7eba",
  null
  
)

export default component.exports