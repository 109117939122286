import axios from 'axios';


class AnnouncementService {
    getShift() {
        const soapRequest = `
       <soap:Envelope xmlns:soap="http://www.w3.org/2003/05/soap-envelope" xmlns:tem="http://tempuri.org/">
   <soap:Header/>
   <soap:Body>
      <tem:NobetciEczaneler>
         <tem:lat>39.986496</tem:lat>
         <tem:lng>32.712816</tem:lng>
      </tem:NobetciEczaneler>
   </soap:Body>
</soap:Envelope>
             `;

        axios.post(
            'https://mvc.aeo.org.tr/PublicSayfalar/WebServices/ws_aeo_Nobet.asmx', soapRequest, {
                headers: {
                    'Content-Type': 'text/xml'
                }
            })
            .then(response => {

                this.response = response.data; // Assign response to your component's data
            })
            .catch(error => {
                console.error('SOAP request error:', error);
            });
    }

    //COUNTRY
    getTodaysNews(payload) {
        return axios.post(`${process.env.VUE_APP_AEO_FRONTEND_API}Page/get-today-news`, {
            PublishPlaceIds: payload
        });
    }

    getSubjectTypes() {
        return axios.get(`${process.env.VUE_APP_AEO_FRONTEND_API}SubjectType/get-all`);
    }

    getPublishPlaces() {
        return axios.get(`${process.env.VUE_APP_AEO_FRONTEND_API}PublishPlace/get-all`);
    }

    getAnnouncementDetail(payload) {
        return axios.post(`${process.env.VUE_APP_AEO_FRONTEND_API}Page/get-page-detail`, {
            SubjectTypeId: payload.SubjectTypeId,
            PublishPlaceId: payload.PublishPlaceId,
            Id: payload.Id,

        });
    }

    getImageGalleryById(payload) {
        return axios.get(`${process.env.VUE_APP_AEO_FRONTEND_API}Page/get-image-gallery-by-id?pageId=` + payload);
    }

    getSubjectTypeCount() {
        return axios.post(`${process.env.VUE_APP_AEO_FRONTEND_API}Page/get-count-of-publication-by-subject-type`);
    }

    getPostsAccorCategory(payload) {
        return axios.post(`${process.env.VUE_APP_AEO_FRONTEND_API}Page/filter-announcements-paginated`,
            {
                SubjectTypeIds: payload?.checkedSubjectTypes,
                IsApproved: 1,
                PageNumber: payload.currentPage, PageLength: payload.pageSize
            }
        )
    }

}

export default new AnnouncementService();