<template>
  <div>
    <div>
      <Banner/>
      <drawer @toggleSidebar="toggleSidebar" :sidebar="sidebar" /> 
    </div>
    <!-- <div class="sticky-top">
      <Header @toggleSidebar="toggleSidebar" />
    </div> -->

    <!-- <about-author /> -->
    <div class="container">
      <h4 class="title">Aramak istediğiniz Eczane Adını Giriniz.</h4>
      <div class="input-group mb-3">
        <input
          type="text"
          class="form-control text-center"
          placeholder="Lütfen küçük harflerle giriniz."
          aria-label="Example text with button addon"
          aria-describedby="button-addon2"
          v-model="phaSearch"
        />
        <button
          class="btn btn-outline-danger"
          type="button"
          id="button-addon2"
          @click="displayPhaResult"
        >
          Ara
        </button>
      </div>

      <div class="result">
        <div class="card p-5" :class="default_result">
          <div
            class="row bg-light opacity-75 align-items-center justify-content-center p-5"
          >
            <div class="col-12">
              <h1 class="text-center">Sonuçları görmek için arama yapınız.</h1>
            </div>
          </div>
        </div>
        <div class="card p-5" :class="false_result">
          <div
            class="row bg-light align-items-center justify-content-center p-5"
          >
            <div class="col-12">
              <h1 class="text-danger text-center result-text">
                Üzgünüz Bu İsimde Bir Eczane Mevcut :/
              </h1>
            </div>
          </div>
        </div>
        <div class="card p-5" :class="true_result">
          <div
            class="row bg-light opacity-75 align-items-center justify-content-center p-5"
          >
            <div class="col-12">
              <h1 class="text-success text-center result-text">
                Bu İsmi alabilirsiniz. :)
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>

    <footer-one />
    <div class="go-top-area" style="display: none">
      <div class="go-top-wrap">
        <div class="go-top-btn-wrap">
          <a href="#" class="go-top go-top-btn d-block">
            <i class="fa fa-angle-double-up"></i>
            <i class="fa fa-angle-double-up"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Drawer from "../Mobile/Drawer.vue";
import FooterOne from "./Footer/FooterOne.vue";
import Banner from "../Utility/Header/Banner";
// import Header from "../Utility/Header/index";
import PhaName from "../Data/PhaName.json";
// import AboutAuthor from "./AboutAuthor.vue";
export default {
  components: {

    FooterOne,
    Drawer,
    Banner
  },
  data: () => ({
    sidebar: false,
    phaSearch: "",
    phaName: PhaName.data,
    false_result: "d-none",
    true_result: "d-none",
    default_result: "d-block",
  }),
  created() {
    document.addEventListener("scroll", this.topToBottom);
  },
  methods: {
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
    topToBottom() {
      const result = document.querySelector(".go-top");
      if (
        document.body.scrollTop > window.innerHeight ||
        document.documentElement.scrollTop > window.innerHeight
      ) {
        result.classList.add("active");
      } else {
        result.classList.remove("active");
      }
    },
    displayPhaResult() {
      this.default_result = "d-none";

      if (this.phaName.includes(this.phaSearch)) {
        this.false_result = "d-block";
        this.true_result = "d-none";
      } else if (this.phaSearch === "") {
        alert("Lütfen bi işim giriniz");
        this.default_result = "d-block";
        this.false_result = "d-none";
        this.true_result = "d-none";
      } else {
        this.true_result = "d-block";
        this.false_result = "d-none";
      }

      console.log(this.phaName);
    },
  },
};
</script>

<style scoped>
.container {
  margin-top: 200px;
}
@media (max-width: 992px) {
  .container {
    margin-top: 150px;
  }
}

@media (max-width: 576px) {
  .result-text {
    font-size: 2rem;
  }
}
.result {
  height: 100vh;
}
</style>
