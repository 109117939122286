<template>
  <div>
    <div class="sticky-top">
      <Navbar class="navbar_container"/>
    </div>
<!--    <Banner/>-->
    <drawer @toggleSidebar="toggleSidebar" :sidebar="sidebar"/>
    <!-- <about-author /> -->
    <div class="container">
      <h4 class="title">Ankara Eczacı Odası Akademik Eğitim</h4>
      <div class="row">
        <!-- <router-link class="gallery_item card" :to="'posts/post-event-academic'+data._id">
          <div class="gallery_item_thumb row">
            <div class="col-12">
              <img :src="require(`@/assets/images/${data.image}`)" alt="gallery" class="ann-img">
            </div>
          </div>
          <div class="gallery_item_content col-8">
            <div class="post-meta">
              <div class="meta-categories">
                Akademik Eğitim
              </div>
            </div>
          </div>
        </router-link> -->
        <div
            class="col-lg-4 col-md-6"
            v-for="(data, index) in academicEducation"
            :key="index"
        >
          <router-link
              :to="{ path: 'posts/post-event-academic/' + data._id }"
              class="card gallery_item mb-5 p-3"
          >
            <div class="gallery_item_thumb row">
              <div class="col-12">
                <img
                    :src="require(`@/assets/images/${data.image}`)"
                    class="ann-img"
                    alt=""
                />
              </div>
            </div>
            <div class="gallery_item_content mt-3">
              <h4 class="title">
                <a href="#">{{ data.title }}</a>
              </h4>
              <p class="card-text">
                <small class="text-muted">{{ data.date }}</small>
              </p>
            </div>
          </router-link>
        </div>
        <!-- <div class="col-md-6 mb-4" v-for="data in academicEducation" :key="data">
          <div class="card mb-3" style="max-width: 540px">
            <div class="row g-0">
              <div class="img_container col-4">
                <img
                  :src="require(`@/assets/images/${data.image}`)"
                  class="img-fluid rounded-start"
                />
              </div>
              <div class="col-8">  
                <div class="card-body ms-0">
                  <div class="">
                    <h5 class="card-title">{{ data.title }}</h5>
                    <p class="card-text">
                      <small class="text-muted">{{data.date}}</small>
                    </p>
                  </div>
                  <div>
                    <button
                      @click="routeTo('posts/post-event-academic',data._id)"
                      class="btn btn-outline-danger btn-sm"
                      >Devamını okuyun...</button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>

    <footer-one/>
    <div class="go-top-area" style="display: none">      <div class="go-top-wrap">
        <div class="go-top-btn-wrap">
          <a href="#" class="go-top go-top-btn d-block">
            <i class="fa fa-angle-double-up"></i>
            <i class="fa fa-angle-double-up"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*import Banner from "../Utility/Header/Banner";*/
import Drawer from "../Mobile/Drawer.vue";
import FooterOne from "../Utility/Footer/FooterOne.vue";
import Navbar from "@/components/Utility/Header/index";
// import Header from "../Utility/Header/index";

// import AboutAuthor from "./AboutAuthor.vue";
export default {
  components: {

    FooterOne,
    Drawer,
    /*    Banner,*/
    Navbar
  },
  data: () => ({
    sidebar: false,
    academicEducation: null,
  }),
  created() {
    document.addEventListener("scroll", this.topToBottom);
    this.academicEducation =
        this.$store.state.academicModule.selectedAcademicEvents;
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
    routeTo(routeTo, routeProp) {
      if (routeProp != null) {
        this.$router.push(routeTo + "/" + routeProp);
      } else this.$router.push(routeTo);
    },
    topToBottom() {
      const result = document.querySelector(".go-top");
      if (
          document.body.scrollTop > window.innerHeight ||
          document.documentElement.scrollTop > window.innerHeight
      ) {
        result.classList.add("active");
      } else {
        result.classList.remove("active");
      }
    },
  },
};
</script>

<style scoped>
.container {
  margin-top: 10vh;
}

@media (max-width: 992px) {
  .container {
    margin-top: 10vh;
  }
}

.ann-img {
  height: 230px;
  width: 300px;
}

.gallery_item_content {
  padding-left: 0px;
  padding-right: 0px;
}

.gallery_item {
  height: 350px;
  border-left: 5px solid gray;
}

.gallery_item:hover {
  border-left: 5px solid #c51e2f;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

@media (max-width: 992px) {
  .container {
    margin-top: 20px;
  }
}

.img-fluid {
  height: 100%;
}

.img_container {
  height: 180px;
  width: 120px;
}

@media (max-width: 992px) {
  .img_container {
    height: 200px;
    width: 120px;
  }
}

.btn {
  position: absolute;
  bottom: 1px;
  right: 15px;
}
</style>
