<template>


  <div v-if="stype === 'col'" class="trending-news-item">
    <button  style="border: 1px solid #e5e5e5; background-color: white" >


      <img :src="require(`@/assets/images/slider/${image[index].name}`)" alt="trending" />

      <!-- apiden alınan hali aşşağıda -->
      <!--      <img :src="datas.iconpath" alt="Network Image" />-->



    <div class="trending-news-content">
      <div class="post-meta">
        <div class="meta-categories">
          <router-link
              :to="{ path: `/allposts/${datas.text}/${datas.id}` }"
              class="fw-bold text-secondary"
          >
            {{ datas.text }}
          </router-link>
        </div>

      </div>
      <!--      <h3 class="title">-->
      <!--        <router-link to="/posts/postOne">{{ datas.title }}</router-link>-->
      <!--      </h3>-->

    </div>
    </button>
  </div>



</template>

<script>
export default {
  props: {
    datas: {
      type: Object,
    },
    readMore: {
      type: Boolean,
      default: false,
    },
    stype: {
      type: String,
    },

  },
};
</script>

<style>

</style>
