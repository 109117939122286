<template>
  <div>
    <div class="sticky-top">
      <Navbar class="navbar_container"/>
    </div>
    <!--    <Banner/>-->
    <drawer @toggleSidebar="toggleSidebar" :sidebar="sidebar"/>

    <!-- <about-author /> -->
    <div class="container">
      <h4 class="title">Site İçi Arama</h4>
      <div class="search_module mb-5">
        <!-- {{ this.form.checkedPublishPlaces }} -->
        <div class="card">
          <div v-if="this.publishPlaces !== null" class="row checkboxlistrule" role="group" aria-label="Basic radio toggle button group">
            <div v-for="(data, index) in this.publishPlaces" :key="index" class="form-check form-switch col-sm-3">
              <input value="" :id="data.id" type="checkbox" :v-model="form.checkedPublishPlaces" class="form-check-input" >
              <label  :for="data.id" class="form-check-label">{{ data.text }} </label>
            </div>

          </div>
          <div
              v-else
              class="btn-group search_category justify-space-between justify-content-center w-100"
              role="group"
              aria-label="Basic radio toggle button group"
          >

            <Spinner></Spinner>
          </div>
          <div
              class="d-flex justify-content-center align-center date_container"
          >
            <div class="date_items">
              <div class="start_date me-5">
                <label for="start" class="p-1 bg-danger text-white me-5 p-2"
                >Tarih Aralığı:</label
                >
                <date-picker
                    :popup-style="{ top: '100%', left: 0 }"
                    :append-to-body="false"
                    v-model="form.dateInterval"
                    format="DD.MM.YYYY"
                    range
                    type="date"
                    valueType="format"
                ></date-picker>
              </div>
            </div>
          </div>
          <div class="card-body text-center">
            <div class="search_text mt-2">
              <h4>Aramak istediğiniz kelimeleri giriniz...</h4>
              <div class="input-group mb-3">
                <input
                    v-model="form.keyword"
                    type="text"
                    class="form-control text-center"
                    placeholder=""
                    aria-label="Example text with button addon"
                    aria-describedby="button-addon1"
                />
                <button
                    class="btn btn-outline-danger"
                    type="button"
                    id="button-addon1"
                    @click="displayResult"

                >
                  Ara
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!spinnerDisplay" class="search_result_list" :class="displayMode"
      >

        <div class="row"

        >
          <div
              class="col-lg-4 col-md-6 mb-4"

              v-for="(data, index) in $store.state.searchModule
              .AnnouncementPaginatedSearchResult.item"
              :key="index"
          >
            <!-- :to="{ path: '/duyurular/'+data.subjectTypeIds[0]+'/'+data.publishPlaceIds[0]+'/'+data.id+'/'+ datas.url}" -->
            <router-link tag="div"
                         :to="{ path: '/duyurular/'+data.subjectTypeIds[0]+'/'+data.publishPlaceIds[0]+'/'+data.id+'/'+ data.url}"
                         class="card search_list_item mb-3">
              <div class="row justify-content-center g-0">
                <div class="img_container col-12">
                  <img
                      :src="data.pageIconPath"
                      class="img-fluid rounded-start"
                  />
                </div>
                <div class="col-12">
                  <div class="card-body ms-0">
                    <div class="mb-5">
                      <div class="row date_type justify-content-between">
                        <p class="card-text col-6">
                          <small
                              v-for="(item, pblIndex) in data.publishPlaceIds"
                              class="text-muted"
                              :key="pblIndex"
                          >
                            {{ publishPlaces.find((x) => x.id === item).text }}
                            <small
                                v-if="pblIndex + 1 !== data.publishPlaceIds.length"
                            >
                              -
                            </small>
                          </small>
                        </p>
                        <div class="meta-date col-6">
                          <span>{{ format_date(data.startDate) }}</span>
                        </div>
                      </div>
                      <div class="d-flex justify-content-center">
                        <h6 class="card-title title w-75
                    ">{{ data.header }}</h6>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div v-else class="card-body spinner_contain">
        <Spinner></Spinner>
      </div>

    </div>

    <footer-one/>
    <div class="go-top-area" style="display: none">
      <div class="go-top-wrap">
        <div class="go-top-btn-wrap">
          <a href="#" class="go-top go-top-btn d-block">
            <i class="fa fa-angle-double-up"></i>
            <i class="fa fa-angle-double-up"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Drawer from "../Mobile/Drawer.vue";
import FooterOne from "../Utility/Footer/FooterOne.vue";
import {mapActions, mapGetters} from "vuex";
import Spinner from "@/components/Spinner/HollowDotsSpinner/Spinner";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Navbar from "@/components/Utility/Header";
// import Header from "../Utility/Header/index";
/*import Banner from "../Utility/Header/Banner";*/

export default {
  components: {
    Spinner,
    FooterOne,
    Drawer,
    DatePicker,
    /*Banner,*/
    Navbar
  },
  data: () => ({
    sidebar: false,
    postSearch: null,
    displayMode: "d-none",
    publishPlaces: null,

    spinnerDisplay: false,
    test: [],
    form: {
      pageSize: 25,
      currentPage: 1,
      keyword: null,
      checkedPublishPlaces: [],
      dateInterval: [
        moment().subtract(1, "years").format("DD.MM.YYYY"),
        moment().format("DD.MM.YYYY"),

      ],
    },
  }),
  created() {
    this.form.keyword = this.$store.state.searchModule.keywordToSearch
    if (this.form.keyword !== null)
      this.displayResult();

    this.publishPlaces =
        this.$store.getters["announcementModule/getterPublishPlaces"];
    if (this.publishPlaces === null) {
      this.getPublishPlaces();
    }
    document.addEventListener("scroll", this.topToBottom);
  },
  mounted() {
    window.scrollTo(0, 0)
  },
  methods: {
    ...mapActions("announcementModule", ["getPublishPlaces"]),
    ...mapActions("searchModule", ["getAnnouncementSearchPaginated"]),
    ...mapGetters("announcementModule", ["getterPublishPlaces"]),
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
    topToBottom() {
      const result = document.querySelector(".go-top");
      if (
          document.body.scrollTop > window.innerHeight ||
          document.documentElement.scrollTop > window.innerHeight
      ) {
        result.classList.add("active");
      } else {
        result.classList.remove("active");
      }
    },
    format_date(value) {
      return moment(value).format("DD.MM.YYYY");
    },
    displayResult() {
      this.displayMode = "d-block";
      //this.$store.state.general.showOverlay = true;
      this.spinnerDisplay = true
      this.getAnnouncementSearchPaginated(this.form)
          .then((res) => {
            //this.$store.state.general.showOverlay = false;
            if (res.endpointResult.data) {
              console.log(res.endpointResult.data);
              this.spinnerDisplay = false
            } else {
              //this.$store.state.dtAeo = Object.assign({});
              //this.errorMessage = "this.getMessage(118);"
              //this.showError = true;
              console.log("no data");
              this.spinnerDisplay = false
            }
          })
          .catch(() => {
            //this.$store.state.general.showOverlay = false;
            console.log("error");
            this.spinnerDisplay = false
          });
    },
    searchBtn(data) {
      if (this.form.checkedPublishPlaces.findIndex(x => x === data.id) < 0) {
        //CHECKEDPUBLİSHPLACE İÇİNDE YOKSA
        return "btn btn-outline-danger";
      } else {
        return "btn btn-danger";
      }
    },
  },
  watch: {
    "$store.state.announcementModule.publishPlaces": function () {
      this.publishPlaces = this.$store.state.announcementModule.publishPlaces;
    },
  },
};
</script>

<style scoped>
/*.container {
  margin-top: 40px;
  margin-bottom: 150px;
}*/
.container {
  margin-top: 10vh;
}

@media (max-width: 992px) {
  .container {
    margin-top: 10vh;
  }
}


/*label {
  display: inline-block;
  width: 120px;
  padding: 10px;
  text-align: center;
}*/

.date_container {
  margin-top: 80px;
}

.search_text {
  margin-top: 80px;
}

@media (max-width: 991.98px) {
  .search_category {
    display: grid;
    grid-template-columns: auto auto auto auto;
  }
}

@media (max-width: 575.98px) {
  .search_category {
    display: grid;
    grid-template-columns: auto auto;
  }
}

.img-fluid {
  height: 100%;
}

.img_container {
  height: 180px;
  width: 120px;
}


@media (max-width: 576px) {
  .img_container {
    height: 200px;
    width: 120px;
  }


}

.spinner_contain {
  display: flex;
  width: 100%;
}

.search_list_item {
  cursor: pointer;
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.search_list_item {
  height: 300px;
  border-left: 5px solid gray;
  text-align: center;

}

.search_list_item:hover {
  border-left: 5px solid #c51e2f;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.checkboxlistrule{
  padding-left: 5vw;
  width: 60vw;
  align-self: center;
}
</style>

<!-- .meta-date::before{
  position: absolute;
    content: "";
    left: 15px;
    top: 50%;
    transform: translateY(-50%) rotate(40deg);
    width: 2px;
    height: 15px;
    background: #515a60;
    border-radius: 30px;
} -->
  