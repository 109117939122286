import AnnouncementService from "@/services/announcement.service";


const initialState = {
  todaysNews: [],
  announcements: [],
  subjectTypes: [],
  publishPlaces: [],
  selectedPage: {},

  //kategori ve sayıları

  subjectTypesCount: [],

  //kategorilere göre duyuru getirme

  postsAccorCategory:{dtPagination: {}, item: []},

  nextPage: {},
  currentPageImageGallery: [],
};

export const announcementModule = {
  namespaced: true,
  state: initialState,
  actions: {
    getTodaysNews({ commit }, payload) {
      return AnnouncementService.getTodaysNews(payload).then((res) => {
        if (res.data) commit("setTodaysNews", res.data);
        return Promise.resolve(res.data);
      });
    },
    getShift() {
      return AnnouncementService.getShift()  ;


    },

    getSubjectTypes({ commit }) {
      return AnnouncementService.getSubjectTypes().then((res) => {
        if (res.data) commit("setSubjectTypes", res.data);
        return Promise.resolve(res.data);
      });
    },
    getPublishPlaces({ commit }) {
      return AnnouncementService.getPublishPlaces().then((res) => {
        if (res.data) commit("setPublishPlaces", res.data);
        return Promise.resolve(res.data);
      });
    },
    getAnnouncementDetail({ commit }, payload) {
      return AnnouncementService.getAnnouncementDetail(payload).then((res) => {
        if (res.data) commit("setSelectedPage", res.data);
        return Promise.resolve(res.data);
      });
    },

    //kategori ve sayıları

    getSubjectTypeCount({ commit }) {
      return AnnouncementService.getSubjectTypeCount().then((res) => {
        if (res.data) commit("setSubjectTypeCount", res.data);
        return Promise.resolve(res.data);
      });
    },

    //kategorilere göre duyuru getirme

    getPostsAccorCategory({ commit }, payload) {
      return AnnouncementService.getPostsAccorCategory(payload).then(
        (res) => {
          if (res.data) commit("setPostsAccorCategory", res.data);
          return Promise.resolve(res.data);
        }
      );
    },

    getImageGalleryById({ commit }, payload) {
      return AnnouncementService.getImageGalleryById(payload).then((res) => {
        if (res.data) commit("setCurrentPageImageGallery", res.data);
        return Promise.resolve(res.data);
      });
    },
  },
  getters: {
    getBaskandan(state) {
      if (state.todaysNews.length === 0) {
        return null;
      }

      return state.todaysNews.filter((x) => x.publishPlaceIds.includes(20));
    },
    getDuyurular(state) {
      if (state.todaysNews.length === 0) {
        return null;
      }
      return state.todaysNews.filter(
        (x) => x.publishPlaceIds.includes(20) || x.publishPlaceIds.includes(23) || x.publishPlaceIds.includes(48) || x.publishPlaceIds.includes(27)
      );
    },
    getAdvertSubjectTypes(state) {
      if (state.subjectTypes.length !== 0)
        return state.subjectTypes.filter((x) => x.isAdvert === true);
      return null;
    },
    getSliderGallery(state) {
      if (state.todaysNews.length !== 0)
        return state.todaysNews.filter((x) => x.publishPlaceIds.includes(48));
      return null;
    },
    getterPublishPlaces(state) {
      if (state.publishPlaces.length === 0) {
        return null;
      }

      return state.publishPlaces;
    },
    getSubjectTypes(state) {
      if (state.subjectTypes.length === 0) {
        return null;
      }
      return state.subjectTypes;
    },

    //kategori ve sayıları
    getterSubjectTypesCount(state) {
      return state.subjectTypesCount;
    },

    //kategorilere göre duyuru getirme
    getterPostsAccorCategory(state) {
      return state.postsAccorCategory.item;
    },
  },
  mutations: {
    setTodaysNews(state, payload) {
      if (!payload.hasError) {
        state.todaysNews = Object.assign([], payload.data);
      }
    },
    setSubjectTypes(state, payload) {
      if (!payload.hasError) {
        state.subjectTypes = Object.assign([], payload.data);
      }
    },
    setPublishPlaces(state, payload) {
      if (!payload.hasError) {
        state.publishPlaces = Object.assign([], payload.data);
      }
    },
    setSliderGallery(state, payload) {
      if (!payload.hasError) {
        state.sliderGallery = Object.assign([], payload.data);
      }
    },
    setSelectedPage(state, payload) {
      if (!payload.hasError) {
        state.selectedPage = Object.assign({}, payload.data);
      }
    },

    setSubjectTypeCount(state, payload) {
      if (!payload.hasError) {
        state.subjectTypesCount = Object.assign([], payload.data);
      }
    },

    //kategorilere göre duyuru getirme
    setPostsAccorCategory(state, payload) {
      state.postsAccorCategory.item = Object.assign([], payload.endpointResult.data);
      state.postsAccorCategory.dtPagination = Object.assign({}, payload.pagination);
      state.Overlay = false
  },

    setCurrentPageImageGallery(state, payload) {
      if (!payload.hasError) {
        state.currentPageImageGallery = Object.assign([], payload.data);
      }
    },
  },
};
