<template>
  <section class="trending-news-area">
    <div class="container" style="width: 100% ; ">
      <div class="row" style="width: 100% ;">
        <div class="col-lg-12">

          <div
              :class="[
              'section-title',
              darkClass && 'title' in darkClass ? darkClass.title : '',
            ]"
          >
            <h3 class="title" style="text-align: center">Konu Grupları </h3>
          </div>
          <div class="trending-news-slider position-relative">

            <VueSlickCarousel :arrows="true" :dots="true" v-bind="trendingSettings">

              <div class="px-2">
                <divide-card

                    :datas="objdata[0]"

                />
              </div>
              <div class="px-2">
                <divide-card
                    :class="[
                      darkClass && 'item' in darkClass ? darkClass.item : '',
                    ]"
                    :datas="objdata[4]"
                    stype="col"
                />
              </div>
              <div class="px-2">
                <divide-card
                    :class="[
                      darkClass && 'item' in darkClass ? darkClass.item : '',
                    ]"
                    :datas="objdata[5]"
                    stype="col"
                />
              </div>
              <div class="px-2">
                <divide-card
                    :class="[
                      darkClass && 'item' in darkClass ? darkClass.item : '',
                    ]"
                    :datas="objdata[6]"
                    stype="col"
                />
              </div>
              <div class="px-2">
                <divide-card
                    :class="[
                      darkClass && 'item' in darkClass ? darkClass.item : '',
                    ]"
                    :datas="objdata[7]"
                    stype="col"
                />
              </div>
              <div class="px-2">
                <divide-card
                    :class="[
                      darkClass && 'item' in darkClass ? darkClass.item : '',
                    ]"
                    :datas="objdata[8]"
                    stype="col"
                />
              </div>
              <div class="px-2">
                <divide-card
                    :class="[
                      darkClass && 'item' in darkClass ? darkClass.item : '',
                    ]"
                    :datas="objdata[9]"
                    stype="col"
                />
              </div>
            </VueSlickCarousel>
          </div>

        </div>

      </div>
    </div>
  </section>
</template>

<script>
import DivideCard from "../Utility/Cards/DivideCard.vue";
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import smallPostGallery from "../Data/NewsRowCard";


export default {
  components: {VueSlickCarousel, DivideCard},
  props: {
    darkClass: {
      type: Object,
    },
    objdata: Object,
  },
  data: () => ({
    smallPostGallery: smallPostGallery.data,
    trendingSettings: {
      "arrows": true,
      "dotsClass": "slick-dots custom-dot-class",
      "edgeFriction": 0.35,
      "infinite": false,
      "speed": 500,
      "slidesToShow": 3,
      "slidesToScroll": 1,
      "touchThreshold": 3
    }


  }),
  methods: {

  },
};
</script>

<style>
.trending-news-area{
  position: relative;
}
</style>
