import SearchService from "@/services/search.service";

const initialState = {
    AnnouncementPaginatedSearchResult: {dtPagination: {}, item: []},

    selectedPostSearch: [],
    keywordToSearch:null,
}

export const searchModule = {
    namespaced: true,
    state: initialState,
    actions: {
        getAnnouncementSearchPaginated({commit}, payload) {
            return SearchService.getAnnouncementSearchPaginated(payload).then(res => {
                if (res.data) commit('setAnnouncementSearchPaginatedItem', res.data);
                return Promise.resolve(res.data);
            });
        },
    },
    getters: {},
    mutations: {
        setAnnouncementSearchPaginatedItem(state, payload) {
            state.AnnouncementPaginatedSearchResult.item = Object.assign([], payload.endpointResult.data);
            state.AnnouncementPaginatedSearchResult.dtPagination = Object.assign({}, payload.pagination);
            state.Overlay = false
        },
    },
}

