<template>
  <div>
    <div class="sticky-top">
      <Navbar class="navbar_container"/>
    </div>

    <drawer @toggleSidebar="toggleSidebar" :sidebar="sidebar"/>
    <!-- <about-author /> -->
    <div class="container">
      <h4 class="title">İlan Panosu</h4>
      <div class="row">
        <div
            class="col-lg-8 mx-auto mb-4" v-if="this.advertDetail !== null"
        >
          <div

              class="card mb-3 advert_card align-items-center"

          >
            <div class="row g-0 w-100 align-items-center">
              <div class="img_container col-md-4">
                <img
                    style="max-width: 200px"
                    src="@/assets/images/logo.jpg"
                    class="img-fluid rounded-start ms-5"
                />
              </div>
              <div class="col-md-8">
                <div class="card-body ms-0">
                  <div class="mb-2">
                    <div><span>İlan no : {{ this.advertDetail.id }}</span></div>
                    <h5 class="card-title">{{ this.advertDetail.header }}</h5>
                    <div v-html="this.advertDetail.content"></div>

                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div v-else>
          <Spinner></Spinner>
        </div>
      </div>
    </div>

    <footer-one/>
    <div class="go-top-area" style="display: none">
      <div class="go-top-wrap">
        <div class="go-top-btn-wrap">
          <a href="#" class="go-top go-top-btn d-block">
            <i class="fa fa-angle-double-up"></i>
            <i class="fa fa-angle-double-up"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Drawer from "../Mobile/Drawer.vue";


import FooterOne from "../Utility/Footer/FooterOne.vue";
// import Header from "../Utility/Header/index";
import {mapActions, mapGetters} from "vuex";
import Spinner from "@/components/Spinner/HollowDotsSpinner/Spinner";
import Navbar from "@/components/Utility/Header/index";
export default {
  components: {
    Spinner,

    FooterOne,
    Drawer,

    Navbar
  },
  data: () => ({
    advertDetail: null,
    sidebar: false,
    Advert: null,
  }),
  created() {
    console.log()
    this.pageDetail = {
      SubjectTypeId: this.$route.params.subjectTypeId,
      PublishPlaceId: this.$route.params.publishPlaceId,
      Id: this.$route.params.id
    }
    this.getAdvertDetail(this.pageDetail);


    document.addEventListener("scroll", this.topToBottom);
  },
  mounted () {
  window.scrollTo(0, 0)
},
  methods: {

    ...mapActions("advertModule", ["getAdvertDetail"]),
    ...mapGetters("advertModule", ["getSelectedAdvert"]),

    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
    topToBottom() {
      const result = document.querySelector(".go-top");
      if (
          document.body.scrollTop > window.innerHeight ||
          document.documentElement.scrollTop > window.innerHeight
      ) {
        result.classList.add("active");
      } else {
        result.classList.remove("active");
      }
    },
  },
  watch: {
    '$store.state.advertModule.selectedAdvert': function () {
      this.advertDetail = this.getSelectedAdvert();
    },
  }
};
</script>

<style scoped>
.container {
  margin-top: 20px;
}


</style>
